@import url(https://fonts.googleapis.com/css?family=Asap:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:wght@600&family=Poppins:wght@400;600&display=swap);
:root {
  --blue: #3a86ff;
  --blue-hover: #2c74e7;
  --green: #569364;
  --green-hover: #478355;
  --red: #c94e4e;
  --orange: #fa8950;
  --invalid: #ff2e2e;
  --gray: #405983;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Asap";
}

button,
textarea,
input,
p,
h1,
h2,
h3,
div {
  font-family: "Asap";
}

input,
textarea:focus {
  border: 0.5px solid var(--blue);
}

.route_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.route_rest_container {
  display: flex;
  flex: 1 1;
  width: 100%;
  flex-direction: row;
}

.route_switch_container {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.no_row_found_div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
}

.no_row_found {
  margin: 0;
  padding: 0;
  align-self: center;
  font-size: 13px;
  color: rgb(63, 63, 63);
}

.pagination_div {
  margin: 0;
  padding: 0;
  width: 92%;
  height: 50px;
  margin-bottom: 20px;
  align-self: center;
  background-color: #f6f6f6;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination_div_client {
  margin: 0;
  padding: 0;
  padding-top: 6px;
  width: 92%;
  height: 50px;
  margin-bottom: 20px;
  align-self: center;
  background-color: #f6f6f6;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination_div_pool {
    margin: 0;
    padding: 0;
    margin-top: -10px;
    padding-top: 18px;
    width: 92%;
    height: 50px;
    margin-bottom: 20px;
    align-self: center;
    background-color: #f6f6f6;
    border-left: 1px solid #c7c7c7;
    border-top: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.network_fixed_div_container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2000;
  justify-content: center;
  background-color: transparent;
}

.network_warnning_text {
  margin-top: 50px;
  height: 10px;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f54848;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
}
.common_header_main_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: rgb(255, 255, 255);
  border-bottom: 1.5px solid #dcdcdc;
  position: fixed;
  z-index: 200;
}

.common_header_logo {
  margin: 2%;
  width: 9%;
  cursor: pointer;
  height: auto ;
}

.common_header_ifa_details {
  margin-right: 4%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.common_header_ifa_details_part {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  background: #ffffff;
  margin: 2px;
  border-radius: 5px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  border: 1px solid #3a86ff;
}

.common_header_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #3a86ff;
  font-weight: 500;
}

.common_header_divider {
  height: 30px;
  width: 15px;
  border-left: 2px solid #42474f;
}

/* @media only screen and (max-width: 1000px) {
  .common_header_logo {
    width: 10%;
  }

  .common_header_text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .common_header_logo {
    width: 15%;
  }

  .common_header_text {
    font-size: 17px;
  }
}

@media only screen and (max-width: 800px) {
  .common_header_logo {
    width: 20%;
  }

  .common_header_text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .common_header_logo {
    width: 25%;
  }

  .common_header_text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .common_header_logo {
    width: 30%;
  }

  .common_header_ifa_details {
    flex-direction: column;
    align-items: flex-start;
  }

  .common_header_ifa_details_part {
    margin-top: 5px;
  }

  .common_header_text {
    font-size: 14px;
  }

  .common_header_divider {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .common_header_logo {
    width: 35%;
  }

  .common_header_ifa_details {
    flex-direction: column;
    align-items: flex-start;
  }

  .common_header_ifa_details_part {
    margin-top: 5px;
  }

  .common_header_text {
    font-size: 13px;
  }

  .common_header_divider {
    display: none;
  }
} */

.common_sidebar_main_container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 220px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-right: 1.5px solid #dcdcdc;
  z-index: 100;
}

.common_sidebar_menu_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 120px;
}

.common_sidebar_menu_item_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.common_sidebar_menu_text_div {
  display: flex;
  width: 100%;
  background-color: white;
  /* padding-top: 15px;
  padding-bottom: 15px; */
  cursor: pointer;
}

.common_sidebar_menu_text {
  margin: 0;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
}

.common_sidebar_menu_text_div:hover {
  background-color: rgb(247, 247, 247);
}

.common_sidebar_menu_logout_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
}

.common_sidebar_menu_logout_button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  width: 40%;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.common_sidebar_menu_logout_button_container:hover {
  background-color: var(--blue-hover);
}

.common_sidebar_menu_logout_text {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.logo{
  font-size : 10px;
  cursor : pointer;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-left: 20px;
}

.logo1{
  font-size : 10px;
  cursor : pointer;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-left: 20px;
  filter: invert(50%) sepia(38%) saturate(6137%) hue-rotate(203deg) brightness(103%) contrast(100%);
}
/* 
@media only screen and (max-width: 1000px) {
  .common_sidebar_main_container {
    width: 220px;
  }

  .common_sidebar_menu_text {
    margin-left: 50px;
    font-size: 14px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 40%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  .common_sidebar_main_container {
    width: 180px;
  }

  .common_sidebar_menu_text {
    margin-left: 40px;
    font-size: 13px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 45%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 800px) {
  .common_sidebar_main_container {
    width: 160px;
  }

  .common_sidebar_menu_text {
    margin-left: 30px;
    font-size: 12px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 50%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 700px) {
  .common_sidebar_main_container {
    width: 140px;
  }

  .common_sidebar_menu_text {
    margin-left: 20px;
    font-size: 11px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 55%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 11px;
  }
} */

.poollist_column_container {
  width: 100%; /* Set the container to full width */
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create three equal columns */
  grid-gap: 30px;
  gap: 30px; /* Add a gap between columns */
  align-self: center;
  padding-bottom: 5px;
}

.pool_container {
  width: 100%-1px;
  overflow: hidden;
  border: 1px solid #E4E4E4;
  border-radius: 8px 8px 8px 8px;
}

.pool_logo_and_title_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 4px;
  border-radius: 3px;
}

.pool_logo_image_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.pool_logo_image {
  width: 26px;
}

.pool_title {
  color: #323232;
  font-weight: bold;
  font-size: 15px;
  margin-left: 10px;
  text-align: center;
}

.pool_middle_container {
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
}

.pool_top_container {
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
  padding:20px;
  border-bottom: 1px solid #E4E4E4;
}
.pool_top_element{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F6F6F6;
  align-items: baseline;
}
.pool_top_subtitle{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  font-size: 16px;
  font-weight: 400;

}
.pool_top_title{
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: #757575;
}
.pool_bottom_container_button_div {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 10px 0;
}

.pool_riskrate_container {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pool_riskrate_container_title {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.pool_riskrate_container_text {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  margin-left: 10px;
}

.pool_bottom_container {
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
  padding:20px;
}
.pool_bottom_element{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F6F6F6;
}
.pool_bottom_subtitle{
  margin: 0;
  padding: 10px 0;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
}
.pool_bottom_title{
  margin: 0;
  padding: 10px 0;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.pool_bottom_container_button_div {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 20px 0;
}

.pool_bottom_container_button {
  width: 100%;
  background-color: var(--blue);
  padding: 12px;
  color: #fff;
  font-size: large;
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pool_bottom_container_button:hover {
  background-color: var(--blue-hover);
}

.pool_bottom_container_view_more_div {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.pool_view_more_text {
  margin: 0;
  padding: 0;
  color: var(--blue);
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.pool_more_details {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  margin-top: 20px;
  border-top: 1px solid #cfcfcf;
}

.pool_more_details_title {
  margin: 0;
  padding: 0;
  color: #323232;
  font-weight: 700;
  font-size: 14px;
}

.pool_more_details_subtitle {
  margin: 0;
  margin-top: 10px;
  padding: 0;
  color: #323232;
  font-weight: 300;
  font-size: 13px;
}

.pool_more_partner_title {
  margin: 0;
  padding: 0;
  color: #323232;
  font-weight: 700;
  font-size: 13px;
}

.pool_container_warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(237, 129, 52, 0.09);
  padding: 10px;
  border-radius: 3px;
}

.pool_container_warning_text {
  margin: 0;
  padding: 0;
  color: #ed8134;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.pool_template_card{
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  border: 1.5px solid #E4E4E4;
  background: #F4F4F4;
  padding: 40px 30px;
}

.pool_template_card_content_container{
  display: flex;
  flex:70% 1;
  flex-direction: row;
}

.pool_template_card_button_container{
   display: flex;
   flex:30% 1;
   align-items: center;
   justify-content: flex-end;
}

.pool_template_card_content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  margin:0 10px;
  background: rgba(255, 255, 255, 0.80);
}

.pool_template_card_content_title{
  color: #757575;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 0;
}

.pool_template_card_content_value{
  display: flex;
  flex-direction: row;
  color: #2E2E2E;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding:5px 0;
}

.pool_template_details_investment_main_header_button {
  width: 170px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #4A88F7;
  box-shadow: 0px 4px 4px 0px rgba(242, 242, 242, 0.25);
  color:#4A88F7;
  margin:0 10px;
}

.pool_template_investment_button{
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #4A88F7;
  color: #FFF;
  border: none;
  margin: 0 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}


.poollist_model_div {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    /* padding-left: 50px;
    padding-right: 50px; */
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  
  .poollist_model_title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .poollist_model_title {
    margin: 0;
    padding: 0;
    color: #414141;
    font-weight: bold;
    font-size: 18px;
  }
  .modal_client_amount_wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
  .poollist_model_input {
    width: 50%;
    padding: 10px;
    /* margin-top: 30px; */
    font-size: large;
    border-radius: 3px;
    border: 1px solid rgb(73, 73, 73);
  }
  
  .poollist_model_button {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .poollist_model_button:hover {
    background-color: var(--blue-hover);
  }
  
.pool_clientlist_model_div {
  display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 16.5%;
    margin-top: 170px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
}

.pool_clientlist_model_title_div {
  display: flex;
    width: 93%;
    flex-direction: row;
    align-content: space-around;
    align-items: center;
    padding-top: 21px;
    padding-bottom: 21px;
    padding-left: 40px;
    justify-content: space-between;
}

.pool_client_list_container_button_div {
  width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    display: flex;
    align-self: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: #faf9f9;
    box-shadow: 0px -2px 7px -1px #6b6b6b40;
}

.pool_client_add_more_section {
  color: #4A88F7;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 0px 40px 37px;
}

.pool_client_bottom_container_button{
  width: 280px;
  height: 50px;
  background-color: var(--blue);
  padding: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pool_client_bottom_container_button:hover {
  background-color: var(--blue-hover);
}

.pool_clientlist_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 20px;
}

.pool_clientlist_search_bar_div {
  margin-top: 15px;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.tool_tip {
  display: flex;
  width: 38%;
  justify-content: space-between;
}

.pool_clientlist_search_bar {
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  width: 20%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
}

.pool_clientlist_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.pool_clientlist_table_div {
  flex: 1 1;
  width: 100%;
  /* padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px; */
}

.pool_clientlist_action_button_invest_disabled {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid #a9a9a9;
  background-color: #fff;
  color: #a9a9a9;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.pool_clientlist_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.pool_clientlist_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.pool_clientlist_kyc_status_text {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  color: var(--orange);
  font-size: 11px;
}

.client_active_investments_loader_div {
  display: flex;
  width: 92%;
  height: 400px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.client_active_investments_container {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
  align-self: center;
}

/* .client_active_investments_title {
  margin-top: 20px;
  width: 92%;
  font-size: 22px;
  align-self: center;
  color: #42474f;
} */

.client_active_investments_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  border: 1px solid var(--blue);
  background-color: #fff;
  color: var(--blue);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.client_active_investments_button:hover {
  background-color: var(--blue);
  color: #fff;
}

.client_closed_investments_loader_div {
  display: flex;
  width: 92%;
  height: 400px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.client_closed_investments_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
}

/* .client_closed_investments_title {
  margin-top: 20px;
  width: 92%;
  font-size: 22px;
  align-self: center;
  color: #42474f;
} */

.client_closed_investments_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  border: 1px solid var(--blue);
  background-color: #fff;
  color: var(--blue);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.client_closed_investments_button:hover {
  background-color: var(--blue);
  color: #fff;
}

.clientlist_header_button_text {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  color: var(--white);
  text-transform: none;
}

.kyclink_model_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 350px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
  outline: none;
}

.kyclink_model_title_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.kyclink_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 18px;
}

.kyclink_model_input_div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.kyclink_model_input {
  padding: 10px;
  margin-top: 12px;
  font-size: large;
  border-radius: 3px;
  border: 1px solid rgb(73, 73, 73);
}

.kyclink_model_button {
  padding: 10px;
  width: 150px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
}

.kyclink_model_button:hover {
  background-color: var(--blue-hover);
}

.kyclink_input_err {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-size: 14px;
  height: 13px;
}

.kyclink_copy_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f6f6f6;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 450px;
}

.ifa_referral_code {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  background-color: white;
  border: 1px solid #429ef5;
  border-radius: 5px;
  margin-right: 20px;
  width: 25%;
  padding-left: 3px;
  padding-right: 3px;
}

.circle {
  font-size: 15px;
  color: #000 !important;
  text-align: center;
  /* background: #000; */
  width: 34px;
  height: 34px;
  padding: 8px;
  text-align: center;
}


.withdrawal_model_div {
    display: flex;
    flex-direction: column;
    /* padding: 30px; */
    background-color: #fff;
    /* border-radius: 3px; */
    border-radius: 0.5%;
    outline: none;
    padding-bottom: 50px;
    margin-left:2vw;
    margin-right: 2vw;
  
  }

  .withdrawal_model_header_title{
    padding: 2.5vh;
    margin: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
  }

  .withdrawal_model_main_div{
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .withdrawal_model_header{
    background-color: #F2F2F2;
    margin-bottom:30px;
    padding:0;
    align-items: left;
    text-align: left;
  }
  
  .withdrawal_model_title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .withdrawal_model_title {
    margin: 0;
    padding: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-size: 20px;
  }
  
  .withdrawal_model_input {
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    font-size: large;
    border-radius: 5px;
    border: 1px solid #424242;
  }
  
  .withdrawal_model_button {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 230px;
    height: 50px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
  
  .withdrawal_model_button:hover {
    background-color: var(--blue-hover);
  }

  .withdrawal_transaction_container{
    /* margin-top: 30px; */
    margin-top: 3%;
    height: auto;
    border: 1px solid #c6ccc8;
    /* border-radius: 5px; */
    border-radius: 1%;
    align-items: center;
    text-align: center;
  }

  .withdrawal_transaction_item_left {
    text-align: left;
    color: rgb(73, 73, 73);
  }
  
  .withdrawal_transaction_item_right {
    text-align: right;
    font-weight: bold;
  }
  
  .withdrawal_transaction_grid_container {
    display: grid;
    grid-template-columns: 70% 30%;
    justify-content: space-between;
    /* padding-left: 5px;
    padding-right: 5px; */
    padding-left: 5%;
    padding-right: 5%;
  }

  .withdrawal_transaction_freedom_plan_breakdown{

    margin-left: 20%;
    justify-content: start;
  }

  .withdrawal_transaction_freedom_plan_withdrables_container{
    display: grid;
    grid-template-columns: 60% 40%;
    justify-content: space-between;
  } 

  
.amount_locked_model_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  padding-bottom: 50px;
  width: 610px;
  height: 250px;
  flex-shrink: 0;
}

.amount_locked_model_header_title {
  display: flex;
  padding: 2.5vh;
  margin: 0;
  color: #2e2e2e;
  font-family: "Asap", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.amount_locked_model_main_div {
  padding: 0 30px;
}

.amount_locked_model_header {
  display: flex;
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 0;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.model-amount-locked-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-amount-locked-content-text {
  padding: 10px 0;
}

.additional-info-container {
    background-color: #ffebeb;
    color: red;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    overflow-x: none;
    margin-right: 10px;
    padding: 10px 5px;
    margin-top: 10px;
}
.additional-info-lock-icon-information {
    display: flex;
    align-items: center;
}
.Information-text {
    font-size: 14px;
}
.information-text-conatainer {
    display: flex;
    flex-direction: column;
}
.information-text1 {
    font-size: 14px;
}
.information-text2 {
    font-size: 12px;
    margin-top: 5px;
}

.otpenter_model_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.otpenter_model_title_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.otpenter_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 18px;
}

.otpenter_model_input {
  width: 60%;
  padding: 10px;
  margin-top: 20px;
  font-size: large;
  border-radius: 3px;
  border: 1px solid rgb(73, 73, 73);
}

.otpenter_model_button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 130px;
  margin-top: 15px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.otpenter_model_button:hover {
  background-color: var(--blue-hover);
}

.otpenter_model_info_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.otpenter_model_info {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #414141;
  font-size: 14px;
}
.otpenter_model_info_attempt_div {
  display: flex;
  flex-direction: row-reverse;
  align-items: left;
  margin-top: 15px;
  justify-content: left;
  float:left;
  inset-block-start:left;
}

.otpenter_model_info_atmpt {
  margin: 0;
  padding: 0;
  display: flex;
  justify-self: left;
  text-align: left;
  font-weight: 400;
  color: #414141;
  font-size: 14px;
  /* color: #951b1b; */
}

.overview_filter_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: 12%;
  height: 25px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.overview_filter_icon_1 {
  font-size: 22px;
  color: #a9a9a9;
}

.overview_filter_text {
  margin: 0;
  padding: 0;
  flex: 1 1;
  font-size: 13px;
  margin-left: 18px;
  color: #8a8888;
}

.overview_filter_dropdown_button {
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  padding-left: 10px;
  background-color: transparent;
}

.overview_filter_icon_2 {
  font-size: 22px;
  color: #a9a9a9;
}

.client_inprocess_investments_loader_div {
  display: flex;
  width: 92%;
  height: 400px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.client_inprocess_investments_container {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
  align-self: center;
}

/* .client_inprocess_investments_title {
    margin-top: 20px;
    width: 92%;
    font-size: 22px;
    align-self: center;
    color: #42474f;
  } */

.client_inprocess_investments_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.client_inprocess_investments_button:hover {
  background-color: var(--orange);
  color: #fff;
}

.input_box_field_container {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
  }

.input_box_input_container {
    margin-top: 12px;
    padding: 12px;
    font-size: large;
    border: 1px solid #494949;
    border-radius: 3px;
}
  
.input_box_container:disabled {
background-color: rgb(247, 247, 247);
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.input_box_label_container {
    margin-bottom: -6px;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: #272727;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    color: #414141;
  }

  .input_box_invalid_text {
    margin: 0;
    padding: 0;
    font-size: medium;
    color: var(--invalid);
  }

  .input_box_invalid_div {
    margin: 0;
    padding: 0;
    /* margin-top: 3px; */
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .input_box_input_container:disabled {
    background-color: #EDEDED;
    color: #1B1B1B;
  }
  @media only screen and (max-width: 550px) {
    .input_box_input_container {
      width: 90%;
    }
  }
  
.client_overview_chart_container {
  border: 1px solid #d1d1d1;
  border-radius: 16px;
  display: grid;
  height: 'auto';
  grid-template-rows: 74% 13% 13%;
  margin: 0px 10px 0px 0px;
  text-align: center;
}

.client_overview_chart {
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.client_overview_limit {
  background-color: #e6f2ff;
  padding-top: 3.3%;
  padding-bottom: 3.3%;
  align-self: center;
  text-align: center;
  justify-content: center;
}

.client_overview_limit_text {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.client_overview_footer {
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-top: 3.3%;
  padding-bottom: 2%;
  align-self: center;
  text-align: center;
  justify-content: center;
}

.client_overview_footer_text {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.client_overview_withdraw {
  border: 1px solid #d1d1d1;
  height: auto;
  border-radius: 16px;
  display: grid;
  grid-template-rows: 85% 15%;
  margin: 0px 10px 0px 0px;
  margin-top: 20px;
  padding-top: 4.8%;
  padding-bottom: 10%;
  padding-left: 5%;
  padding-right: 5%;
}

.client_overview_withdraw_detail {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: left;
}

.client_overview_withdraw_header_text {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom: 2;
  padding-bottom: 0;
}

.client_overview_withdraw_value_text {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #212121;
  margin-top: 0;
  padding-top: 0;
}

.client_overview_button {
  background-color: transparent;
  border: 1.3px solid #3a86ff;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  padding: 10px;
}
.client_overview_button_disabled{
  background-color: transparent;
  border: 1.3px solid #949598;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  padding: 10px;
}
.client_overview_button_disabled{
  background-color: transparent;
  height: 36px;
  border: 1.3px solid #949598;
  border-radius: 3px;
  justify-content: center;
  text-align: center;
  margin-left: 20px;
}

.client_overview_button_text {
  color: #3a86ff;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  align-self: center;
}
.client_overview_button_text_disabled {
  color: #949598;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  align-self: center;
}

.client_overview_button_text_disabled {
  color: #949598;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  align-self: center;
}

.client_overview_actions{
  padding:15px 0;
  border-radius: 8px;
  opacity: 0.7;
  background: #F6F6F6;
  margin: 30px 0;
}

.client_overview_action_buttons{
  padding: 10px 0;
}

.client_overview_actions_header {
  color:#757575;
  font-weight: 600;
  margin-left:20px;
}

.client_overview_investment_details{
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  /* margin-bottom: 30px; */
}

.investment_detail_card{
  display:flex;
  flex-direction: column;
  background-color: #EBF2FE;
  border-radius: 10px;
  width: 50%;
  margin: 20px 0;
  align-items: center;
  padding: 25px;
}

.investment_detail_info{
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  margin-bottom: 20px;
}

.investment_detail_content{
  text-align: center;
  font-family: Poppins;
  font-size: 38px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

.investment_detail_logo{
  display:flex;
  padding:10px;
}

.investment_detail_text{
  display: flex;
  flex-direction: column;
  padding:0 10px;
}

.investment_detail_button{
  display: flex;
  flex:20% 1;
  align-items: center;
  justify-content: center;
  border-top:0.5px solid #BDD0F2;
  padding:6px;
}

.investment_detail_value{
  font-weight: 600;
  font-size: 20px;
  color: #2E2E2E;
}

.investment_detail_header{
  font-size: 14px;
  color:#2E2E2E;
  padding:5px;
}

.view_details_button{
  color:#3A86FF;
  font-size: 14px;
}

.view_details_button:hover{
  text-decoration: underline;
  cursor:pointer;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
    transform: translate(-50%, -50%);
  width: 40%;
  background: rgba(0, 0, 0, 1);
  border: 1px solid #424242;
  border-radius: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999; 
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-button{
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 230px;
  height: 50px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
}

.disclaimer-container {
  padding: 15px 10px;
  border: 1px solid #fbe795;
  background: #fffbeb;
  font-size: 16px;
  color: #404040;
  border-radius: 4px;
  display: flex;
}

.info-icon {
  height: 20px;
  margin-right: 8px;
}

.disclaimer-text {
  font-size: 16px;
  color: #404040;
}

.bold-text{
  font-weight: "600";
  opacity: "0.9";
  font-size: "16px";
  color: "#404040"
}


@media screen and (max-width: 1300px) {
  .client_overview_footer_text {
    font-size:10px
  }

  .client_overview_button {
    width: 150px;
  }

  .client_overview_button_disabled{
    width: 150px;
  }

  .client_overview_button_text {
    font-size: 9px;
  }
  .client_overview_button_text_disabled {
    font-size: 9px;
  }
}

@media screen and (max-width: 1050px) {
  .client_overview_button {
    width: 120px;
  }
  .client_overview_button_disabled{
    width: 120px;
  }
  .client_overview_button_text {
    font-size: 9px;
  }
  .client_overview_button_text_disabled {
    font-size: 9px;
  }
  .client_overview_chart {
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-left: 0%;
  }

  .client_overview_footer_text {
    font-size:12px
  }
}

.wallet_loading_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    padding-bottom: 50px;
    margin-left:2vw;
    margin-right: 2vw;
  }

  .wallet_loading_model_header_title{
    padding: 2.5vh;
    margin: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
  }

  .wallet_loading_model_main_div{
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .wallet_loading_model_header{
    background-color: #F2F2F2;
    margin-bottom:30px;
    padding:0;
    align-items: left;
    text-align: left;
  }

  .wallet_loading_model_title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .wallet_loading_model_title {
    margin: 0;
    padding: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-size: 20px;
  }
  
  .wallet_loading_model_input {
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    font-size: large;
    border-radius: 5px;
    border: 1px solid #424242;
  }
  
  .wallet_loading_model_button {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 230px;
    height: 50px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
  
  .wallet_loading_model_button:hover {
    background-color: var(--blue-hover);
  }
  

.client_portfolio_request_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    padding-bottom: 50px;
    width: 610px;
    height: 350px;
    flex-shrink: 0;
  }

  .client_portfolio_request_model_header_title{
    display: flex;
    padding: 2.5vh;
    margin: 0;
    color: #2E2E2E;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }

  .client_portfolio_request_model_main_div{
    padding:0 30px;
  }

  .client_portfolio_request_model_header{
    display: flex;
    background-color: #F2F2F2;
    margin-bottom:10px;
    padding:0;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .client_portfolio_toggle_container {
    display: flex;
    align-self: center;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    border-bottom: 1px solid #D4D4D4;
  }
  .client_portfolio_toggle_element {
    font-size: 15px;
    color: #757575;
    margin-right: 10%;
    cursor: pointer;
  }
  .client_portfolio_toggle_element_active {
    font-size: 15px;
    font-weight: 600;
    color: #3a86ff;
    margin-right: 10%;
    cursor: pointer;
    border-bottom: 3px solid #3a86ff;
    padding-bottom: 12px;
  }
  .client_portfolio_investments_container{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  .client_portfolio_investments_content{
    display: flex;
    flex-direction: row;
    padding: 10px 0;
  }
  .client_portfolio_investments_text{
    display: flex;
    flex: 50% 1;
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .client_portfolio_investments_value{
    display: flex;
    flex:50% 1;
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  

.client_overall_earnings_request_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    padding-bottom: 50px;
    width: 610px;
    height: 290px;
    flex-shrink: 0;
  }

  .client_overall_earnings_request_model_header_title{
    display: flex;
    padding: 2.5vh;
    margin: 0;
    color: #2E2E2E;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }

  .client_overall_earnings_request_model_main_div{
    padding:0 30px;
  }

  .client_overall_earnings_request_model_header{
    display: flex;
    background-color: #F2F2F2;
    margin-bottom:10px;
    padding:0;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .client_overall_earnings_investments_container{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  .client_overall_earnings_investments_content{
    display: flex;
    flex-direction: row;
    padding: 10px 0;
  }
  .client_overall_earnings_investments_text{
    display: flex;
    flex: 50% 1;
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .client_overall_earnings_investments_value{
    display: flex;
    flex:50% 1;
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
.client_switch_plan_main_div {
  display: flex;
  flex-direction: column;
  background-color: white;
  outline: none;
  border-radius: 12px;
  overflow: hidden;
  width: 55%;
  padding-bottom: 15px;
}

.client_switch_plan_header {
  width: 100%;
  height: 65px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.client_switch_plan_title {
  margin: 0;
  padding: 0;
  margin-left: 18px;
  font-size: 20px;
  color: black;
}

.client_switch_plan_close_icon {
  margin: 0;
  padding: 0;
  margin-right: 30px;
  cursor: pointer;
}

.client_switch_plan_loader_div {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.client_switch_plan_top_div {
  margin-top: 15px;
}

.client_switch_plan_row_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.client_switch_plan_h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: black;
}

.client_switch_plan_h2 {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  font-size: 18px;
  color: black;
  font-weight: 700;
}

.client_switch_plan_h4 {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-size: 14px;
  color: #757575;
}

.client_switch_plan_investment {
  margin-top: 15px;
  align-self: center;
  background-color: #fbfbfb;
  border-radius: 4px;
  padding: 12px;
}

.client_switch_plan_investment_toggle {
  display: flex;
  width: 100%;
  align-self: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  border-bottom: 1px solid #d4d4d4;
}

.client_switch_plan_investment_active {
  font-size: 14px;
  font-weight: 600;
  color: #3a86ff;
  margin-right: 7%;
  cursor: pointer;
  padding-bottom: 12px;
  border-bottom: 3px solid #3a86ff;
}

.client_switch_plan_investment_inactive {
  font-size: 14px;
  color: #757575;
  margin-right: 7%;
  cursor: pointer;
}

.client_switch_plan_investment_sub_heading {
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  font-weight: 400;
  color: #757575;
}

.client_switch_plan_investment_content {
  margin-left: 0px;
  padding-left: 0px;
  font-size: 12px;
}

.client_switch_plan_select_tenure_text {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-size: 14px;
  color: #282828;
}

.client_switch_plan_tenure_container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.client_switch_plan_tenure_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
  border: 1px solid #aeaeae;
}

.client_switch_plan_tenure_text {
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-left: 5px;
}

.client_switch_plan_input_div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #424242;
  border-radius: 4px;
  width: 280px;
}

.client_switch_plan_input {
  width: 100%;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.client_switch_error_text {
  margin: 0;
  padding: 0;
  font-size: 11px;
  margin-top: 10px;
  color: #e73535;
}

.client_switch_info_text {
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-top: 18px;
  color: #757575;
}

.client_switch_plan_invest_button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 200px;
  margin-top: 15px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
}

.client_switch_plan_invest_button:hover {
  background-color: var(--blue-hover);
}

.client_switch_plan_list_div {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.client_switch_plan_list_row_div {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.client_switch_plan_list_text_heading {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  font-size: 14.5px;
  color: #363636;
}

.client_switch_plan_list_text_content {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  font-size: 14px;
  color: #171717;
}

.client_growth_plan_div_container {
  display: flex;
  flex-direction: column;
}

.client_growth_header {
  margin: 20px 0;
}
.client_growth_header_text{
  color: #2E2E2E;
  font-size: 16px;
  font-weight: 500;
}

.client_growth_header_content{
  display:flex;
  flex-direction: row;
  margin: 10px 0;
}

.client_growth_tenure_button{
  padding:10px 30px;
  border-radius: 19px;
  border: 1px solid #E1E0E0;
  margin-right:20px;
  color: #064586;
  cursor: pointer;
  font-size: 13px;
}
.client_growth_tenure_button_active{
  padding:10px 30px;
  border-radius: 19px;
  margin-right:20px;
  background-color: #3A86FF;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 13px;
}

.client_growth_card{
  padding: 40px 30px;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  border: 1.5px solid #E4E4E4;
  background: #F4F4F4;
}

.client_growth_card_content_container{
  display: flex;
  flex:70% 1;
  flex-direction: row;
}

.client_growth_card_button_container{
   display: flex;
   flex:30% 1;
   align-items: center;
   justify-content: flex-end;
}

.client_growth_card_content{
  padding: 15px;
  margin:0 10px;
  background: rgba(255, 255, 255, 0.80);
}

.client_growth_card_content_title{
  color: #757575;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 0;
}

.client_growth_card_content_value{
  color: #2E2E2E;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding:5px 0;
}

.client_growth_details_investment_main_header_button {
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #4A88F7;
  box-shadow: 0px 4px 4px 0px rgba(242, 242, 242, 0.25);
  color:#4A88F7;
  margin:0 10px;
}

.client_growth_investment_button{
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #4A88F7;
  color: #FFF;
  border: none;
  margin: 0 10px;
  cursor: pointer;
}

.client_growth_header_left_grid {
  display: grid;
  justify-content: center;
  align-self: center;
  grid-template-columns: 5% 95%;
  padding-left: 2%;
}


.client_growth_header_left_grid_text {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.client_growth_details {
  padding: 1% 2% 1% 2%;
}

.client_growth_details_summary {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: left;
  padding: 1%;
  padding-left: 2%;
  padding-right: 2%;
}

.client_growth_details_summary_header_text {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom: 0;
  padding-bottom: 0;
}

.client_growth_details_summary_value_text {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0e0e0e;
  margin-top: 0;
  padding-top: 0;
}

.client_growth_details_investment {
  padding: 2%;
  padding-left: 5%;
  padding-right: 5%;
}

.client_growth_details_investment_main_header {
  margin-bottom: 8vh;
  justify-content: center;
  align-self: center;
}

.client_growth_details_investment_main_header_text {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #232323;
}

.client_growth_details_investment_grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  text-align: left;
}

.client_growth_details_investment_header_text {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom: 0;
  padding-bottom: 0;
}

.client_growth_details_investment_value_text {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0e0e0e;
  margin-top: 0;
  padding-top: 0;
}

.client_growth_tenure_label {
  margin: 0px 0px 0px 0px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

#client_growth_tenure {
  accent-color: #064586;
  border-color: #064586;
  width: 30px;
  margin: 2px 0px 0px 0px;
  cursor: pointer;
}

.client_growth_radio_button_container {
  text-align: left;
  margin-bottom: 2%;
}

.client_growth_radio_button_inner_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.client_growth_radio_button {
  border: 1px solid #064586;
  padding: 1.5%;
  display: inline;
  margin-left: 2%;
  margin-top: 2%;
  border-radius: 4px;
  cursor: pointer;
  width: 30%;
  box-sizing: border-box;
}

.payout_radio_button_container {
  text-align: left;
  margin-top: 30px;
  align-items: space-between;
}

.payout_radio_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5%;
  margin-right: 15px;
  cursor: pointer;
}

.payout_radio_button_sub_container {
  display: grid;
  grid-template-columns: 35% 35%;
  grid-gap: 0%;
  text-align: left;
  margin-bottom: 1vh;
  margin-left: 5px;
}

.payout_radio_label {
  font-size: 13px;
  font-family: "Poppins";
  cursor: pointer;
  font-weight: 400;
  color: #282828;
  margin-left: 3px;
}

@media screen and (max-width: 1500px) {
  .client_growth_details_summary_header_text {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    color: #282828;
  }
  .client_growth_details_summary_value_text {
    font-size: 16px;
  }

  .client_growth_header_left_grid_text {
    font-size: 17px;
  }
}


.client_investment_request_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    padding-bottom: 50px;
  }

  .client_investment_request_model_header_title{
    display: flex;
    padding: 2.5vh;
    margin: 0;
    color: #2E2E2E;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }

  .client_investment_request_model_main_div{
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .client_investment_request_model_header{
    display: flex;
    background-color: #F2F2F2;
    margin-bottom:30px;
    padding:0;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .client_investment_request_model_title_div {
    align-items: center;
    text-align: center;
  }
  
  .client_investment_request_model_title {
    color: #757575;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 0 3px 0 5px;
  }
  
  .client_investment_request_model_input {
    width: 350px;
    padding: 10px;
    margin-top: 30px;
    font-size: large;
    border-radius: 5px;
    border: 1px solid #424242;
  }
  
  .client_investment_request_model_button {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 250px;
    height: 45px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
  
  .client_investment_request_model_button:hover {
    background-color: var(--blue-hover);
  }

  .client_investment_request_content{
    display: flex;
    flex: column 1;
    padding-top: 10px;
  }

  .client_investment_request_model_heading{
    color: #2E2E2E;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media screen and (max-width: 550px) {
    .client_investment_request_model_input {
      width: 260px;
    }

    .client_investment_request_model_button {
      width: 150px;
    }
  }
  
.client_growth_plan_investments_model_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  padding-bottom: 50px;
  margin-left: 2vw;
  margin-right: 2vw;
  width: 50vw;
}

.client_growth_plan_investments_model_header_title {
  padding: 2.5vh;
  padding-left: 2.5vw;
  margin: 0;
  color: #343434;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.client_growth_plan_investments_model_main_container_div {
  display: flex;
  flex-direction: column;
  padding-left: 1.5vw;
  padding-right: 1vw;
  padding-bottom: 2vh;
  max-height: 40vh;
  overflow-y: scroll;
}

.client_growth_plan_investments_model_main_div {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  text-align: left;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 1vh;
}

.client_growth_plan_investments_model_line_div {
  padding-bottom: 1.5vh;
}

.client_growth_plan_investments_model_header {
  background-color: #f2f2f2;
  margin-bottom: 30px;
  padding: 0;
  align-items: left;
  text-align: left;
}

.client_growth_plan_investments_model_left_div {
  display: grid;
  grid-template-columns: 12% 40%;
}

.client_growth_plan_investments_model_title {
  margin: 0px 0px 0px 0px;
  padding: 0;
  color: #686868;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 14px;
}

.client_growth_plan_investments_model_title_value {
  margin: 0;
  padding: 0;
  color: #3F3F3F;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.client_growth_plan_investments_model_input {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  font-size: large;
  border-radius: 5px;
  border: 1px solid #424242;
}

.client_growth_plan_investments_model_button {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 230px;
  height: 50px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Lato", sans-serif;
}

.client_growth_plan_investments_model_button:hover {
  background-color: var(--blue-hover);
}

.arrow_downward_icon_outer_circle_div {
  width: 25px;
  height: 25px;
  background-color: #064586;
  border-radius: 50%;
  position: relative;
}

.arrow_downward_icon_div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 850px) {
  .client_growth_plan_investments_model_div {
    width: 90vw;
  }

  .client_growth_plan_investments_model_left_div {
    grid-template-columns: 8% 70%;
  }

  .client_growth_plan_investments_model_info {
    text-align: center;
  }

  .client_growth_plan_investments_model_title {
    font-size: 10px;
  }

  .client_growth_plan_investments_model_title_value {
    font-weight: 500;
    font-size: 12px;
  }
}

.client_freedom_plan_div_container {
  display: flex;
  flex-direction: column;
  margin :20px 0;
}

.client_freedom_card{
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1.5px solid #E4E4E4;
  background: #F4F4F4;
  justify-content: space-between;
  padding:20px 10px;
}

.client_freedom_card_content_container{
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.client_freedom_card_button_container{
   display: flex;
   margin: 20px 20px;
   align-items: center;
}

.client_freedom_card_content{
  padding: 20px;
  margin:20px 10px;
  background: rgba(255, 255, 255, 0.80);
}

.client_freedom_card_content_title{
  color: #757575;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.client_freedom_card_content_value{
  color: #2E2E2E;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top:10px;
}

.client_freedom_details_investment_main_header_button {
  width: 220px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #4A88F7;
  box-shadow: 0px 4px 4px 0px rgba(242, 242, 242, 0.25);
  color:#4A88F7;
  margin:0 10px;
}

.client_freedom_investment_button{
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #4A88F7;
  color: #FFF;
  border: none;
  margin: 0 10px;
  cursor: pointer;
}

.client_freedom_header {
  padding: 2% 2% 2% 2%;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.client_freedom_header_left_grid {
  display: grid;
  justify-content: left;
  align-self: left;
  grid-template-columns: 4% 80%;
}

.client_freedom_header_left_grid_text {
  text-align: left;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.client_freedom_details_summary {
  display: grid;
  padding: 1% ;
  padding-left: 5%;
  padding-right: 5%;
  grid-template-columns: 50% 50%;
  text-align: left;
}

.client_freedom_details_summary_header_text{
  font-size:14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom:0;
  padding-bottom:0;
}

.client_freedom_details_summary_value_text{
  font-size:22px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0E0E0E;
  margin-top:0;
  padding-top:0;
}

.client_freedom_details_investment{
  padding: 2%;
  padding-left: 5%;
  padding-right: 5%;
}

.client_freedom_details_investment_main_header{
  margin-bottom: 8vh;
  justify-content: center;
  align-self: center;
}

.client_freedom_details_investment_main_header_text{
  font-size:16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #232323;
}

.client_freedom_details_investment_grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%; 
  text-align: left;
}

.client_freedom_details_investment_header_text{
  font-size:14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom:0;
  padding-bottom:0;
}

.client_freedom_details_investment_value_text{
  font-size:14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0E0E0E;
  margin-top:0;
  padding-top:0;
}

.client_freedom_plan_earning_button{
  background-color: transparent;
  width: 160px;
  height: 36px;
  border: 1.3px solid #3A86FF;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
  text-align: center; 
}

.client_freedom_plan_earning_button_text{
  color: #3A86FF;
  font-size: 20px;
  font-weight: 500;
  align-self: center;
}

@media screen and (max-width: 1500px) {
  .client_freedom_details_summary_header_text{
    font-size:11px;
  }
  .client_freedom_details_summary_value_text{
    font-size:16px;
  }

  .client_freedom_header_left_grid_text {
    font-size: 17px;
  }
  }

.client_earnings_model_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  padding-bottom: 50px;
  margin-left: 2vw;
  margin-right: 2vw;
  width: 50vw;
}

.client_earnings_model_header_title {
  padding: 2.5vh;
  padding-left: 2.5vw;
  margin: 0;
  color: #343434;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.client_earnings_model_main_container_div {
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vh;
  max-height: 40vh;
  overflow-y: scroll;
}

.client_earnings_model_main_div {
  display: grid;
  grid-template-columns: 27% 27% 27%;
  grid-gap: 10.5%;
  text-align: left;
  padding: 1vh 1vw 1vh 1vw;
  margin-bottom: 1vh;
  background-color: #F9F9F9;
}

.client_earnings_model_main_value_div {
  display: grid;
  grid-template-columns: 27% 27% 27%;
  grid-gap: 10.5%;
  text-align: left;
  padding: 1vh 1vw 1vh 1vw;
}


.client_earnings_model_line_div {
  padding-bottom: 1.5vh;
}

.client_earnings_model_header {
  background-color: #f2f2f2;
  margin-bottom: 30px;
  padding: 0;
  align-items: left;
  text-align: left;
}


.client_earnings_model_title {
  margin: 0px 0px 0px 0px;
  padding: 0;
  color: #3E3E3E;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 14px;
}

.client_earnings_model_title_value {
  margin: 0;
  padding: 0;
  color: #3F3F3F;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.client_earnings_model_input {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  font-size: large;
  border-radius: 5px;
  border: 1px solid #424242;
}

.client_earnings_model_button {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 230px;
  height: 50px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Lato", sans-serif;
}

.client_earnings_model_button:hover {
  background-color: var(--blue-hover);
}

.arrow_downward_icon_outer_circle_div {
  width: 25px;
  height: 25px;
  background-color: #064586;
  border-radius: 50%;
  position: relative;
}

.arrow_downward_icon_div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.client_earnings_model_line_div {
  padding-bottom: 1.5vh;
}

@media screen and (max-width: 850px) {
  .client_earnings_model_div {
    width: 90vw;
  }

  .client_earnings_model_info {
    text-align: center;
  }
}

.client_freedom_plan_error_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  padding-bottom: 50px;
  margin-left: 2vw;
  margin-right: 2vw;
  width: 50vw;
}


.client_otpenter_model_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  
  .client_otpenter_model_title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .client_otpenter_model_title {
    margin: 0;
    padding: 0;
    color: #414141;
    font-weight: bold;
    font-size: 18px;
  }
  
  .client_otpenter_model_input {
    width: 60%;
    padding: 10px;
    margin-top: 20px;
    font-size: large;
    border-radius: 3px;
    border: 1px solid rgb(73, 73, 73);
  }
  
  .client_otpenter_model_button {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 130px;
    margin-top: 15px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .client_otpenter_model_button:hover {
    background-color: var(--blue-hover);
  }
  
  .client_otpenter_model_info_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }
  
  .client_otpenter_model_info {
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #414141;
    font-size: 14px;
  }
  
.pdf_viewer_model_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  
  .pdf_viewer_model_title_div {
    display: flex;
    flex-direction: row;
    text-align: justify;
  }
  
  .pdf_viewer_model_title {
    margin: 0;
    padding: 0;
    color: #414141;
    font-weight: bold;
    font-size: 18px;
    font-family:"Times New Roman", Times, serif;
  }

  .pdf_viewer_model_body {
    margin: 0;
    padding: 0;
    color: #414141;
    font-weight: bold;
    font-size: 18px;
    font-family:"Times New Roman", Times, serif;
  }
  
  .pdf_viewer_model_input {
    width: 60%;
    padding: 10px;
    margin-top: 20px;
    font-size: large;
    border-radius: 3px;
    border: 1px solid rgb(73, 73, 73);
  }
  
  .pdf_viewer_model_button {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 130px;
    /* margin-top: 15px; */
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .pdf_viewer_model_button:hover {
    background-color: var(--blue-hover);
  }
  
  .pdf_viewer_model_info_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }
  
  .pdf_viewer_model_info {
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #414141;
    font-size: 14px;
  }

  .container_tnc {
    background: linear-gradient(to bottom, #f6f6f6 80px, white 0%); 
    box-sizing: border-box;
    border: 1px solid #b0b5b1;
    border-radius: 10px;  
    padding: 30px 20px;
    padding: 0;
    width: 92%;
    margin-top: 20px;
    align-self: center;
    color: #42474f;
    font-weight: 800;
    font-size: 28px;
  }

  .pdf_viewer_header_container{
    display: grid;
    grid-template-columns: 96% 4%;
  }

  ::-webkit-scrollbar {
    width: 0px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
    background: #faf7f8;
    border-radius: 5px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #70696c;
    border-radius: 5px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }

    @media only screen and (max-width: 550px) {
      .pdf_viewer_header_container{
        display: grid;
        grid-template-columns: 90% 10%;
      }
      
    }
    


  


.account_creation_form_container {
  width: 100%;
  margin-top: 20px;
}

.account_creation_gst_toggle_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.account_creation_field_container {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
}

.account_creation_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.account_creation_input_box_flex_container {
  display: flex;
  align-items: center;
}

.account_creation_footer {
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.account_creation_footer_text {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #6b6b6b;
  font-weight: 500;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

.account_creation_text_link {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: var(--blue);
  font-weight: 500;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  margin-left: 5px;
}

.kyc_details_form_container {
  width: 100%;
  margin-top: 20px;
}
.bank_verify_form_container {
    width: 100%;
    margin-top: 20px;
  }

  .bank_verify_checkbox {
    display: flex;
    flex-direction: row;
    margin-top: -1%;
    margin-left: 0px;
  }

  .bank_verify_checkbox_condition {
    font-size: 11px;
    margin-left: 0px;
    margin-top: 9px;
    color: rgb(105, 105, 105);
  }

  .bank_verify_checkbox_container {
    width: 100%;
  }
.template_ifa_agreement_page_break{
    page-break-before : always;
}

ol,ul,li {
    margin:0;
    padding:0px 0px 0px 10px;
}

p {
    padding: 5px;
    margin: 5px;
    font-size: 13px;
}

.template_ifa_agreement_container{
    margin:0;
    text-align: center;
    justify-content: center;
    width:100vw;
    letter-spacing: 0.01px;
}

.template_ifa_agreement_heading{
    padding-top:30px;
    padding-bottom:50px;
}

.template_ifa_agreement_heading_text{
    color:black;
    font-size:18px;
    font-weight: 800;
}

.template_ifa_agreement_sub_heading_text{
    color:black;
    font-size:16px;
}

.template_ifa_agreement_underline{
    padding-bottom: 2px;
    border-bottom: black 1px solid;
}

.template_ifa_agreement_p_justify{
    text-align: justify;
}

.template_ifa_agreement_main_point_heading{
    font-size:17px;
    color:black;
    font-weight: 800;
}

.template_ifa_agreement_bullet_container{
    display: grid;
    grid-template-columns: 6% 94%;
    padding:0;
    margin:0;
}

.template_ifa_agreement_authority{
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}

.template_ifa_agreement_bottom_heading{
    padding-bottom:50px;
}

@media only screen and (max-width: 550px){
    .template_ifa_agreement_container{
      width: 900px;
    }  
}

.main_signup {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.signup_container {
  display: flex;
  flex-direction: column;
  /* margin-top: 50px; */
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 40px;
  padding-right: 40px;
  width: 75%;
  /* border: 1px solid #c3c3c3; */
  border-radius: 3px;
}

.thank_page_container {
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 728px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #50cd89;
  border-radius: 25px;
}

.kyc_main_image_div {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  border: none;
}

.kyc_main_image_div_container {
  display: flex;
  height: 45px;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.kyc_main_image_text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.signup_header {
  display: flex;
  width: 100%;
  align-items: center;
}

.signup_header_title {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.signup_form_container {
  width: 100%;
  margin-top: 20px;
}

.signup_field_container {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
}

.signup_label_container {
  margin-bottom: -6px;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #272727;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  color: #414141;
}

.pan_conatainer_main {
  margin-top: 15px;
}

.upload_pan_title {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}
.pan_upload {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: grey;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}
.pan_upload_container {
  display: flex;
}

.pan_uploaded {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.pan_upload_button {
  margin-top: 1%;
  width: 25%;
  margin-left: 5%;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: #ffffff;
  /* color: #3a86ff; */
  border-style: solid;
  /* border-color:#3a86ff; */
  border-radius: 4px;
  /* border: none; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.signup_input_container {
  margin-top: 12px;
  padding: 12px;
  font-size: large;
  border: 1px solid #494949;
  border-radius: 3px;
}

.signup_input_container:disabled {
  background-color: rgb(247, 247, 247);
}

.signup_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_details_bank_verify_checkbox_container {
  width: 100%;
}

.kyc_details_bank_verify_checkbox {
  display: flex;
  flex-direction: row;
  margin-top: -1%;
  margin-left: 0px;
}

.checkbox_condition {
  font-size: 11px;
  margin-left: 0px;
  margin-top: 9px;
  color: rgb(105, 105, 105);
}

.signup_submit_container_disable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
  font-size: 14px;
  background-color: #b9b9b9;
  color: #ffffff;
  outline: none;
  cursor: not-allowed;
  border-radius: 3px;
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 100%;
  font-size: 18px;
}

.signup_footer {
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.signup_footer_text {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #6b6b6b;
  font-weight: 500;

  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  color: #414141;
}

.signup_footer_text_link {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  font-size: 18px;
  color: var(--blue);
  font-weight: 500;
  cursor: pointer;
}

.signup_notice_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  width: 60%;
  border-radius: 3px;
}

.signup_notice_text {
  font-size: 16px;
}

.signup_invalid_div {
  margin: 0;
  padding: 0;
  /* margin-top: 3px; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signup_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

/*Drop down div */
.org_check {
  display: flex;
  align-items: center;
}

.selectDropDown {
  width: 100%;
  margin-top: -8px;
  margin-left: 12px;
}

.selectFirmText {
  margin: 0;
  padding-bottom: 6px;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}
.info_text_container {
  margin-top: 10px;
  font-size: 50px;
}

#upload_doc_firm {
  margin-top: 8px;
}

.dropDownItem {
  border-bottom: 0.5px solid #aeaeae;
  width: auto;
  padding: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Asap";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  transform: translate3d(0, 0, 0) !important;
  color: #000000;
}

.dropdownContent {
  background: #ffffff;
  border: 1px solid grey;
  width: auto;
  overflow-y: scroll;
  /* -ms-overflow-style: none;  IE and Edge */
  /* scrollbar-width: none;   Firefox */
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid grey;
  width: auto;
  margin-bottom: -147px;
  z-index: 4;
  overflow-y: scroll;
  position:fixed;
}

.dropdownContent::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
}

.gstToggleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.gstToggle {
  /* margin-top: 7px; */
  /* margin-left: 15px; */
}

.policy_text{
  cursor: pointer;
  font-size: 11px;
  color: #3a86ff;
}

.clientkyc_main_input_field {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}
.optional_label {
  color: gray;
  font-size: smaller;
  margin-left: 5px;
}

@media only screen and (max-width: 1190px) {
  #mobileNumber {
    width: 80%;
  }
}

.bank_verify_form_container {
    width: 100%;
    margin-top: 20px;
  }

  .bank_verify_checkbox {
    display: flex;
    flex-direction: row;
    margin-top: -1%;
    margin-left: 0px;
  }

  .bank_verify_checkbox_condition {
    font-size: 11px;
    margin-left: 0px;
    margin-top: 9px;
    color: rgb(105, 105, 105);
  }

  .bank_verify_checkbox_container {
    width: 100%;
  }
.pdf_viewer_model_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.pdf_viewer_model_title_div {
  display: flex;
  flex-direction: row;
  text-align: justify;
}

.pdf_viewer_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
}

.pdf_viewer_model_body {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
}

.pdf_viewer_model_input {
  width: 60%;
  padding: 10px;
  margin-top: 20px;
  font-size: large;
  border-radius: 3px;
  border: 1px solid rgb(73, 73, 73);
}

.pdf_viewer_model_button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  margin-left: 15px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pdf_viewer_download_button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  margin-right: 15px;
  color: "#3a86ff";
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pdf_viewer_model_button:hover {
  background-color: var(--blue-hover);
}

.pdf_viewer_model_info_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.pdf_viewer_model_info {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #414141;
  font-size: 14px;
}

.container_tnc {
  background: linear-gradient(to bottom, #f6f6f6 80px, white 0%);
  box-sizing: border-box;
  border: 1px solid #b0b5b1;
  border-radius: 10px;
  padding: 30px 20px;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.pdf_viewer_header_container {
  display: grid;
  grid-template-columns: 96% 4%;
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #faf7f8;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #70696c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 550px) {
  .pdf_viewer_header_container {
    display: grid;
    grid-template-columns: 90% 10%;
  }
}

.terms-conditions-container {
  background-color: #fbf8fd;
  margin: 20px;
  border-radius: 25px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.terms-conditions-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 70vh;
}

.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px;
}

.header-title {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.embed-frame {
  width: 100%;
  height: calc(100% - 70px); 
  border: none;
  outline: none;
  
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  box-sizing: border-box; 
  background: #FBF8FD; 
}

.download-button-container {
  display: flex;
  align-items: center;
}

.download-button-icon {
  margin-right: 2px;
  height: 32px;
  width: 32px;
}

.download-button {
  color: #3a86ff;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
}

.download-button.hidden {
  display: none;
}

.custom-button {
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  font-family: 'Poppins', sans-serif;
  background-color: #3a86ff;
  cursor: pointer;
}

.custom-button.disabled {
  background-color: #ada8a8;
  cursor: not-allowed;
}

.org_document_form_container {
    width: 100%;
    margin-top: 50px;
  }

  .info_text_container {
    margin-top: -5px;
    font-size: 50px;
}
.marketitem {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: dense;
    grid-template-rows: repeat(5, 1fr);
    margin-right:8%;
    margin-left:5%;
  }


.marketcontainer { 
  position: static;
  border: 1.5px solid hsla(0, 0%, 39%, 0.835); 
  padding:5%;
  border-radius: 25px;
  height:75%;
  width:80%;
  display: flex;
  justify-content: center;
 
}

.marketcontainer img { 
  display: inline-block; 
  margin-left: auto;
  border-radius: 25px;
  height: 10%;
  width:10%;
 }
 .marketcontainer object { 
  display: static;
  border-radius: 25px;
  height: 80%;
  width:100%;
  /* margin-bottom: 5px; */
  align-self: center;
  border:5%;
  object-fit: cover;
 }
.marketcontainer .fa-download { 
  background-color: transparent;
  border: none;
  cursor: pointer;
  float: right;
  margin-top:5%
  }
.marketcontainer .marketleft {
  justify-content: space-between;
  float:left;
  margin-left: auto;
  color: hsla(0, 0%, 39%, 0.835); 
  margin-top:5%;
  font-weight: 700;
} 
  
.marketlabel{
  display : flex;
  justify-content: space-between;
}
.index-label {
  margin-right: 10px;
  font-weight: bold;
}

.item1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;
    grid-template-rows: repeat(3, 1fr);
    margin-right:20%
  }
  
  .item2 {
    width: 120px;
    min-height: 120px;
    max-height: auto;
    float: left;
    margin: 3px;
    padding: 3px;
}

.container { 
  position: static;
  /* max-width: 33%; */
  border: 6px solid #0000000d; 
  padding:5%;
  /* border:20%; */
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 25px;
  /* edit */
  display: flex;
  justify-content: space-between;
  height: 250px;
  width:330px;
  /* align-items: center; */
  
}
.container .containertext {
  background-color: #f0f0f0; 
  padding:5%;
  border:2%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top:3%;
  border-radius: 15px;
  height: 190px;
  width:280px;
  font-size:medium;
  overflow: auto;
  align-self: center;
}

.container img { 
  display: inline-block;
  /* margin-left: auto; */
  /* display: flex; */
  border-radius: 25px;
  margin-bottom: auto;
  /* margin-bottom: 100px; */
  /* padding:3% */
  /* float:right; */
  /* padding:1% */
  
 }

.container .msg{
  display: inline-block;
  justify-content: space-between;
  float:left;
  /* padding:3%; */
  margin-bottom: auto;
  margin-right: 10%;
  margin-left: 2.2%;
  /* padding:1% */

}
.container .fa-download { 
  background-color: transparent;
  border: none;
  cursor: pointer;
  float:right;
  margin-left: 16%;
}

.label{
  display : flex;
  justify-content: space-between;
  padding:5%;
}
.logo_main_input_form {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    width:300px;
  }

.logo_main_input_form .img{
  width:100%;
  height:100%
}
.logo_main_input_label {
font-size: 16px;
margin: 0;
padding: 0;
font-weight: normal;
margin-bottom: 12px;
}

.logo_main_image_div_container {
display: flex;
height: 100%;
width: 100%;
margin: 0;
padding: 0;
margin-bottom: 12px;
border: 1px solid #cacaca;
border-radius: 3px;
flex-direction: row;
align-items: center;
overflow: hidden;
}
  
.logo_main_image_div {
flex: 1 1;
width:100%;
display: flex;
align-items: center;
margin: 0;
padding: 0;
padding-left: 12px;
border: none;
}
  
.logo_main_image_text {
margin: 0;
padding: 0;
font-size: 16px;
height:100%;
width:100%;
}

.logo_main_image_button {
height: 100%;
width: 28%;
display: flex;
align-items: center;
justify-content: center;
font-size: medium;
background-color: var(--blue); 
color: #fff;
border: none;
cursor: pointer;
outline: none;
}
.logo_main_image_button:hover {
    background-color: var(--blue-hover);
    }

/* @media only screen and (max-width: 550px) {
    .logo_main_image_button {
      width: 28%;
    }
} */

.logo_field_container {
    display: flex;
    flex-direction: column;
  }
.logo_submit_container {
  background-color: var(--blue);
  color: #fff;
  align-items: "center";
  display: "flex";
  align-self: "center";
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 50%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  margin-left:25%
}
/* .download_image {
    display: block;
    margin-bottom: 10px;
    width:300px;
    padding:5%;
  } */
  .support_header {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    grid-row: 1 / 2;
  }
.client-item {
    margin-bottom: 10px;
  }
  .client-item label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .client-item input[type="checkbox"] {
    margin-right: 10px;
  }

  .parent_div{
    background-color: #fff;
    border:2px solid rgb(124, 122, 122);
    border-radius: 5px;

  }

  .list_client_model_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    padding-left: 50px;
    padding-right: 50px;
    height:"50%";
    width:"50%";
    background-color: #fff;
    border-radius: 5px;
    outline: none;
    height:600px;
    width:500px;
    overflow-y: "auto";
  }

  .client_list{
    overflow-y: "auto";
    height: 300px;
    width:300px;
    border: 2px solid rgb(124, 122, 122);
    padding:4%;
    border-radius: 2%;
  } 

  .text_input_box_message {
    width:300px;
    height: 150px;
    margin-top: 15px;
    padding: 4%;
    font-size: 14px;
    border: 1px solid #c3c3c3;
    border-radius: 2%;
    /* align-self: center; */
    overflow-y: "auto";
  }

  
  .text_input_box_message::placeholder {
    color: #838383;
    margin-top: 0px;
  }


  .send_submit_container {
    background-color: var(--blue);
    color: #fff;
    margin-top: 15px;
    padding: 4%;
    border: none;
    width:350px;
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    
  }

  .send_submit_container:hover {
    background-color: var(--blue-hover);
  }
  
@media print {
	.documentToPdf {
		page-break-before: always;
		break-before: always;
    page-break-after: always;
    break-after: always; 
	}
}

.dropdown{
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}

.dropdown_button {
    width: inherit;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    padding-right: 10px;
    background: #ffffff;
    border: 1px solid #060606;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #626262;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 48px;
    display: flex;
    align-items: center;
    /* cursor:not-allowed; */
  }

  .dropdow_disable{
    width: inherit;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    padding-right: 10px;
    background: #ffffff;
    border: 1px solid #060606;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #060606;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 48px;
    display: flex;
    align-items: center;
  }

  .dropdown_select_dropdown {
    width: 100%;
    margin-top: -8px;
    margin-left: 12px;
  }
  
  .dropdown_select_firm_text {
    margin: 0;
    padding-bottom: 6px;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #414141;
  }
  
  .dropdown_content {
      background: #ffffff;
      border: 1px solid grey;
      overflow-y: scroll;
      box-sizing: border-box;
      border-radius: 2px;
      border: 1px solid grey;
      margin-bottom: -147px;
      width: 100%;
      height: auto;
      z-index: 100;
      overflow-y: scroll;
      position:absolute
    }

  
 @media only screen and (max-width: 1200px){

} 
.signup_submit_container {
    background-color: var(--blue);
    color: #fff;
    margin-top: 12px;
    padding: 12px;
    border: none;
    width: 100%;
    font-size: 18px;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
  }

  .signup_field_container {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
  }
.resend_otp_container {
    margin-bottom: -6px;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: #272727;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    color: #414141;
  }

  .resend_otp_label_container {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: #272727;
  }
.consent_text_container {
    width: 100%;
  }

  .consent_text_checkbox {
    display: flex;
    flex-direction: row;
    margin-top: -1%;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .consent_text_condition {
    font-size: 11px;
    margin-left: 0px;
    margin-top: 9px;
    color: rgb(105, 105, 105);
  }

  .consent_text_policy_text{
    cursor: pointer;
    font-size: 11px;
    color: #3a86ff;
  }
  
/* .upload_input_v2_main_contianer{
    margin-top:10px;
} */

.upload_input_title {
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #414141;
  }

  .upload_input_container {
    display: flex;
  }

  .upload_input_uploaded {
    margin-top: 1%;
    width: 70%;
    background: #ffffff;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    border-radius: 4px;
    height: 46px;
    float: left;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 11px 5px 12px 21px;
  }

  .upload_input_upload {
    margin-top: 1%;
    width: 70%;
    background: #ffffff;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    border-radius: 4px;
    height: 46px;
    float: left;
    color: grey;
    display: flex;
    flex-direction: column;
    padding: 11px 5px 12px 21px;
  }

  .upload_input_button {
    margin-top: 1%;
    width: 25%;
    margin-left: 5%;
    height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    background: #ffffff;
    /* color: #3a86ff; */
    border-style: solid;
    /* border-color:#3a86ff; */
    border-radius: 4px;
    /* border: none; */
  }

  .upload_input_info_text_container {
    margin-top: 15px;
    font-size: 50px;
  }
.input_container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.file_input_container {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}

.file_input_title {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
  margin-bottom: 15px;
}

.file_input_uploaded {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.file_input_upload {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  justify-content: center;
  float: left;
  color: grey;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.file_input_button {
  margin-top: 1%;
  width: 25%;
  margin-left: 5%;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  border-color: white;
  line-height: 18px;
  background: var(--blue);
  color: #ffffff;
  border-radius: 4px;
}

.file_placeholder{
  color: #494949;
}

.file_placeholder_uploaded{
  color: black;
}

.file_input_info_text_container{
  margin-top:10px;
}
.action_dropdown_button_text {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    color: var(--white);
  }
  
.transaction_history_model_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  padding-bottom: 50px;
  margin-left: 2vw;
  margin-right: 2vw;
  width: 50vw;
}

.transaction_history_model_header_title {
  padding: 2.5vh;
  margin: 0;
  color: #343434;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.transaction_history_model_main_container_div {
  display: flex;
  flex-direction: column;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 2vh;
  max-height: 50vh;
  overflow-y: scroll;
}

.transaction_history_model_main_container_div ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.transaction_history_model_main_container_div ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.transaction_history_model_main_div {
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 1vh;
}

.disclaimer_model {
  margin-left: 3vw;
  margin-right: 3vw;
  margin-bottom: 2vh;
}

.transaction_history_model_line_div {
  padding-bottom: 1.5vh;
}

.transaction_history_model_header {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 0;
  align-items: left;
  text-align: left;
}

.transaction_history_model_left_div {
  display: grid;
  grid-template-columns: 10% 50%;
}

.transaction_history_model_title {
  margin: 0;
  padding: 0;
  color: #1b1b1b;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.transaction_history_model_title_description {
  margin: 2px 0px 10px 0px;
  padding: 0;
  color: #737373;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 12px;
}

.transaction_history_model_input {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  font-size: large;
  border-radius: 5px;
  border: 1px solid #424242;
}

.transaction_history_model_button {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 230px;
  height: 50px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Lato", sans-serif;
}

.transaction_history_model_button:hover {
  background-color: var(--blue-hover);
}

.transaction_history_model_loader_div {
  display: flex;
  width: 92%;
  height: 400px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.arrow_downward_icon_outer_circle_div {
  width: 25px;
  height: 25px;
  background-color: #064586;
  border-radius: 50%;
  position: relative;
}

.arrow_downward_icon_div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.download_statement_container_div {
  display: flex;
  flex-direction: column;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 2vh;
  max-height: 50vh;
}

.download_statement_title_div {
  padding-left: 0;
  color: #212121;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 25.36px;
}

.download_statement_sub_container_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #adadad;
  border-radius: 4px;
  height: 121px;
  margin-bottom: 20px;
}
.download_statement_sub_title_div {
  padding-left: 0;
  color: #3a3a3a;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  margin-left: 4%;
  margin-top: 10px;
}
.download_statement_date_selection_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  margin-top: 0;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 4%;
}
.date_selection_column_div {
  height: 30px;
  width: 30%;
}

.date_picker_input {
  border-color: #adadad;
  height: 40px;
  width: 100%;
  padding-left: 6%;
  padding-right: 1%;
  font-size: 17px;
  border-radius: 5px;
}
@media screen and (max-width: 850px) {
  .transaction_history_model_div {
    width: 90vw;
  }

  .transaction_history_model_left_div {
    grid-template-columns: 8% 70%;
  }

  .transaction_history_model_info {
    text-align: left;
  }
}

.transaction_history_model_loader_loader {
  /* display: flex; */
  align-self: center;
  align-items: center;
  top: 90%;
  position: absolute;
  justify-content: center;
}

.ifa_transaction_statement_button {
  color: white;
  background-color: #3a86ff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 19px;
  width: 100%;
  height: 100%;
  border: 1px solid #3a86ff;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.withdraw_requests_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.withdraw_requests_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width : 95%;
}

.withdraw_requests_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.withdraw_requests_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.withdraw_requests_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.withdraw_requests_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 13%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.withdraw_requests_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.withdraw_requests_status_text_0 {
  margin: 0;
  padding: 0;
  color: var(--red);
}

.withdraw_requests_status_text_1 {
  margin: 0;
  padding: 0;
  color: var(--green);
}

.withdraw_requests_status_text_2 {
  margin: 0;
  padding: 0;
  color: var(--orange);
}


.withdraw_requests_status_text_5 {
  margin: 0;
  padding: 0;
  color: var(--orange);
}
.withdrawal-details_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    width: 610px; 
    height: 300px;
    padding-bottom: 50px;
    margin-left:2vw;
    margin-right: 2vw;
  }

.withdrawal-details_model_header{
    background-color: #F2F2F2;
    margin-bottom:30px;
    padding:0;
    align-items: left;
    text-align: left;
    border-radius: 4px 4px 0 0;
}

.withdrawal-details_model_header_title{
    padding: 2.5vh;
    margin: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.withdrawal-details_model_body{
    margin-top: 10px;
    margin-bottom:30px;
    align-items: center;
    text-align: center; 
    color:  #2E2E2E;
}

.withdrwal-details-model-body-text{
    font-family: Asap;
    font-size: 20px;
    font-weight: 500;
    line-height: 22.92px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.withdrwal-details-model-body-subtext{
    font-family: Asap;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.75px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.withdrwal-details-model-slider{
    margin-top: 25px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.withdrwal-details-model-line{
    width: 190px;
    height: 0px;
    align-items: center;
    grid-gap: 0px;
    gap: 0px;
    border: 1px solid #BDBDBD
}
.withdrwal-details-model-steps{
    display: flex;
    justify-content: center;
    text-align: center;
    /* gap: 45px ; */
    margin-top: 10px;
}
.withdrwal-details-model-date{
    display: flex;
    font-size: small;

}
.withdrawal-details-sent-time{
    margin-left: 40px;
}
.withdrawal-details-approved-time{
    margin-left: 100px;
    margin-top: 10px;
}
.withdrawal-details-processed-time{
    margin-left: 100px;
}
.circle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    /* margin-left: 120px; */
}
.paymentlinks_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.paymentlinks_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width : 95%;
}

.paymentlinks_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.paymentlinks_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.paymentlinks_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.paymentlinks_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 13%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.paymentlinks_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.paymentlinks_status_text_0 {
  margin: 0;
  padding: 0;
  color: var(--red);
}

.paymentlinks_status_text_1 {
  margin: 0;
  padding: 0;
  color: var(--blue);
}

.paymentlinks_status_text_5 {
  margin: 0;
  padding: 0;
  color: var(--orange);
}

.paymentlinks_status_success {
  margin: 0;
  padding: 0;
  color: var(--green);
}

.resend_button {
  border: none;
  background: "#ebf0fa";
  border-radius: 1px;
  width:40px;
  height:30px;
}

.resend_payment_link_replay_button {
  color: #3a86ff;
}

.client_investment_status_modal_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  width: 610px; 
  height: 300px;
  padding-bottom: 50px;
  margin-left:2vw;
  margin-right: 2vw;
}

.investment_status_modal_header {
  background-color: #f2f2f2;
  margin-bottom: 30px;
  padding: 0;
  align-items: left;
  text-align: left;
  border-radius: 4px 4px 0 0;
}

.investment_status_modal_header_title {
  padding: 2.5vh;
  padding-left: 2.5vw;
  margin: 0;
  color: #343434;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.investment-status-body {
  text-align: center;
  margin-top: 5px;
  font-weight: 500;
  font-size: 18;
}

.investment-amount-body {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 32px;
}

.status-link {
  text-decoration: underline;
  cursor: pointer;
}

.investment-status {
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}

.investment-details-model-slider{
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.investment-details-model-line{
  width: 190px;
  height: 0px;
  align-items: center;
  grid-gap: 0px;
  gap: 0px;
  border: 1px solid #BDBDBD;
}
.walletlinks_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.walletlinks_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width : 95%;
}

.walletlinks_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.walletlinks_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.walletlinks_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.walletlinks_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 13%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.walletlinks_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.walletlinks_status_text_0 {
  margin: 0;
  padding: 0;
  color: var(--red);
}

.walletlinks_status_text_1 {
  margin: 0;
  padding: 0;
  color: var(--green);
}

.walletlinks_status_text_2 {
  margin: 0;
  padding: 0;
  color: var(--orange);
}

.walletlinks_status_text_5 {
  margin: 0;
  padding: 0;
  color: grey;
}
.loader_screen_container{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    margin-top: 10%;
}

.loader_screen_text{
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    width: 450px;
    color: #151515;
    margin-top: 40px;
}

.loader_screen_subtext{
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    width: 450px;
    color: #151515;
    margin-top: 10px;
}
.loader_screen_timeout_container{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    margin-top: 10%;
}

.loader_screen_timeout_text{
    font-weight: bold;
    font-size: 25px;
    margin-top: 50px;
}
.loader_screen_timeout_subtext{
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    width: 450px;
    color: #151515;
    margin-top: 40px;
}
.upload_input_v2_container {
  display: flex;
}

.upload_input_v2_main_container {
  width: 100%;
  font-size: 12px;
  padding: 15px;
  color: #3a86ff;
  border-color: #3a86ff;
  background-color: #ffffff;
  cursor: pointer;
  border-style: solid;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.upload_input_v2_text {
  font-size: 12px;
  color: #3a86ff;
  border-color: #3a86ff;
  margin-left: 5px;
}

.upload_input_v2_info_text_container {
  margin-top: 15px;
  font-size: 50px;
}

.upload_input_v2_title {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

.upload_input_v2_file_container {
  width: 100%;
  border: 1px solid black;
  padding: 10px;
  border-radius: 2px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.upload_input_v2_remove_file {
  padding: 0;
  color: #3a86ff;
  font-size: 13px;
  font-weight: 600;
  font-family: lato;
  cursor: pointer;
}

.upload_input_v2_file_text {
  padding-top: 5px;
  font-size: 13px;
  color: #616060;
}
.download_template{
  font-weight: 400;
  color: #3A86FF;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.input_box_field_container {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
  }

  
.input_box_input_container :disabled {
background-color: rgb(247, 247, 247);
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.input_box_v2_label_container {
    margin-bottom: -6px;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: #272727;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    color: #414141;
  }

  .input_box_v2_invalid_text {
    margin: 0;
    padding: 0;
    font-size: medium;
    color: var(--invalid);
  }

  .input_box_invalid_div {
    margin: 0;
    padding: 0;
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .input_box_field_container {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
  }

.upload_input_v3_main_contianer{
    margin-top:5px;
}

.upload_input_v3_title {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

.upload_input_v3_container {
  display: flex;
}

.upload_input_v3_upload {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: grey;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.upload_input_v3_button {
  margin-top: 1%;
  width: 25%;
  margin-left: 5%;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: #ffffff;
  /* color: #3a86ff; */
  border-style: solid;
  /* border-color:#3a86ff; */
  border-radius: 4px;
  /* border: none; */
}
.upload_input_v3_info_text_container {
    margin-top: 15px;
    font-size: 50px;
  }
.upload_input_v3_file_container {
  border: 1px solid black;
  padding: 10px;
  border-radius: 2px;
  margin-top: 10px;
  display: flex;
  text-align: right;
  justify-content: space-between;
}

.upload_input_v3_main_container {
  width: 100%;
  font-size: 12px;
  padding: 15px;
  color: #3a86ff;
  border-color: #3a86ff;
  background-color: #ffffff;
  cursor: pointer;
  border-style: solid;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.upload_input_v3_file_text {
  padding-top: 5px;
  font-size: 13px;
  color: #616060;
}

.upload_input_v3_remove_file {
  padding: 0;
  color: #3a86ff;
  font-size: 13px;
  font-weight: 600;
  font-family: lato;
  cursor: pointer;
}

.upload_input_uploaded {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.upload_input_upload {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: grey;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.upload_input_button {
  margin-top: 1%;
  width: 25%;
  margin-left: 5%;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: #ffffff;
  /* color: #3a86ff; */
  border-style: solid;
  /* border-color:#3a86ff; */
  border-radius: 4px;
  /* border: none; */
}

.upload_input_info_text_container {
  margin-top: 15px;
  font-size: 50px;
}

.client_otpenter_model_v2_header {
    background-color: #f2f2f2;
    width: 94%;
    padding-top: 15px;
    text-align: left;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    margin-left: 0px;
    padding-bottom: 15px;
    border-radius: 5px
}

.client_otpenter_model_v2_header_title {
  color: #343434;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.client_otpenter_model_v2_body {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client_otpenter_model_v2_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  outline: none;
}

.client_otpenter_model_v2_title_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.client_otpenter_model_v2_title {
  margin: 0;
  padding: 0;
  color: #000;
  text-align: center;
  font-family: "Asap";
  /* letter-spacing: 5px; */
  font-size: 16px;
  word-spacing: 2px;
  width: 520px;
  font-style: normal;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 22px;
}

.client_otpenter_model_v2_input {
  width: 75%;
  padding: 10px;
  margin-top: 20px;
  font-size: large;
  border-radius: 3px;
  border: 1px solid rgb(73, 73, 73);
}

.client_otpenter_model_v2_button {
    padding: 13px;
    padding-left: 20px;
    padding-right: 20px;
    width: 130px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
}

.client_otpenter_model_v2_button:hover {
  background-color: var(--blue-hover);
}

.add_client_pan_not_verified_container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    border: 1px solid rgb(219, 219, 219);
    align-items: center;
    width: 65%;
    padding-top: 90px;
    height: 300px;
  }
  
  .add_client_pan_not_verified_text{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    margin-top: 20px;
  }
  
  .add_client_pan_not_verified_support_text{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 0;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    margin: 0;
  }

  .add_client_pan_not_verified_support_email{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 10px;
    padding: 0;
    color: #064586;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
    text-decoration: none;
  }

  .add_client_pan_not_verified_support{
    margin-top: 25px;
  }
.consent_request_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    padding-bottom: 50px;
    width: 40%
  }

  .consent_request_model_header_title{
    padding: 2.5vh;
    margin: 0;
    color: #343434;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 20px;
  }
  .consent_request_model_header{
    background-color: #F2F2F2;
    padding:0;
    align-items: left;
    text-align: left;
  }
  .consent_request_model_body{
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    line-height: 24.36px;
    padding: 30px 50px 10px 50px;
  }
  .consent_request_model_list{
    font-family: "Inter";
    margin-top: 10px;
  }
  .consent_request_model_button {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 250px;
    height: 45px;
    margin-top: 40px;
    background-color: var(--blue);
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
.pool_list_div_main_container {
    display: flex;
    flex-direction: column;
  }
  
  .pool_list_main_div {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
  }
  
  .poollist_clientname_conatiner {
    display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
  }
  
  .poollist_header_button_text_title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
    color: #525252;
  }
  
  .poollist_header_button_text_subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
    color: var(--blue);
  }
  
  .pool_pagination_div_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    /* padding-bottom: 60px; */
  }
  
  .pagination_div_container {
    display: flex;
    align-self: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    width: 80%;
    height: 80px;
  }
  
  .previous_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: #3a86ff;
  }
  
  .next_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: #3a86ff;
    margin-left: 30px;
  }
  
  .pool_list_toggle_container {
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    border-bottom: 1px solid #D4D4D4;
  }
  .pool_list_toggle_element {
    font-size: 20px;
    color: #757575;
    margin-right: 7%;
    cursor: pointer;
  }
  .pool_list_toggle_element_active {
    font-size: 20px;
    font-weight: 600;
    color: #3a86ff;
    margin-right: 7%;
    cursor: pointer;
    border-bottom: 3px solid #3a86ff;
    padding-bottom: 12px;
  }
  .pool_list_sub_heading{
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 40px;
    font-weight: 400;
    color: #757575;
    width: 100%;
  }
  .pool_list_sub_heading_content{
    margin-left:0px;
    padding-left:0px;
    font-size: 16px;
  }
  
.state_dropdown_main_contianer {
  margin-top: 5px;
}

.state-dropdown-container {
  display: flex;
  border: 1px solid #494949;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 5px;
}

.state-dropdown-container-disabled {
  display: flex;
  border: 1px solid #494949;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 5px;
  background: #eeeeee;
}

.state_drop_down_label {
  margin-bottom: -6px;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #272727;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  color: #414141;
}

.state_dropdown_input_container {
  font-size: 14px;
  border: 1px solid #eeeeee;
  color: #605e5e;
  border-radius: 3px;
}

.state_dropdown_input_field {
  background: #eeeeee;
  padding: 5px;
  font-size: 14px;
  border-radius: 0px 4px 4px 0px;
  width: 100%;
  border: 1px solid #494949;
  color: #605e5e;
  border: none;
  outline: none;
}

.state_dropdown_input_field:disabled {
  background: #eeeeee;
  font-size: 16px;
  border-radius: 0px 4px 4px 0px;
  width: 100%;
  border: 1px solid #494949;
  color: #0a0a0a;
  border: none;
  margin-left: -8px;
  outline: none;
}

.state_dropdown_input_container:disabled {
  background-color: #ededed;
  color: #1b1b1b;
}

.state-dropdown-list {
  border: 1px solid #eeeeee;
  border-top: none;
}

.dropdown-select {
  display: flex;
  border: 1px solid #494949;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 5px;
}
.dropdown-select-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-top: 3px;
  color: #656363;
}

.ifa_switch_plan_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ifa_switch_plan_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 95%;
}

.ifa_switch_plan_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ifa_switch_plan_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.ifa_switch_plan_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.ifa_switch_plan_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 13%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.ifa_switch_plan_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.ifa_switch_plan_status_text {
  margin: 0;
  padding: 0;
}

.ifa_switch_plan_resend_button_div {
  border: none;
  background: "#ebf0fa";
  border-radius: 1px;
  width: 40px;
  height: 30px;
}

.ifa_switch_plan_resend_button {
  color: #3a86ff;
}

.ifa_switch_plan_resend_button_disable {
  color: #a8a8a8;
}

.auth_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 70px;
}

.auth_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
  
}

.auth_header_text {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: black;
  margin-right: 2%;
}

.auth_logo_img {
  height: auto;
  width: 140px;
  cursor: pointer;
  margin-left: 2%;
}

.auth_main_logo_image {
  width: 13%;
  cursor: pointer;
}

.auth_rest_container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  margin-top: 100px;
}

.auth_image_container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: 50%;
  height: 100%;
  background-image: url("https://arthmate-p2p-data.s3.ap-south-1.amazonaws.com/dev/public/12345.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(120%);
}

.auth_main_title {
  color: #414141;
  font-weight: bolder;
  font-size: 40px;
  margin-top: 100px;
  margin-bottom: 60px;
  text-align: center;
}

/* .auth_image {
  width: 100%;
  overflow: hidden;
  /* margin-right: 100px; */
/* } */

.auth_form_container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  align-items: center;
  width: 50%;
  padding-bottom: 20px;
  /* text-align: center; */
}

.thank_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.thank_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 100px;
  padding-top: 35px;
}

.thank_main_title h3 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height */
  color: #2f2f2f;
}

@media screen and (max-width: 990px) {
  .auth_header_text {
    font-size: 18px;
  }

  .auth_image_container {
    width: 50%;
  }
  .auth_form_container {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {

  .auth_header {
    flex-direction: column;
  }

  .auth_logo_img {
    width: 180px;
    height: auto;
  }

  .auth_header_text {
    font-size: 20px;
  }

  .auth_main_logo_image {
    width: 30%;
  }

  .auth_rest_container {
    flex-direction: column;
    margin-top: 100px;
  }

  .auth_image_container {
    position: absolute;
    height: 50%;
    width: 100%;
    flex: 1 1;
    background-image: url("https://arthmate-p2p-data.s3.ap-south-1.amazonaws.com/dev/public/12345.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .auth_main_title {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  /* .auth_image {
    width: 60%;
    margin-bottom: 40px;
    margin-right: 70px;
  } */

  .auth_form_container {
    left: 0%;
    position: absolute;
    top: 60%;
    width: 100%;
  }
}

.main_login {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.login_container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 40px;
  padding-right: 40px;
  width: 70%;
  /* border: 1px solid #c3c3c3; */
  border-radius: 3px;
}

.login_container #welcome_title {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #1c1c1c;
}

.login_header {
  display: flex;
  width: 100%;
  align-items: center;
}

.login_header_title {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #1c1c1c;
}

.login_form_container {
  width: 100%;
  margin-top: 20px;
}

.login_field_container {
  display: flex;
  flex-direction: column;
}

.login_label_container {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #272727;
}

.login_input_container {
  margin-top: 10px;
  padding: 12px;
  font-size: large;
  border: 1px solid #494949;
  border-radius: 3px;
}

.login_input_container:disabled {
  background-color: rgb(247, 247, 247);
}

.login_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.login_footer {
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.login_footer_text {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #6b6b6b;
  font-weight: 500;

  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  color: #414141;
}

.login_footer_text_link {
  margin: 0;
  padding: 0;
  margin-left:5px;
  font-size: 18px;
  color: var(--blue);
  font-weight: 500;
  cursor: pointer;
}

.login_notice_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  width: 60%;
  border-radius: 3px;
}

.login_notice_text {
  font-size: 16px;
}

.login_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

@media screen and (max-width: 700px) {
 .login_container{
  margin: auto;
 }
}

.main_signup {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.signup_container {
  display: flex;
  flex-direction: column;
  /* margin-top: 50px; */
  padding-top: 25px;
  padding-bottom: 200px;
  padding-left: 40px;
  padding-right: 40px;
  width: 75%;
  /* border: 1px solid #c3c3c3; */
  border-radius: 3px;
}

.thank_page_container {
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 728px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #50cd89;
  border-radius: 25px;
}

.kyc_main_image_div {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  border: none;
}

.kyc_main_image_div_container {
  display: flex;
  height: 45px;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.kyc_main_image_text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.signup_header {
  display: flex;
  width: 100%;
  align-items: center;
}

.signup_header_title {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.signup_form_container {
  width: 100%;
  margin-top: 20px;
}

.signup_field_container {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
}

.signup_label_container {
  margin-bottom: -6px;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #272727;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  color: #414141;
}

.pan_conatainer_main {
  margin-top: 15px;
}

.upload_pan_title {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}
.pan_upload {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: grey;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}
.pan_upload_container {
  display: flex;
}

.pan_uploaded {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.pan_upload_button {
  margin-top: 1%;
  width: 25%;
  margin-left: 5%;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: #ffffff;
  color: #3a86ff;
  border-style: solid;
  border-color: #3a86ff;
  border-radius: 4px;
  /* border: none; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.signup_input_container {
  margin-top: 12px;
  padding: 12px;
  font-size: large;
  border: 1px solid #494949;
  border-radius: 3px;
}

.signup_input_container:disabled {
  background-color: rgb(247, 247, 247);
}

.signup_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.signup_footer {
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.signup_footer_text {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #6b6b6b;
  font-weight: 500;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

.signup_footer_text_link {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: var(--blue);
  font-weight: 500;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  margin-left: 5px;
}

.signup_notice_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  width: 60%;
  border-radius: 3px;
}

.signup_notice_text {
  font-size: 16px;
}

.signup_invalid_div {
  margin: 0;
  padding: 0;
  /* margin-top: 3px; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signup_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

/*Drop down div */
.org_check {
  display: flex;
  align-items: center;
}

.selectDropDown {
  width: 100%;
  margin-top: -8px;
  margin-left: 12px;
}

.selectFirmText {
  margin: 0;
  padding-bottom: 6px;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

#upload_doc_firm {
  margin-top: 8px;
}

.dropDownItem {
  border-bottom: 0.5px solid #aeaeae;
  width: auto;
  padding: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Asap";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  transform: translate3d(0, 0, 0) !important;
  color: #000000;
}

.dropdownContent {
  background: #ffffff;
  border: 1px solid grey;
  width: auto;
  overflow-y: scroll;
  /* -ms-overflow-style: none;  IE and Edge */
  /* scrollbar-width: none;   Firefox */
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid grey;
  width: auto;
  margin-bottom: -147px;
  z-index: 4;
  overflow-y: scroll;
}

.dropdownContent::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
}

.gstToggleBox {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.gstToggle {
  margin-top: 7px;
  margin-left: 15px;
}

.kyc-loader{
  display: grid;
  grid-template-columns: 10% 5%;
  margin-top: 100px;
  text-align: center;
  justify-content: center;
}

@media only screen and (max-width: 1190px) {
  #mobileNumber {
    width: 80%;
  }
}
.step_navigator {
  width: 70%;
  z-index: -1;
}
.end_navigator{
  width: 70%;
  z-index: -1;
}
@media only screen and (min-width: 6001px) and (max-width: 8000px) {
  .step_navigator {
   width: 67%
  }
}
@media only screen and (min-width: 5001px) and (max-width: 6000px){
  .step_navigator {
    width: 67%
   }
}
@media only screen and (min-width: 3500px) and (max-width: 5000px){
  .step_navigator {
    width: 68%
   }
}
@media only screen and (min-width: 2645px) and (max-width: 3000px){
  .step_navigator {
    width: 66%
   }
}
@media only screen and (min-width: 2250px) and (max-width: 2644px){
  .step_navigator {
    width: 66%
   }
}

@media only screen and (min-width: 6001px) and (max-width: 8000px) {
  .end_navigator {
   width: 75%
  }
}
@media only screen and (min-width: 5001px) and (max-width: 6000px){
  .end_navigator {
    width: 76%
   }
}
@media only screen and (min-width: 3500px) and (max-width: 5000px){
  .end_navigator {
    width: 76%
   }
}
@media only screen and (min-width: 2645px) and (max-width: 3000px){
  .end_navigator {
    width: 77%
   }
}
@media only screen and (min-width: 2250px) and (max-width: 2644px){
  .end_navigator {
    width: 77%
   }
}
@media only screen and (min-width: 2000px) and (max-width: 2250px){
  .end_navigator {
    width: 78%
   }
}
@media only screen and (min-width: 1500px) and (max-width: 2000px){
  .end_navigator {
    width: 78%
   }
}
@media only screen and (min-width: 1000px) and (max-width: 1500px){
  .end_navigator {
    width: 80%
   }
}



.step_circle {
  width: 26px;
  height: 27px;
  line-height: 25px;
  background-color: #D9D9D9;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
  margin: auto;
  /* margin-bottom: 16%; */
}

.stepBlock img{
  width: 27px;
  height: 27px;
  line-height: 25px;
  background-color: #D9D9D9;
  color: white;
  border-radius: 50%;
}
.stepBlock {
  /* padding: 0px 50px; */
  text-align: center;
}

.circleWrapper {
  margin-bottom: 25px;
  text-align: center;
}


.stepper{
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 30px;
}

.selected .step_circle {
    color: black;
    width: 12px;
    height: 13px;
    border-width: 9px;
    background-color: white;
    border-color: #3A86FF;
}

.disableText{
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  color: #ADADAD
}
  

span {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  color: #151515;
}


  .stepWrapper {
    display: flex;
    margin-bottom: 20px;
    margin-top: -25px;
    justify-content: space-around;
    z-index: -1;
  }
  
  .stepBlock .circleWrapper {
    padding: 0px 20px;
    position: relative;
    width: 80px;
  }

/* 
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 26%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 135%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 26%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}

@media only screen and (min-width: 1500px) and (max-width: 2000px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 170%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 26%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}
@media only screen and (min-width: 6000px) and (max-width: 8000px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 755%;
    height: 2px;
    position: absolute;
    top: 10px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 0%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}
@media only screen and (min-width: 5001px) and (max-width: 6000px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 550%;
    height: 2px;
    position: absolute;
    top: 10px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 20%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}
@media only screen and (min-width: 3500px) and (max-width: 5000px) {
  .stepBlock:not(:last-child) > .circleWrapper::before {
    content: '';
    width: 760%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }
  .stepBlock:not(:first-child) > .circleWrapper::before {
    content: '';
    width: 0%;
    height: 2px;
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    margin-right: 100px;
    z-index: -1;
  }
}
@media only screen and (min-width: 3000px) and (max-width: 3500px) {
  .stepBlock:not(:last-child) > .circleWrapper::before {
    content: '';
    width: 640%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }
  .stepBlock:not(:first-child) > .circleWrapper::before {
    content: '';
    width: 0%;
    height: 2px;
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    margin-right: 100px;
    z-index: -1;
  }
}

@media only screen and (min-width: 2645px) and (max-width: 3000px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 280%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 0%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}
@media only screen and (min-width: 2250px) and (max-width: 2644px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 250%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 0%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}

@media only screen and (min-width: 2000px) and (max-width: 2250px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 200%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 26%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}

@media only screen and (min-width: 6001px) and (max-width: 8000px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 570%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}
@media only screen and (min-width: 5001px) and (max-width: 6000px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 410%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}
@media only screen and (min-width: 3500px) and (max-width: 5000px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 300%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}
@media only screen and (min-width: 2645px) and (max-width: 3000px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 220%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }
}
@media only screen and (min-width: 2250px) and (max-width: 2644px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 185%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}
@media only screen and (min-width: 2000px) and (max-width: 2250px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 150%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}

@media only screen and (min-width: 1500px) and (max-width: 2000px){
.stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
    content: '';
    width: 120%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
}}


@media only screen and (min-width: 1190px) and (max-width: 1500px){
.stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
}
} */
.banner_container {
  position: absolute;
  /* inset: 0; */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_header {
  display: flex;
  z-index: 2;
  top:0;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.banner_logo_img {
  height: auto;
  width: 180px;
  cursor: pointer;
  margin-left: 20px; 
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #2c2e2b0d;
  padding: 6%;
  margin-top: 150px;
  margin-bottom: 50px;
  margin-left: 15%;
  margin-right: 15%;
  border-radius: 25px;
}

.banner_image {
  width: 72%;
}

.banner_text {
  text-align: center;
  font-size: 18px;
}

@media screen and (max-width: 900px) {
  .banner_logo_img {
    width: 150px;
  }
}

@media screen and (max-width: 600px) {
  .banner_header {
    flex-direction: column;
    justify-content: center;
  }
  .banner_logo_img {
    width: 120px;
    margin-left: 0px;
  }
}
.overview_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.overview_rest_div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.overview_rest_div_heading {
  margin: 0;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.overview_border {
  background: linear-gradient(to bottom, #f6f6f6 80px, white 0%);
  box-sizing: border-box;
  border: 1px solid #b0b5b1;
  border-radius: 10px;
  padding: 30px 20px;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.overview_client_heading {
  margin: 2%;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.overview_column_container {
  margin: 2%;
  width: 92%;
  align-self: center;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin-bottom: 6%;
}

.overview_row_container {
  display: flex;
  height: 100px;
  margin-top: 20px;
}

.overview_row_container_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
  padding-left: 15px;
  border-radius: 3px;
  border: 1px #d0d0d0;
}

.overview_row_container_title {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: #262626;
  font-weight: 500;
  font-size: 14px;
}

.overview_tooltip_grid {
  display: grid;
  grid-template-columns: 90% 5%;
}

.overview_row_container_subtitle {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: #262626;
  font-weight: bolder;
  font-size: 28px;
}

.overview_row_container_highlight {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: var(--blue);
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.overview-model-div {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 12.5%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.overview-model-header-div {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 29px;
  padding-right: 29px;
}

.overview-model-header-text {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
}

.overview-model-footer-div {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-model-footer-button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 200px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
}

.overview-model-footer-button:hover {
  background-color: var(--green);
  color: #fff;
}

.overview_logout_icon {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  padding-right: 30px;
}

.overview_pagination_div {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  width: 95%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
  background-color: #ececec;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.client_notify {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.client_notify:hover {
  background-color: var(--green);
  color: #fff;
  cursor: pointer;
}

.overview-model-div-aum {
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: auto;
  margin-top: 15%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.overview-model-header-div-aum {
  padding-top: 22px;
  display: flex;
  justify-content: space-between;
  padding-left: 29px;
  border-radius: 4px 4px 0px 0px;
  background: #f2f2f2;
  padding-right: 29px;
  padding-bottom: 22px;
}
.overview-model-header-text-aum {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #343434;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.overview-tab-container {
  padding: 10px;
  margin-left: 18px;
  margin-top: 15px;
  padding-right: 27px;
}
.overview-tab-navbar {
  display: flex;
}

.overview_tab {
  background: #f3f3f3;
  color: #686868;
  font-size: 12px;
  font-style: normal;
  padding: 9px;
  text-align: center;
  font-weight: 400;
  width: 137px;
  cursor: pointer;
  line-height: normal;
}

.overview_tab_selected {
  background: #3a86ff;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  padding: 9px;
  font-weight: 400;
  text-align: center;
  width: 137px;
  cursor: pointer;
  line-height: normal;
}
.overview_tab_panel_container {
  border-radius: 4px 4px 0px 0px;
  background: #fbfbfb;
  margin-top: 15px;
}

.overview_total_invested_amount {
  padding: 10px 0px 5px 15px;
  display: flex;
}

.overview_total_invested_amount_text {
    color: #3c3c3c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 3px;
    margin-right: 5px;
}

.overview_total_invested_amount_amount {
  color: #424242;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.overview_aum_split_container {
  display: flex;
  padding: 10px;
}

.overview_aum_invested_plan {
  padding: 10px 10px 10px 5px;
  display: grid;
  grid-gap: 5px;
}

.overview_aum_invested_plan_2 {
  padding: 10px 10px 10px 5px;
  display: grid;
  grid-gap: 5px;
  margin-left: 15%
}

.overview_aum_invested_plan_text {
  color: #3c3c3c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.overview_aum_invested_plan_amount {
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.overview-model-footer-button-aum {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 5px;
  width: 90px;
  border: 1.5px solid #3a86ff;
  background-color: #fff;
  color: #3a86ff;
  font-size: 12px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
}

.overview-model-footer-div-aum {
  margin-bottom: 20px;
  display: flex;
  justify-content: right;
  padding-right: 25px;
  padding-top: 10px;
  
}
/* 
@media only screen and (max-width: 1000px) {
  .overview_rest_div_heading {
    font-size: 30px;
  }
  .overview_column_container {
    grid-template-columns: 50% 50%;
  }
  .overview_row_container_title {
    font-size: 13px;
  }
  .overview_row_container_subtitle {
    font-size: 36px;
  }
  .overview_row_container_highlight {
    font-size: 13px;
  }
}

@media only screen and (max-width: 700px) {
  .overview_rest_div_heading {
    font-size: 26px;
  }
  .overview_column_container {
    grid-template-columns: 100%;
  }
  .overview_row_container_title {
    font-size: 12px;
  }
  .overview_row_container_subtitle {
    font-size: 34px;
  }
  .overview_row_container_highlight {
    font-size: 12px;
  }
} */

.clientkyc_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientkyc_main_div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-left: 220px;
  padding-bottom: 20px;
  overflow: hidden;
}

.clientkyc_top_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.clientkyc_top_row_container {
  width: 56%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientkyc_top_row_back {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
}

.clientkyc_main_container_main_title {
  flex: 2.3 1;
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 30px;
}

.clientkyc_main_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.clientkyc_main_container_div1 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_div2 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_title_net_worth {
  margin: 0;
  padding: 0;
  color: var(--blue);
  font-weight: 600;
  font-size: 22px;
}

.clientkyc_main_input_form {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.clientkyc_main_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 12px;
}

.clientkyc_main_input_field {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.clientkyc_main_input_field:disabled {
  background-color: #ececec;
  color: rgb(119, 119, 119);
}

.clientkyc_main_image_div_container {
  display: flex;
  height: 45px;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.clientkyc_main_image_div {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  border: none;
}

.clientkyc_main_image_text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.clientkyc_main_image_button {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  background-color: var(--blue);
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
}

.clientkyc_main_image_button:hover {
  background-color: var(--blue-hover);
}

.kyc_button_container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 70%;
}

.kyc_submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 50%;
  background-color: var(--green);
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_submit_button:hover {
  background-color: var(--green-hover);
}

@media only screen and (max-width: 550px) {
  .clientkyc_main_image_button {
    width: 28%;
  }
}

.clientkyc_ckyc_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_user_exist_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clientkyc_user_exist_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #414141;
}

.clientkyc_user_exist_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_user_exist_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_enable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button_pan_enable:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_disable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: #b9b9b9;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: not-allowed;
  border-radius: 3px;
}

.clientkyc_gender_input {
  padding-left: 15px;
}

.clientkyc_gender_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 3px;
}

/* Page not Found */
.clientpagenotfound_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientpagenotfound_rest_container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.clientpagenotfound_container_title {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 100px;
  letter-spacing: 10px;
  color: var(--blue);
  text-align: center;
}

.clientpagenotfound_container_subtitle {
  margin-top: 10px;
  font-size: 25px;
  color: var(--gray);
  text-align: center;
}

.client_tc_header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.client_tc_logo_image {
  width: 180px;
  cursor: pointer;
  margin-left: 2%;
}

.client_thank_page_container {
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 728px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #50cd89;
  border-radius: 25px;
}

.client_thank_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.client_thank_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 90px;
  width: 80%;
  padding-top: 35px;
}

.client_thank_main_title h3 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32.09px;
  /* identical to box height */
  color: #2f2f2f;
}
.client_thank_main_title p {
  font-family: "Asap";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25.63px;
  color: #000000;
}
.clientkyc_net_worth_header_container {
  display: grid;
  grid-template-columns: 70% 30%;
}

.kyc_download_net_worth_button {
  background-color: #3a86ff;
  width: 100px;
  height: 38px;
  border: 1px solid #3a86ff;
  border-radius: 2px;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.kyc_download_net_worth_button_text {
  color: #ffffff;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  align-self: center;
}

.tc_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  border: 1.5px solid #6c6c6c;
  border-radius: 25px;
  margin: auto;
  margin-top: 50px;
  padding: 40px;
  margin-bottom: 50px;
}

.tc_main_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.tc_title {
  color: #2f2f2f;
  font-size: 24px;
  font-weight: 600;
  line-height: 32.09px;
  text-align: center;
}
.tc_nominee_details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}
.tc_nominee_header {
  align-self: center;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}
.tc_nominee_content {
  margin-top: 20px;
  align-self: flex-start;
  width: 100%;
}
.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin-right: 2%;
}
.tc_nominee_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
}
.nominee_key {
  color: #686868;
  font-size: 16px;
  font-weight: 400;
  margin-right: 2%;
}
.nominee_value {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.checkbox_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}
.consent_checked {
  height: 16px;
  width: 16px;
  background-color: #064586;
  border-radius: 1px;
  margin-right: 2%;
  cursor: pointer;
}
.consent_unchecked {
  height: 12px;
  width: 12px;
  border: 2px solid #686868;
  cursor: pointer;
  border-radius: 1px;
  margin-right: 2%;
}
.consent_text {
  font-family: "poppins";
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  width: 100%;
}
.tc_agree_cta {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 40%;
  border-radius: 3px;
  background-color: #3a86ff;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}
.tc_cta_text {
  font-family: "Lato";
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}
.tc_loader {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .clientkyc_main_container_title_net_worth {
    font-size: 16px;
  }

  .kyc_download_net_worth_button {
    width: 80px;
    height: 30px;
    display: flex;
  }

  .kyc_download_net_worth_button_text {
    font-size: 10px;
    text-align: center;
  }
}

@media screen and (max-width: 900px) {
  .kyc_download_net_worth_button {
    width: 60px;
    height: 30px;
    display: flex;
  }

  .kyc_download_net_worth_button_text {
    font-size: 7px;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  .clientkyc_net_worth_header_container {
    display: initial;
    grid-template-columns: none;
  }
}

.investortype_main_container {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.investortype_main_header {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #414141;
}

.investortype_container {
  margin-top: 10px;
  padding: 30px;
  /* width: 85%; */
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.investortype_input_conatiner {
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
}
.radioClass {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.investortype_label {
  display: flex;
  align-items: center;
  align-content: center;
  color: #141519;
  letter-spacing: -0.25px;
  padding: 8px;
}

.investor_entity_container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.investor_entity_label_conatiner {
  margin-top: 15px;
}

.investor_type_proceed_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5%;
  cursor: pointer;
}

.investor_type_proceed_button_text {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.upload_input_container {
  display: flex;
}
 .entity_type_container{
  margin-top: 10px;
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}


.entity_kyc_main_input_form {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}


.entity_clientkyc_main_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 12px;
}

.entity_clientkyc_main_input_field {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.board_resolution_template{
  color: var(--blue);
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: none;
}

.entity_details{
  padding-bottom: 25px;
}



.auth_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.auth_logo_image {
  width: 180px;
  cursor: pointer;
  margin-left: 2%;
}

.client_kyc_link_main_container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.client_kyc_link_container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.client_kyc_link_body_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.clientkyclink_main_container_main_title {
  flex: 2.3 1;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 30px;
}

.clientkyclink_main_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-left: 15vw;
}

.clientkyclink_main_container_div1 {
  margin-top: 10vh;
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.kyc_link_button_container {
  margin-left: 15vw;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 70%;
}

.kyc_link_checkbox_container {
  margin-left: 15vw;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 70%;
}

.kyc_link_submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 41%;
  background-color: var(--green);
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_link_submit_button:hover {
  background-color: var(--green-hover);
}

.clientkyc_user_exist_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clientkyc_user_exist_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #414141;
}

.clientkyc_user_exist_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_user_exist_button:hover {
  background-color: var(--blue-hover);
}

@media only screen and (max-width: 550px) {
  .clientkyclink_main_container_div1 {
    width: 75%;
  }
  .client_kyc_link_container {
    width: 100%;
  }
  .clientkyclink_main_container {
    display: flex;
    width: 98%;
    margin-left: 1vw;
  }

  .auth_logo_image {
    width: 200px;
    height: auto;
    cursor: pointer;
  }

  .clientkyclink_main_container_main_title {
    margin-left: 0vw;
  }
}

.client_tc_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.thank_you_main_container {
  width: 450px;
  border-radius: 5px;
  border: 1px solid #50cd89;
  border-radius: 15px;
  margin-left: 500px;
  margin-top: 150px;
  position: fixed;
  padding: 40px;
}
.thank_you_check_icon {
  margin-left: 150px;
  margin-top: 10px;
}

.thank_you_text {
  margin-left: 90px;
}
.thank_you_subtext {
  margin-left: 10px;
}

.clientkyc_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientkyc_main_div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-left: 220px;
  padding-bottom: 20px;
  overflow: hidden;
}

.clientkyc_top_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.clientkyc_top_row_container {
  width: 56%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientkyc_top_row_back {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
}

.clientkyc_main_container_main_title {
  flex: 2.3 1;
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 30px;
}

.clientkyc_main_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
}

.clientkyc_main_container_div1 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_div2 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_title {
  margin: 0;
  padding: 0;
  color: var(--blue);
  font-weight: 600;
  font-size: 22px;
}

.clientkyc_main_input_form {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.clientkyc_main_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 12px;
}

.clientkyc_main_input_field {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.clientkyc_main_input_field:disabled {
  background-color: #ececec;
  color: rgb(119, 119, 119);
}

.clientkyc_main_image_div_container {
  display: flex;
  height: 45px;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.clientkyc_main_image_div {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  border: none;
}

.clientkyc_main_image_text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.clientkyc_main_image_button {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  background-color: var(--blue);
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
}

.clientkyc_main_image_button:hover {
  background-color: var(--blue-hover);
}

.kyc_button_container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 70%;
}

.kyc_submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 50%;
  background-color: var(--green);
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_submit_button:hover {
  background-color: var(--green-hover);
}

@media only screen and (max-width: 550px) {
  .clientkyc_main_image_button {
    width: 28%;
  }
}

.clientkyc_ckyc_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_user_exist_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clientkyc_user_exist_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #414141;
}

.clientkyc_user_exist_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_user_exist_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_enable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button_pan_enable:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_disable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: #b9b9b9;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: not-allowed;
  border-radius: 3px;
}

.clientkyc_gender_input {
  padding-left: 15px;
}

.clientkyc_gender_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 3px;
}

/* Page not Found */
.clientpagenotfound_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientpagenotfound_rest_container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.clientpagenotfound_container_title {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 100px;
  letter-spacing: 10px;
  color: var(--blue);
  text-align: center;
}

.clientpagenotfound_container_subtitle {
  margin-top: 10px;
  font-size: 25px;
  color: var(--gray);
  text-align: center;
}

.client_tc_header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.client_tc_logo_image {
  width: 180px;
  cursor: pointer;
  margin-left: 2%;
}

.client_thank_page_container {
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 728px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #50cd89;
  border-radius: 25px;
}

.client_thank_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.client_thank_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 100px;
  padding-top: 35px;
}

.client_thank_main_title h3 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height */
  color: #2f2f2f;
}

.investortype_main_container {
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.investortype_main_header {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #414141;
}

.investortype_container {
  margin-top: 10px;
  padding: 30px;
  /* width: 85%; */
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.investortype_input_conatiner {
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
}
.radioClass {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.investortype_label {
  display: flex;
  align-items: center;
  align-content: center;
  color: #141519;
  letter-spacing: -0.25px;
  padding: 8px;
}

.investor_entity_container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.investor_entity_label_conatiner {
  margin-top: 15px;
}

.investor_type_proceed_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5%;
  cursor: pointer;
}

.investor_type_proceed_button_text {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.upload_input_container {
  display: flex;
}

.entity_type_container {
  margin-top: 10px;
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.entity_kyc_main_input_form {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.entity_clientkyc_main_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 12px;
}

.entity_clientkyc_main_input_field {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.board_resolution_template {
  color: var(--blue);
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: none;
}

.clientinvestment_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: x-scroll;
  margin-bottom: 5vh;
}

.clientinvestment_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.clientinvestment_header_div {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}
.clientinvestment_header_subject_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clientinvestment_header_div_title_container {
  display: flex;
  flex-direction: row;
}
.clientinvestment_header_div_buttons_container {
  display: flex;
  flex-direction: row;
}

.clientinvestment_header_div_subtitle {
  margin: 0;
  padding: 0;
  color: #969494;
  font-size: 14px;
}

.clientinvestment_header_div_title {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 32px;
}

.clientinvestment_header_button_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientinvestment_header_button_div_kyc {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid var(--green);
  color: var(--green);
  border-radius: 3px;
  cursor: pointer;
}

.clientinvestment_header_button_text_kyc {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  color: var(--green);
}

.clientinvestment_header_button_div_kyc:hover {
  background-color: var(--green);
}

.clientinvestment_header_button_div_kyc:hover
  .clientinvestment_header_button_text_kyc {
  color: #fff;
}

.clientinvestment_header_button_div_net {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 3px;
}

.clientinvestment_header_button_text_net {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
}

.clientinvestment_searchbar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.clientinvestment_searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: 12%;
  height: 25px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.clientinvestment_searchbar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.clientinvestment_filtercontainer {
  width: 92%;
  align-self: center;
  align-items: flex-start;
  margin-top: 20px;
}

.clientinvestment_tab_container {
  width: 96%;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 10px;
  grid-column-gap: 30px;
  column-gap: 30px;
  padding: 0px 10px 0px 10px;
}
.date_selection_column_div {
  height: 30px;
  width: 30%;
}
.Income_Statement_for_earlier_FYs{
  width: 60%;
  height: 150%;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid black;
  text-align: center;
  align-items: center;
  font-family: Asap;  
}
.provisional_income_statement_button{
  color: white;
  background-color: #3A86FF;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-top: 5px;
  width: 148px;
  height: 48px;
  border: 1px solid #A6A6A6;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.annual_income_statement_button {
  color: white;
  background-color: #A6A6A6;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 19px;
  width: 90%;
  height: 150%;
  border: 1px solid #A6A6A6;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.active_ais_button {
  background-color: #3A86FF !important;
}
.clientinvestment_tab_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 45px;
  margin-bottom: 10px;
  width: 100%;
}

.clientinvestment_tab_text {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #4b4b4b;
}

.clientinvestment_tab_underline {
  height: 3px;
  border-radius: 5px;
  margin-top: 5px;
  overflow: hidden;
}

.virtual-model-div {
  display: flex;
  flex-direction: column;
  height: 26%;
  width: 38%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
}
.virtual-model-div-2 {
  display: flex;
  flex-direction: column;
  height: 17%;
  width: 38%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
}

.virtual-model-div-3 {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px; ;
  /* height: 37%; */
  width: 37%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
  margin-top: 20%;
}

.virtual-model-div-8 {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px; ;
  /* height: 37%; */
  width: 75%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
  margin-top: 20%;
}

.cellBox-parent-div-2 {
  width: 40%;
  margin-left: 28px;
}

.virtual-model-body-div-margin2{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 30px;
  grid-row-gap: 24%;
  row-gap: 24%;
  height: 50%;
  justify-content: center;
  margin-left:28px;
  margin-right: 28px;
}

.annual-model-header-text{
  margin-top: 20px;
  margin-left: 30px;
  font-size: 18px;
  font-weight: 500;
}
.provisional-model-header-text{
    margin-top: 5px;
    color: #3A86FF;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;}

.annual-model-body-div-margin2{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 30px;
  grid-row-gap: 24%;
  row-gap: 24%;
  justify-content: center;
  margin-left:38px;
  margin-right: 38px;
  border: 1px solid grey;
  border-radius: 4px;
 /* Height:40% */
}

.provisional-model-body-div-margin{
  display: flex;
  margin-top: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #F2F2F2;
  justify-content: center;
  grid-gap: 95px;
  gap: 95px;
  margin-left:38px;
  margin-right: 38px;
  
  border-radius: 4px;
}

.annual-model-inner-body-div{
  display: flex;
  padding-left: 20px;
  padding-top: 8px;
  grid-gap: 30px;
  gap: 30px;
}

.selectDropDownannual{
  width: 60%;
}

@media screen and (max-width: 992px) {
  .virtual-model-div {
    display: flex;
    flex-direction: column;
    height: 26%;
    width: 50%;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  .virtual-model-div-2 {
  display: flex;
  flex-direction: column;
  height: 17%;
  width: 50%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
}
}

@media screen and (max-height: 850px) {
  .virtual-model-div {
    display: flex;
    flex-direction: column;
    height: 36%;
    width: 50%;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  .virtual-model-div-2 {
  display: flex;
  flex-direction: column;
  height: 27%;
  width: 50%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
}
}

.virtual-model-header-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 3px;
  outline: none;
  background-color: #f2f2f2;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
}

.virtual-model-header-div-2{
  display: flex;
  flex-direction: row;
  grid-gap:50%;
  gap:50%;
  border-radius: 3px;
  outline: none;
  background-color: #f2f2f2;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
}

.virtual-model-header-text {
  margin-top: 20px;
  margin-left: 28px;
  margin-bottom: 23px;
}

.virtual-model-cross-icon {
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 23px;
}

.virtual-model-cross-icon:hover {
  cursor: pointer;
}

.virtual-model-body-div {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 30px;
  grid-row-gap: 24%;
  row-gap: 24%;
  height: 50%;
  justify-content: center;
}

.virtual-model-inner-body-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column-gap: 40px;
  column-gap: 40px;
}

.virtual-cell-box {
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
  font-style: normal;
}

.virtual-cell-box-header {
  display: flex;
  flex-direction: column;
  color: #686868;
  font-weight: 400;
  font-size: 12px;
}
.virtual-cell-box-header-2 {
  display: flex;
  flex-direction: column;
  color: #686868;
  font-weight: 400;
  font-size: 14px;
}

.virtual-cell-box-value {
  display: flex;
  flex-direction: column;
  color: #1b1b1b;
  font-weight: 500;
  font-size: 14px;
}
.virtual-cell-box-value-2 {
  display: flex;
  flex-direction: column;
  color: #2e2e2e;
  font-weight: 500;
  font-size: 16px;
  margin-top: 7px;
}

.cellBox-parent-div {
  width: 40%;
}

.virtual-model-copy-icon:hover {
  cursor: pointer;
}

.client_investment_summary {
  display: flex;
  flex-direction: column;
  margin-left: 220px;
  max-height: 900px;
  padding: 0 30px;
}

/* .client_investment_summary_templates{
   padding : 20px 0;
} */

.client_investment_consent_button {
  background-color: #edf4ff;
  color: #3a86ff;
  border: none;
  border-radius: 4px;
  text-underline-offset: 3px;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  width: "auto";
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
}
.consent_resend_button {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  color: #3a86ff;
}

.client_summary_toggle_container {
  display: flex;
  align-self: center;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-top: 30px;
  border-bottom: 1px solid #d4d4d4;
}
.client_summary_toggle_element {
  font-size: 20px;
  color: #757575;
  margin-right: 7%;
  cursor: pointer;
}
.client_summary_toggle_element_active {
  font-size: 20px;
  font-weight: 600;
  color: #3a86ff;
  margin-right: 7%;
  cursor: pointer;
  border-bottom: 3px solid #3a86ff;
  padding-bottom: 12px;
}

/* @media screen and (max-width: 1400px) {
  .client_investment_summary {
    margin-left: 260px;
    margin-right: 10px;
  }
} */

.clientrepayment_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientrepayment_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.clientrepayment_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clientrepayment_header_div_title_container {
  display: flex;
  flex-direction: column;
}

.clientrepayment_header_div_subtitle {
  margin: 0;
  padding: 0;
  color: #969494;
  font-size: 14px;
}

.clientrepayment_header_div_title {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.clientrepayment_header_button_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientrepayment_status_button_r {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientrepayment_status_button_u {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--blue);
  background-color: #fff;
  color: var(--blue);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientrepayment_status_button_s {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientrepayment_status_button_d {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--gray);
  background-color: #fff;
  color: var(--gray);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.pool_list_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 100px;
  margin-left: 220px;
}

.pool_list_heading_container {
  display: flex;
  flex-direction: column;
  width: 92%;
  align-self: center;
  margin-top: 30px;
}

.pool_list_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.pool_list_content{
  margin-top: 30px;
  width: 92%;
  align-self: center;
}



.ifa_payment_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.ifa_payment_logo_image {
  margin-top: 40px;
  width: 180px;
  margin-left: 2%; 
}

.payment_success_container {
  border: 1px solid #DEDEDE;
  border-radius: 8px;
}

.payment_success_details {
  border-bottom: 1px solid #DEDEDE;
}

.payment_success_details_text {
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
}

.ifa_payment_page_expire {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.ifa_payment_div {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
}

.ifa_payment_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: rgb(218, 218, 218);
}

.ifa_payment_header_title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(87, 87, 87);
}

.ifa_payment_header_page_expire_title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: rgb(87, 87, 87);
}

.ifa_payment_div_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgb(233, 232, 232);
}

.ifa_payment_data_div1 {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ifa_payment_data_div2 {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ifa_payment_data_div3 {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ifa_payment_data {
  display: flex;
  flex-direction: column;
}

.ifa_payment_data_title {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 100;
  color: #000;
}

.ifa_payment_data_subtitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: #2f2f2f;
}

.ifa_payment_data_div4 {
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ifa_payment_button {
  color: #fff;
  padding: 12px;
  border: none;
  width: 87%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.ifa_payment_agreement {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ifa_payment_agreement_title {
  font-size: 16px;
  font-weight: 300;
  margin-left: 4px;
  padding: 0;
  color: rgb(63, 63, 63);
}

.agreement_link {
  color: var(--blue);
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: none;
}

.otp_info_text_div {
  width: 85%;
  margin-top: 5%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_info_text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: rgb(63, 63, 63);
}

.ifa_payment_input {
  width: 70%;
  margin-top: 8%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}

.ifa_payment_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-bottom: 5px;
  width: 74%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ifa_payment_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

.ifa_payment_otp_button {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: larger;
  outline: none;
  cursor: pointer;
}

.ifa_payment_otp_button:hover {
  background-color: var(--blue-hover);
}

@media only screen and (max-width: 1230px) {
  .ifa_payment_logo_image {
    width: 20%;
  }

  .ifa_payment_page_expire {
    width: 40%;
  }

  .ifa_payment_div {
    width: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .ifa_payment_logo_image {
    width: 25%;
  }

  .ifa_payment_page_expire {
    width: 50%;
  }

  .ifa_payment_div {
    width: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .ifa_payment_logo_image {
    width: 30%;
  }

  .ifa_payment_page_expire {
    width: 60%;
  }

  .ifa_payment_div {
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .ifa_payment_logo_image {
    width: 35%;
  }

  .ifa_payment_page_expire {
    width: 70%;
  }

  .ifa_payment_div {
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .ifa_payment_logo_image {
    width: 40%;
  }

  .ifa_payment_page_expire {
    width: 80%;
  }

  .ifa_payment_div {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .ifa_payment_logo_image {
    width: 45%;
  }

  .ifa_payment_page_expire {
    width: 90%;
  }

  .ifa_payment_div {
    width: 90%;
  }

  .ifa_payment_data_div2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .ifa_payment_data_div3 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .ifa_payment_data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ifa_payment_data_title {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .ifa_payment_data_subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .ifa_payment_agreement_title {
    font-size: 14px;
  }

  .ifa_payment_button {
    padding: 12px;
    width: 87%;
    font-size: 16px;
  }

}

.payment_success_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.payment_success_page_container {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #11a75c;
  border-radius: 25px;
  margin-top: 10vh;
}

.payment_success_image_container {
  margin-top: 5px;
  /* margin-left: 298px; */
}

.payment_success_check_mark {
  height: 96px;
  width: auto;
}

.payment_success_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 100px;
  padding-top: 35px;
}

.payment_success_main_title_text {
  font-family: "Asap";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 19px;
  line-height: 32px;
  /* identical to box height */
  /* color: #11a75c; */
}
.payment_success_head_grid_container {
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
  border-radius: 20px;
  border: 1px solid transparent;
  width: 550px;
  padding: 20px;
}

.payment_success_item_left {
  text-align: left;
}

.payment_success_item_right {
  text-align: right;
}

.payment_success_grid_container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
}

.payment_success_item_text {
  color: black;
  font-size: 13.5px;
}


.wallet_request_confirmation_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.wallet_request_confirmation_logo_image {
  margin-top: 40px;
  width: 180px;
  margin-left: 2%;
}

.wallet_request_confirmation_page_expire {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.wallet_request_confirmation_div {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
}

.wallet_request_confirmation_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: rgb(218, 218, 218);
}

.wallet_request_confirmation_header_title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(87, 87, 87);
}

.wallet_request_confirmation_header_page_expire_title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: rgb(87, 87, 87);
}

.wallet_request_confirmation_div_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgb(233, 232, 232);
}

.wallet_request_confirmation_data_div1 {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wallet_request_confirmation_data_div2 {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wallet_request_confirmation_data_div3 {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wallet_request_confirmation_data {
  display: flex;
  flex-direction: column;
}

.wallet_request_confirmation_data_title {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 100;
  color: #000;
}

.wallet_request_confirmation_data_subtitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: #2f2f2f;
}

.wallet_request_confirmation_data_div4 {
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wallet_request_confirmation_button {
  color: #fff;
  padding: 12px;
  border: none;
  width: 87%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.wallet_request_confirmation_agreement {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wallet_request_confirmation_agreement_title {
  font-size: 16px;
  font-weight: 300;
  margin-left: 4px;
  padding: 0;
  color: rgb(63, 63, 63);
}

.agreement_link {
  color: var(--blue);
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: none;
}

.otp_info_text_div {
  width: 85%;
  margin-top: 5%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_info_text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: rgb(63, 63, 63);
}

.wallet_request_confirmation_input {
  width: 70%;
  margin-top: 8%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}

.wallet_request_confirmation_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-bottom: 5px;
  width: 74%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wallet_request_confirmation_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

.wallet_request_confirmation_otp_button {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: larger;
  outline: none;
  cursor: pointer;
}

.wallet_request_confirmation_otp_button:hover {
  background-color: var(--blue-hover);
}


.virtual_account{
  margin: 10px;
  padding: 13px;
  font-size: 13px;
  /* background-color: #f4f4f4; */
  
}

.vpa_title{
  margin-top: 5px;
  font-size: 15px;
  justify-content: space-between;
  display: flex;
}

.vpa_subtitle{
  font-weight: 700;

  font-size: 15px;
  font-family: "";
}

.element {
  position: relative;
}

.tooltip {
  display: none;
  border: 1px solid #9e9e9e;
  font-family: "Asap";
  font-style: normal;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
  padding: 2px;
  position: absolute;
  font-size: 10px;
  width: 130px;
  color: #9e9e9e;
  margin-left: -22px;
  line-height: 16px;
}

.element:hover .tooltip {
  display: block;  
}


.vpaUnorderList {
  /* background: #F3F3F3; */
  border-radius: 4px 4px 0px 0px;
  padding-left: 0px;
  padding-top: 0;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 18px; */
  color: #333333;
  list-style: none;
  height: -moz-fit-content;
  height: fit-content;
  /* display: flex; */
  align-items: center;
  padding-right: 15px;
  padding-left: 10px;

}

.vpa_request_confirmation_input {
  width: 70%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}



.paytmEnterVpa {
  /* background: #F3F3F3; */
  border-radius: 4px 4px 0px 0px;
  padding-left: 0px;
  padding-top: 0;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 18px; */
  color: #333333;
  list-style: none;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 15px;
  padding-left: 10px;

}


.radioClass{
  height: 15px;
    margin-right: 10px;
    width: 15px;
    cursor: pointer;
}
.copy_button{
  border: 1px solid rgb(0, 145, 255);
  padding: 2px;
  color: rgb(0, 145, 255);
  cursor: pointer;
  font-size: 10px;
  margin-top: -5px;
}

@media only screen and (max-width: 1230px) {
  .wallet_request_confirmation_logo_image {
    width: 20%;
  }

  .wallet_request_confirmation_page_expire {
    width: 40%;
  }

  .wallet_request_confirmation_div {
    width: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .wallet_request_confirmation_logo_image {
    width: 25%;
  }

  .wallet_request_confirmation_page_expire {
    width: 50%;
  }

  .wallet_request_confirmation_div {
    width: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .wallet_request_confirmation_logo_image {
    width: 30%;
  }

  .wallet_request_confirmation_page_expire {
    width: 60%;
  }

  .wallet_request_confirmation_div {
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .wallet_request_confirmation_logo_image {
    width: 35%;
  }

  .wallet_request_confirmation_page_expire {
    width: 70%;
  }

  .wallet_request_confirmation_div {
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .wallet_request_confirmation_logo_image {
    width: 40%;
  }

  .wallet_request_confirmation_page_expire {
    width: 80%;
  }

  .wallet_request_confirmation_div {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .wallet_request_confirmation_logo_image {
    width: 45%;
  }

  .wallet_request_confirmation_page_expire {
    width: 90%;
  }

  .wallet_request_confirmation_div {
    width: 90%;
  }

  .wallet_request_confirmation_data_div2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .wallet_request_confirmation_data_div3 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .wallet_request_confirmation_data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .wallet_request_confirmation_data_title {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .wallet_request_confirmation_data_subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .wallet_request_confirmation_agreement_title {
    font-size: 14px;
  }

  .wallet_request_confirmation_button {
    padding: 12px;
    width: 87%;
    font-size: 16px;
  }

  .wallet_request_confirmation_data_div1 {
    flex-direction: column;
  }
}

.support_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.support_main_div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.support_header {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.support_image {
  width: 35%;
}

.support_form_div {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.support_form_container {
  min-width: 35%;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 30px;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.support_form_container_div {
  width: 90%;
  display: flex;
  align-self: center;
}

.support_form_container_title {
  margin: 0;
  padding: 0;
  color: #494949;
  font-size: 20px;
  font-weight: 600;
}

.support_input_box_subject {
  width: 85%;
  resize: none;
  margin-top: 15px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #494949;
  border-radius: 3px;
}

.support_input_box_message {
  width: 85%;
  height: 150px;
  resize: none;
  margin-top: 15px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
}

.support_input_box_subject::placeholder {
  color: #838383;
}

.support_input_box_message::placeholder {
  color: #838383;
}

.support_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 15px;
  padding: 12px;
  border: none;
  width: 90%;
  font-size: 16px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.contact_box {
  min-width: 35%;
  padding: 15px;
  padding-top: 15px;
  padding-bottom: 20px;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 15px;
}

.support_submit_container:hover {
  background-color: var(--blue-hover);
}

.support_subject {
  /* border: 1px solid #494949;
  border-radius: 3px;
  border-top-width: 1px ; */
  margin-top: 1%;
  width: 90%;
  padding: 12px;
}

.support_subject:hover {
  cursor: pointer;
  color: black;
  border-width: 2px;
}

.support_contact {
  color: #494949;
  margin-left: 50px;
  font-weight: 400;
}

.support_icon {
  margin-right: 10px;
}

.details {
  margin-top: 20px;
  display: flex;
  align-self: center;
  flex-direction: row;
  width: 90%;
  align-items: center;
}
.phone_icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  text-align: center;
  font-size: 15px;
  color: #494949;
}

.email_icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  color: #494949;
}

@media  (max-width: 1400px) {
  .details {
    flex-direction: column;
    align-items: baseline;
  }
  
}

@media (max-width: 1000px) {
  .support_subject {
    width: 95%;
  }  
}

@media (max-width: 760px) {
  .support_subject {
    width: 100%;
  }  
  .contact_box {
    padding-left: 0px;
    padding-right: 10px;
  }
}
.pagenotfound_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pagenotfound_rest_container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
  align-items: center;
}

.pagenotfound_container_title {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 100px;
  letter-spacing: 10px;
  color: var(--blue);
  text-align: center;
}

.pagenotfound_container_subtitle {
  margin-top: 10px;
  font-size: 25px;
  color: var(--gray);
  text-align: center;
}

.withdrawal_confirmation_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.withdrawal_confirmation_logo_image {
  margin-top: 40px;
  width: 180px;
  margin-left: 2%;
}

.withdrawal_confirmation_page_expire {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.withdrawal_confirmation_div {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
}

.withdrawal_confirmation_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: rgb(218, 218, 218);
}

.withdrawal_confirmation_header_title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(87, 87, 87);
}

.withdrawal_confirmation_header_page_expire_title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: rgb(87, 87, 87);
}

.withdrawal_confirmation_div_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgb(233, 232, 232);
}

.withdrawal_confirmation_data_div1 {
  width: 93%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
}

.withdrawal_confirmation_data_div2 {
  width: 93%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.withdrawal_confirmation_data_div3 {
  width: 93%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.withdrawal_confirmation_data {
  display: flex;
  flex-direction: column;
}

.withdrawal_confirmation_data_title {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 100;
  color: #000;
}

.withdrawal_confirmation_data_subtitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: #2f2f2f;
}

.withdrawal_confirmation_data_div4 {
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.withdrawal_confirmation_data_div5 {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 33% 33%;
  text-align: left;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 20px;
  
}

.withdrawal_confirmation_button {
  color: #fff;
  padding: 12px;
  border: none;
  width: 87%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.withdrawal_confirmation_agreement {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.withdrawal_confirmation_agreement_title {
  font-size: 16px;
  font-weight: 300;
  margin-left: 4px;
  padding: 0;
  color: rgb(63, 63, 63);
}

.agreement_link {
  color: var(--blue);
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: none;
}

.otp_info_text_div {
  width: 85%;
  margin-top: 5%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_info_text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: rgb(63, 63, 63);
}

.withdrawal_confirmation_input {
  width: 70%;
  margin-top: 8%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}

.withdrawal_confirmation_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-bottom: 5px;
  width: 74%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.withdrawal_confirmation_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

.withdrawal_confirmation_otp_button {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: larger;
  outline: none;
  cursor: pointer;
}

.withdrawal_confirmation_otp_button:hover {
  background-color: var(--blue-hover);
}

@media only screen and (max-width: 1230px) {
  .withdrawal_confirmation_logo_image {
    width: 20%;
  }

  .withdrawal_confirmation_page_expire {
    width: 40%;
  }

  .withdrawal_confirmation_div {
    width: 50%;
  }
}

@media only screen and (max-width: 1100px) {
  .withdrawal_confirmation_logo_image {
    width: 25%;
  }

  .withdrawal_confirmation_page_expire {
    width: 50%;
  }

  .withdrawal_confirmation_div {
    width: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .withdrawal_confirmation_logo_image {
    width: 30%;
  }

  .withdrawal_confirmation_page_expire {
    width: 60%;
  }

  .withdrawal_confirmation_div {
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .withdrawal_confirmation_logo_image {
    width: 35%;
  }

  .withdrawal_confirmation_page_expire {
    width: 70%;
  }

  .withdrawal_confirmation_div {
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .withdrawal_confirmation_logo_image {
    width: 40%;
  }

  .withdrawal_confirmation_page_expire {
    width: 80%;
  }

  .withdrawal_confirmation_div {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .withdrawal_confirmation_logo_image {
    width: 45%;
  }

  .withdrawal_confirmation_page_expire {
    width: 90%;
  }

  .withdrawal_confirmation_div {
    width: 90%;
  }

  .withdrawal_confirmation_data_div2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .withdrawal_confirmation_data_div3 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .withdrawal_confirmation_data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .withdrawal_confirmation_data_title {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .withdrawal_confirmation_data_subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .withdrawal_confirmation_agreement_title {
    font-size: 14px;
  }

  .withdrawal_confirmation_button {
    padding: 12px;
    width: 87%;
    font-size: 16px;
  }
}

.wallet_request_success_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.wallet_request_success_page_container {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #11a75c;
  border-radius: 25px;
  margin-top: 20vh;
}

.wallet_request_success_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.wallet_request_success_check_mark {
  height: 80px;
  width: auto;
}

.wallet_request_success_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 100px;
  padding-top: 35px;
}

.wallet_request_success_main_title_text {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  /* identical to box height */
  color: #11a75c;
}
.wallet_request_success_head_grid_container {
  margin-top: 130px;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
  border-radius: 20px;
  border: 1px solid transparent;
  width: 550px;
  padding: 20px;
}

.wallet_request_success_item_left {
  text-align: left;
}

.wallet_request_success_item_right {
  text-align: right;
}

.wallet_request_success_grid_container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
}

.wallet_request_success_item_text {
  color: black;
  font-size: 13.5px;
}

.profile_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile_rest_div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.profile_rest_div_heading {
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  align-self: center;
  color: #c0c0c0;
  font-weight: 800;
  font-size: 28px;
}

.profile_border {
  background: linear-gradient(to bottom, #f0f0f0 180px, white 0%);
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 30px 20px;
  padding: 0;
  width: 92%;
  margin-top: 60px;
  align-self: center;
  /* color: #848884; */
  font-weight: 800;
  font-size: 30px;
}

.profile_section {
  margin-top: 34px;
  margin-bottom: 40px;
  padding-left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile_circle {
  align-items: center;
  height: 100px;
  width: 100px;
  background-color: #6b6d6e;
  color: #f0f0f0;
  border-radius: 50%;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 20px;
}

.profile_section_heading {
  /* margin: 1%; */
  padding: 0 px;
  /* margin-left: -1px; */
  width: 100%;
  margin-bottom: 0px;
  margin-top: 10px;
  align-self: center;
  color: #36454f;
  font-weight: 500;
  font-size: 28px;
}
.profile_client_heading {
  margin: 0;
  padding-left: 10px;
  width: 92%;
  margin-top: 10px;
  align-self: center;
  color: #36454f;
  font-weight: 500;
  font-size: 28px;
}

.profile_tag {
  margin-left: -10px;
  padding: 0;
  font-size: 15px;
  color: #36454f;
  align-self: center;
  font-weight: 100;
  width: 100%;
}

.profile_row {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.profile_row_container {
  display: flex;
  height: auto;
  /* margin-top: 40px; */
  padding-top: 0;
}

.profile_row_container_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  padding-left: 15px;
  border-radius: 3px;
  border: 1px #d0d0d0;
  /* height : 800px; */
}

.profile_row_container_title {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  color: #b5b4b3;
  font-weight: 500;
  font-size: 13px;
  height: 15px;
}

.profile_row_container_subtitle {
  margin: 0;
  /* padding-left:50px; */
  padding: 0;
  margin-top: 0px;
  color: #262626;
  font-weight: 300;
  font-size: 17px;
  /* height : 50px; */
}

.ifa_profile_referral_code {
  cursor: pointer;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  font-size: 18px;
  width: 125px;
}

.profile_row_container_ref {
  /* margin-right: 5px; */
  /* padding:10px; */
  margin-bottom: 10px;
  color: #4d4b4a;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  /* height: 20px; */
}

.profile_initials {
  display: flex;
  flex-direction: row;
}

.profile_referral_wrapper {
  width: 20%;
}

.profile_referral_span {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ifa_profile_email_status {
  font-weight: 300;
  font-size: 14px;
  color: #069952;
}
.email_verified_status {
  height: 16px;
  width: 16px;
  margin-bottom: -3px;
}

.ifa_profile_email_text {
  margin-right: 10px;
  font-weight: 300;
  font-size: 14px;
  color: #262626;
}
.ifa_email_pending_container {
  width: 70%;
  border-radius: 4px;
  border: 0.5px solid #db8400;
  background: #fffaf2;
  margin-top: 5px;
  display: flex;
}

.ifa_email_pending_text {
  margin: 0;
  color: #db8400;
  font-size: 12px;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: -5px;
}

.ifa_email_pending_text_without_subtext {
  margin: 0;
  color: #db8400;
  font-size: 12px;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  border: 0.5px solid #db8400;
  background: #fffaf2;
  margin-top: 5px;
  display: flex;
}

.ifa_email_pending_subtext {
  margin: 0;
  color: #db8400;
  font-size: 10px;
  font-family: Asap;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ifa_email_send_button {
  border: 0.5px solid #3a86ff;
  background: #3a86ff;
  color: #fff;
  font-size: 9px;
  font-family: Poppins;
  font-style: normal;
  width: 70px;
  padding: 7px;
  font-weight: 400;
  margin: 6px;
  line-height: normal;
  margin-top: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.ifa_email_container{
  margin-top: -10px;
}
.clientlist_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientlist_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.clientlist_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clientlist_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.clientlist_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.clientlist_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 13%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.clientlist_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.clientlist_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
}

.clientlist_consent_status {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  color: var(--blue);
}

.clientlist_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientlist_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientlist_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.clientlist_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientlist_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.clientlist_table_flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientlist_kyc_status_text {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  color: var(--orange);
  font-size: 11px;
}

.tableContainer {
  margin-top: 20px !important;
  width: 100% !important;
  border-top-left-radius: "5px" !important;
  border-top-right-radius: "5px" !important;
  border-top: "1px solid #c7c7c7" !important;
  border-left: "1px solid #c7c7c7" !important;
  border-right: "1px solid #c7c7c7" !important;
  align-self: "center" !important;
  /* margin-left: 30px ; */
}

.clientListActionDiv {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  margin-right: 20px;
}

.css-y8ay40-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  /* padding: 16px; */
  color: rgba(0, 0, 0, 0.87);
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
}

.remarks_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.remarks_dot {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

.remarks_text {
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-size: 12px;
  color: #424242;
}

.consent_modal {
  position: fixed;
  top: 25%;
  justify-content: center;
  background-color: white;
  width: 600px;
}

.consent_modal_button {
  padding-bottom: 40px;
}

.consent_button {
  color: white;
  background-color: #3a86ff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 60%;
  border: 1px solid #3a86ff;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.table_button_text {
  font-size: 14px;
  color: #3a86ff;
  cursor: pointer;
  white-space: pre;
}

.faq_div_container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.faq_main_div{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;
}

.faq_title{
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.faq_title_text{
  font-weight: 500;
}
.faq_style_wrapper{
  width : 70%;
  margin-top: 30px;
}

.faq_style_wrapper .faq-body .faq-row .row-content .row-content-text{
    font-size: medium !important;
    line-height: 1.5em !important;
}

.faq_style_wrapper .faq-body .faq-row .row-content{
  width: 95%;
  text-align: justify;
}
.downloads_div_container {
    display: flex;
    flex-direction: column;
    height: 80%;
    /* padding:4%; */
  }

  .downloads_header_div_title {
    
    color: #42474f;
    font-weight: 600;
    font-size: 28px;
  }
  

  .downloads_main_div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 220px;
  }

  .support_header {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .download_image {
    display: table;
    grid-row: 1 /2;
    margin-bottom: 10px;
    width:200px;
    padding:5%;
    align-self: center;
    align-items: center;
  }
  .row-with-download:hover button {
    display: block;
    }

  .tabdownloads {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    border-bottom: 2px solid transparent;
  }
  
  .tabdownloads.selected {
    color:rgb(58, 134, 255);
    border-bottom: 2px solid rgb(58, 134, 255);
  }
.logo_main_input_form {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }
.logo_main_input_label {
font-size: 16px;
margin: 0;
padding: 0;
font-weight: normal;
margin-bottom: 12px;
}

.logo_main_image_div_container {
display: flex;
height: 45px;
width: 100%;
margin: 0;
padding: 0;
margin-bottom: 12px;
border: 1px solid #cacaca;
border-radius: 3px;
flex-direction: row;
align-items: center;
overflow: hidden;
}
  
.logo_main_image_div {
flex: 1 1;
height: 50px;
width:200px;
display: flex;
align-items: center;
margin: 0;
padding: 0;
padding-left: 12px;
border: none;
}
  
.logo_main_image_text {
margin: 0;
padding: 0;
font-size: 16px;
}

.logo_main_image_button {
height: 100%;
width: 28%;
display: flex;
align-items: center;
justify-content: center;
font-size: medium;
background-color: var(--blue); 
color: #fff;
border: none;
cursor: pointer;
outline: none;
}
.logo_main_image_button:hover {
    background-color: var(--blue-hover);
    }

/* @media only screen and (max-width: 550px) {
    .logo_main_image_button {
      width: 28%;
    }
} */

.logo_field_container {
    display: flex;
    flex-direction: column;
  }
.logo_submit_container {
  background-color: var(--blue);
  color: #fff;
  align-items: "center";
  display: "flex";
  align-self: "center";
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 50%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  margin-left:25%
}
.download_image {
    display: block;
    margin-bottom: 10px;
    width:200px;
    padding:5%;
  }
  .support_header {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    grid-row: 1 / 2;
  }
  

  .item2 {
    width: 120px;
    min-height: 120px;
    max-height: auto;
    float: left;
    margin: 3px;
    padding: 3px;
}

.container { 
  position: static;
  border: 3px solid #2c2e2b0d; 
  padding:5%;
  border:20%;
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 25px;
}
.container img { 
  display: flex;
  border-radius: 25px;
 }
.container .fa-download { 
  position: absolute; 
  border-bottom:2px; 
  border-right: 0px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  }

.label{
  display : flex;
  justify-content: space-between;
}

/* for tabs */
a {
  display: inline-block;
  padding: 10px;
  color: black;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

/* Style for the tab when hovered */
a:hover {
  color: "#3A86FF";
  border-bottom: 2px solid rgb(42, 81, 220);
}

/* Style for the tab when active */
a:active {
  color: "#3A86FF";
  border-bottom: 2px solid rgb(43, 82, 225);
}

/* thatsbetter */
.tab {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
  border-bottom: 2px solid transparent;
  cursor:pointer;
}

.tab.selected {
  color:rgb(43, 82, 225);
  border-bottom: 2px solid rgb(43, 82, 225);
  cursor:pointer;
}

.marketing_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* padding:4%; */
}

.marketing_main_div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 200px;
}
.wallet_request_failure_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.wallet_request_failure_page_container {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #ff6666;
  border-radius: 25px;
  margin-top: 20vh;
}

.wallet_request_failure_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.wallet_request_failure_check_mark {
  height: 80px;
  width: auto;
}

.wallet_request_failure_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 100px;
  padding-top: 35px;
}

.wallet_request_failure_main_title_text {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  /* identical to box height */
  color: #ff6666;
}

.wallet_request_failure_head_grid_container {
  margin-top: 130px;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
  border-radius: 20px;
  border: 1px solid transparent;
  width: 550px;
  padding: 20px;
}

.wallet_request_failure_item_left {
  text-align: left;
}

.wallet_request_failure_item_right {
  text-align: right;
}

.wallet_request_failure_grid_container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
}

.wallet_request_failure_item_text {
  color: black;
  font-size: 13.5px;
}

.transaction_requests_div_container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .transaction_requests_div_table_container{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 220px;
  }

  .transaction_requests_header_div {
    width: 90%;
    align-self: center;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .transaction_requests_header_div_title {
    margin: 0;
    padding: 0;
    color: #42474f;
    font-weight: 600;
    font-size: 24px;
  }
  .toggle_container{
    display: flex;
    align-self: center;
    flex-direction: row ;
    width: 90%;
    justify-content: flex-start;
    margin-top: 30px;
    border-bottom: 1px solid #8c8c8c;
  }
  .toggle_element{
    font-size: 20px;
    color: #616162;
    margin-right: 10%;
    cursor: pointer;
  }
  .toggle_element_active{
    font-size: 20px;
    font-weight: 600;
    color:#3A86FF;
    margin-right: 10%;
    cursor: pointer;
    border-bottom: 3px solid #3A86FF;
    padding-bottom: 12px;
  }


.commissions_summary_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.commissions_summary_div_table_container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.commissions_summary_header_div {
  width: 90%;
  align-self: center;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.commissions_summary_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 24px;
}
.toggle_container {
  display: flex;
  align-self: center;
  flex-direction: row;
  width: 90%;
  justify-content: flex-start;
  margin-top: 30px;
  border-bottom: 1px solid #8c8c8c;
}
.toggle_element {
  font-size: 20px;
  color: #616162;
  margin-right: 10%;
  cursor: pointer;
}
.toggle_element_active {
  font-size: 20px;
  font-weight: 600;
  color: #3a86ff;
  margin-right: 10%;
  cursor: pointer;
  border-bottom: 3px solid #3a86ff;
  padding-bottom: 12px;
}
.commissions_summary_info_container {
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 2%;
}
.commissions_summary_info {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.commissions_summary_info_heading {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3%;
  color: #4e4e4e;
  display: grid;
  grid-template-columns: 90% 6%;
}
.commissions_summary_info_value {
  color: #262626;
  font-weight: 600;
  font-size: 24px;
}
.commissions_summary_info_value_1{
  color: #393939;
  font-weight: 500;
  font-size: 24px;
}
.paid_value{
  color: #2DAE18;
  font-weight: 600;
  font-size: 20px;
}
.unpaid_value{
  color: #E25656;
  font-weight: 600;
  font-size: 20px;
}
.table_container {
  width: 90%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no_selection_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12%;
}
.no_selection_text {
  font-size: 14px;
  color: #4e4e4e;
  margin-top: 4%;
  font-weight: 400;
}
.MenuItem{
  font-family: "Asap";
}
.table_data_container{
  width: 100%;
  align-items: center
}
.table_footer{
  position: absolute;
  white-space: nowrap;
  top: 92%;
  right: 4%;
}
.total_commission{
  font-weight: 400;
  font-size: 18px;
  margin-top: 2%;
  margin-bottom: 2%;
}
.contact_us{
  font-weight: 500;
  font-size: 18px;
  margin-left: 1%;
  color: #000000
}

.invoice_section{
  display: flex;
  float: left;
  height: 50px;
}
.ifa_commissions_generate_invoice{
  background-color: var(--blue);
  border-radius: 3px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  width: 160px;
  justify-content: center;
  text-align: center;
  border: none;
  padding-top: 10px;
  color: white;
  padding-bottom: 10px;
  cursor: pointer;
}

.ifa_commissions_generate_invoice_button_text{
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
  text-align: center;
}

.invoice_link{
  text-decoration: none;
  color: blue;
  margin: 0;
  padding: 0;
}

.total_commissions_earnings_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1%;
}

.earnings_toggle_container {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 14px;
  font-family: "Asap";
  margin-top: 1%;
  margin-bottom: 1.6%;
  border-radius: 6px;
  margin-right: 3.1%;
}

.earnings_toggle_element {
  color: #3a86ff;
  cursor: pointer;
  padding: 5%;
  white-space: nowrap;
  border: 1px solid #3a86ff;
  width:110%;
}

.earnings_toggle_element_active {
  color: white;
  cursor: pointer;
  background-color: #3a86ff;
  padding: 5%;
  white-space: nowrap;
  border: 1px solid #3a86ff;
  width:110%;
}

.monthly {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.maturity {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.auth_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.auth_logo_image {
  width: 180px;
  cursor: pointer;
  margin-left: 2%;
}

.client_kyc_link_main_container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.client_kyc_link_container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.client_kyc_link_body_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.clientkyclink_main_container_main_title {
  flex: 2.3 1;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  width: 500px;
  text-align: center;
  line-height: 27px;
}
.clientkyclink_main_container_title {
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 30px;
}
.clientkyclink_main_container_1 {
  display: flex;
  width: 60%;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.clientkyclink_main_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-left: 15vw;
}

.clientkyclink_main_container_div2 {
  /* margin-top: 10vh; */
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}
.clientkyc_main_container_title_1 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  color: #3f3f3f;
}

.kyc_link_button_container {
  margin-left: 15vw;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 70%;
}

.kyc_link_checkbox_container {
  margin-top: 30px;
  margin-left: 15vw;
  margin-bottom: 20px;
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}

.kyc_link_submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 41%;
  background-color: var(--green);
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_link_submit_button:hover {
  background-color: var(--green-hover);
}

.clientkyc_user_exist_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clientkyc_user_exist_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #414141;
}

.clientkyc_user_exist_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}
.clientkyclink_main_container_div1 {
  margin-top: 1vh;
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}
.clientkyc_user_exist_button:hover {
  background-color: var(--blue-hover);
}
.thank_you_main_container_new {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #50cd89;
  border-radius: 15px;
  position: fixed;
  padding: 40px;
  margin: 20%;
}

@media only screen and (max-width: 550px) {
  .clientkyclink_main_container_div1 {
    width: 75%;
  }
  .kyc_link_checkbox_container{
    width: 75%;
  }
  .client_kyc_link_container {
    width: 100%;
  }
  .clientkyclink_main_container {
    display: flex;
    width: 98%;
    margin-left: 1vw;
  }

  .auth_logo_image {
    width: 200px;
    height: auto;
    cursor: pointer;
  }

  .clientkyclink_main_container_main_title {
    margin-left: 0vw;
    width: 300px;
  }
  .clientkyclink_main_container_1 {
    width: 100%;
  }
  .clientkyclink_main_container_div2 {
    padding: 30px;
    width: 75%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid rgb(219, 219, 219);
  }
  .thank_you_main_container_new {
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #50cd89;
    border-radius: 15px;
    margin-left: 10%;
    margin-top: 200px;
    position: fixed;
    padding: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .clientkyclink_main_container_main_title {
    margin-left: 0vw;
    width: 400px;
  }
  .clientkyclink_main_container_1 {
    width: 80%;
  }
}
.client_tc_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
}


.thank_you_check_icon_new {
  /* margin-left: 150px; */
  margin-top: 10px;
}

.thank_you_text_new {
  margin-top: 10px;
}
.thank_you_subtext_new {
  /* margin-left: 10px; */
  width: 90%;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
}

.nominee_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
}
.skip_button {
  cursor: pointer;
  color: #3a86ff;
  text-decoration: underline;
}
.client_kyc_link_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.add_client_link_entity_subtext {
  margin-top: 5px;
  color: #4d4d4d;
  font-family: Asap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.add_client_kyc_link_main_submit_cta {
  padding: 10px;
  width: 53%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background-color: #3a86ff;
  margin-top: 20px;
  cursor: pointer;
}
.add_client_kyc_link_basic_details_cta_text {
  font-size: 18px;
  padding: 0;
  line-height: 21px;
  font-weight: 500;
  color: #ffffff;
}
.kyc_link_checkbox_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5px;
}
.kyc_link_consent_text {
  font-family: Poppins;
    font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  width: inherit;
}
.kyc_link_consent_checked {
  height: 16px;
  width: 16px;
  background-color: #064586;
  border-radius: 1px;
  margin-right: 2%;
  cursor: pointer;
}
.kyc_link_consent_unchecked {
  height: 12px;
  width: 12px;
  border: 2px solid #686868;
  cursor: pointer;
  border-radius: 1px;
  margin-right: 2%;
}
#address_toggle_label2 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  overflow: hidden;
  white-space: initial;
  padding-left: 0;
  margin-left: 0;
}

.clientkyc_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientkyc_main_div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-left: 220px;
  padding-bottom: 20px;
  overflow: hidden;
}

.clientkyc_top_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.clientkyc_top_row_container {
  width: 56%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientkyc_top_row_back {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
}

.clientkyc_main_container_main_title {
  flex: 2.3 1;
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 30px;
}

.clientkyc_main_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
}

.clientkyc_main_container_div1 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_div2 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_title {
  margin: 0;
  padding: 0;
  color: var(--blue);
  font-weight: 600;
  font-size: 22px;
}

.clientkyc_main_input_form {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.clientkyc_main_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 12px;
}

.clientkyc_main_input_field {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.clientkyc_main_input_field:disabled {
  background-color: #ececec;
  color: rgb(119, 119, 119);
}

.clientkyc_main_image_div_container {
  display: flex;
  height: 45px;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.clientkyc_main_image_div {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  border: none;
}

.clientkyc_main_image_text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.clientkyc_main_image_button {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  background-color: var(--blue);
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
}

.clientkyc_main_image_button:hover {
  background-color: var(--blue-hover);
}

.kyc_button_container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 70%;
}

.kyc_submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 50%;
  background-color: var(--green);
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_submit_button:hover {
  background-color: var(--green-hover);
}

@media only screen and (max-width: 550px) {
  .clientkyc_main_image_button {
    width: 28%;
  }
}

.clientkyc_ckyc_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_user_exist_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clientkyc_user_exist_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #414141;
}

.clientkyc_user_exist_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_user_exist_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_enable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button_pan_enable:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_disable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: #b9b9b9;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: not-allowed;
  border-radius: 3px;
}

.clientkyc_gender_input {
  padding-left: 15px;
}

.clientkyc_gender_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 3px;
}

/* Page not Found */
.clientpagenotfound_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientpagenotfound_rest_container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.clientpagenotfound_container_title {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 100px;
  letter-spacing: 10px;
  color: var(--blue);
  text-align: center;
}

.clientpagenotfound_container_subtitle {
  margin-top: 10px;
  font-size: 25px;
  color: var(--gray);
  text-align: center;
}

.client_tc_header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.client_tc_logo_image {
  width: 180px;
  cursor: pointer;
  margin-left: 2%;
}

.client_thank_page_container {
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 728px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #50cd89;
  border-radius: 25px;
}

.client_thank_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.client_thank_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 100px;
  padding-top: 35px;
}

.client_thank_main_title h3 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height */
  color: #2f2f2f;
}
.clientkyc_profile_input_form{
  display: flex;
  flex-direction: column;
}
.clientkyc_profile_input_label{
  margin-bottom: 10px;
}
.add_client_link_investor_select_type{
  display: flex;
  justify-content: flex-start;
}

.add_client_link_gender_type{
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
}
.entity_pan_cta_container{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.entity_pan_cta{
    padding: 5px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: #3A86FF;
    margin-top: 10px;
    cursor: pointer;
}
.entity_pan_cta_text{
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;

}
.add_clientkyclinkv2_pan_consent_checkbox{
  display: flex;
  flex-direction: row;
  margin-top: -1%;
  margin-left: 0px;
}
.add_clientkyclinkv2_pan_consent_checkbox_condition{
  font-size: 13px;
  margin-left: 0px;
  margin-top: 10px;
  color: #323232;
  font-family: 'Poppins';
}
.add_clientkyclink_change_cta{
  font-weight: 500;
  font-size: 14px;
  color: #3A86FF;
  cursor: pointer;
  margin-left: 1%;
}
.add_clientkyclink_investor_type{
  margin-bottom: 25px;
}
/* .upload_input_v2_main_contianer{
    margin-top:10px;
} */

.upload_input_title {
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #414141;
  }

  .upload_input_container {
    display: flex;
  }

  .upload_input_uploaded {
    margin-top: 1%;
    width: 70%;
    background: #ffffff;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    border-radius: 4px;
    height: 46px;
    float: left;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 11px 5px 12px 21px;
  }

  .upload_input_upload {
    margin-top: 1%;
    width: 70%;
    background: #ffffff;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    border-radius: 4px;
    height: 46px;
    float: left;
    color: grey;
    display: flex;
    flex-direction: column;
    padding: 11px 5px 12px 21px;
  }

  .upload_input_button {
    margin-top: 1%;
    width: 25%;
    margin-left: 5%;
    height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    background: #ffffff;
    /* color: #3a86ff; */
    border-style: solid;
    /* border-color:#3a86ff; */
    border-radius: 4px;
    /* border: none; */
  }

  .upload_input_info_text_container {
    margin-top: 15px;
    font-size: 50px;
  }
.add_clientkycv2_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.add_clientkycv2_main_div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-left: 220px;
  padding-bottom: 20px;
  overflow: hidden;
}

.add_clientkycv2_top_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.add_clientkycv2_top_row_container {
  width: 56%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add_clientkycv2_top_row_back {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
}

.add_clientkycv2_main_container_main_title {
  flex: 2.3 1;
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 30px;
}

.add_clientkycv2_main_conatiner {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.add_client_kyc_main_submit_cta {
  padding: 10px;
  width: 53%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background-color: #3a86ff;
  margin-top: 20px;
  cursor: pointer;
}

.add_client_v2_success_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 220px;
  margin-left: 221px;
  padding-bottom: 20px;
  overflow: hidden;
}

.add_client_success_screen_subtext_text {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  padding: 0;
  align-items: center;
  text-align: center;
  /* font-family: "Poppins"; */
  margin: 0;
}

.add_client_success_screen_v2 {
  margin-top: 25px;
}

.add_client_success_screen_text {
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  align-items: center;
  text-align: center;
  /* font-family: "Poppins"; */
  margin-top: 20px;
}

.add_client_success_screen_container {
  padding: 60px;
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  border: 1px solid rgb(219, 219, 219);
  align-items: center;
  width: 72%;
  padding-top: 90px;
  height: 300px;
}
.add_client_v2_success_screen_main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_client_kyc_basic_details_container{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin-top: 15px;
}

.add_client_kyc_basic_details_main_container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 70%;
    border-radius: 5px;
    border: 1px solid rgb(219, 219, 219);
}


.add_client_kyc_basic_details_submit_cta{
    padding: 10px;
    width: 75%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    background-color: #3A86FF;
    margin-top: 20px;
    cursor: pointer;
}

.add_client_kyc_basic_details_cta_text{
    font-size: 18px;
    padding: 0;
    line-height: 21px;
    font-weight: 500;
    color: #FFFFFF;
}

.add_client_investor_type{
    margin-bottom: 25px;
}

/* .add_client_gender_type_container{
    margin-bottom: 25px;
} */

.add_client_investor_select_type{
    display: flex;
}

.add_client_gender_type{
    display: flex;
}
.add_client_kyc_pre_pan_container{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 55%;
}

.add_client_kyc_pre_pan_main_container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid rgb(219, 219, 219);
}

.add_client_kyc_pre_pan_main_container_title{
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size:18px;
  line-height: 21px;
}

.add_client_kyc_pre_pan_submit_cta{
    padding: 10px;
    width: 97%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    background-color: #3A86FF;
    margin-top: 20px;
    cursor: pointer;
}

.add_client_kyc_pre_pan_cta_text{
    font-size: 18px;
    padding: 0;
    line-height: 21px;
    font-weight: 500;
    color: #FFFFFF;
}

.add_cientkycv2_pan_consent_checkbox {
    display: flex;
    flex-direction: row;
    margin-top: -1%;
    margin-left: 0px;
  }
  
  .add_cientkycv2_pan_consent_checkbox_condition {
    font-size: 13px;
    margin-left: 0px;
    margin-top: 10px;
    color: #323232;
    font-family: 'Poppins';
  }

  .add_client_pan_not_verified_container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    border: 1px solid rgb(219, 219, 219);
    align-items: center;
    width: 65%;
    padding-top: 90px;
    height: 300px;
  }
  
  .add_client_pan_not_verified_text{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    margin-top: 20px;
  }
  
  .add_client_pan_not_verified_support_text{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 0;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    margin: 0;
  }

  .add_client_pan_not_verified_support_email{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 10px;
    padding: 0;
    color: #064586;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    text-decoration: none;
  }

  .add_client_pan_not_verified_support{
    margin-top: 25px;
  }
.add_client_kyc_bank_details {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.add_client_kyc_bank_details {
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 70%;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.add_client_kyc_bank_verify_cta {
  display: flex;
  height: 45px;
  width: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 10px;
}

.add_cientkycv2_bank_consent_checkbox {
  display: flex;
  flex-direction: row;
  margin-top: -1%;
  margin-left: 0px;
}

.add_cientkycv2_bank_consent_checkbox_condition {
  font-size: 13px;
  margin-left: 0px;
  margin-top: 10px;
  color: #323232;
  font-family: "Poppins";
}


.add_client_kyc_nominee_main_container_title{
    display: flex;
    justify-content: space-between;
}

.add_client_kyc_nominee_text{
    color: var(--blue);
    border-bottom: 2px solid var(--blue);
    cursor: pointer;
}
.add_client_kyc_bank_details {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.add_client_kyc_bank_details {
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 70%;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}
/* #add_client_proprietor_detail_subtitle {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.04px;
} */
#add_client_proprietor_detail_subtitle {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
.add_client_kyc_bank_verify_cta {
  display: flex;
  height: 45px;
  width: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 10px;
}

.add_cientkycv2_bank_consent_checkbox {
  display: flex;
  flex-direction: row;
  margin-top: -1%;
  margin-left: 0px;
}

.add_cientkycv2_bank_consent_checkbox_condition {
  font-size: 13px;
  margin-left: 0px;
  margin-top: 10px;
  color: #323232;
  font-family: "Poppins";
}
.address_toggle {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
}
#address_toggle_label1 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 0;
  margin-left: 0;
}

.add_client_kyc_entity_main_container_title {
  display: flex;
  flex-direction: column;
}

.add_client_entity_subtext {
  margin-top: 5px;
  color: #4d4d4d;
  font-family: Asap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.beneficial_owner_tile {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size:18px;
  line-height: 21px;
}

#beneficial_owner_subtext{
  margin: 0;
  margin-top: 12px;
  margin-bottom: 40px;
  padding: 0;
  color: rgb(105, 105, 105);
}

#beneficial_owner_subtitle{
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
.add_benefifcial_button{
  float: right;
  padding: 10px;
  width: 30%;
  border-radius: 3px;
  margin-right: 0;
  border: none;
  margin-left: 70%;
  background-color: #3a86ff;
  color: #FFFFFF;
 cursor: pointer;
}
.add_client_kyc_bank_details {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.add_client_kyc_bank_details {
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 70%;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.add_client_kyc_bank_verify_cta {
  display: flex;
  height: 45px;
  width: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 10px;
}

.add_cientkycv2_bank_consent_checkbox {
  display: flex;
  flex-direction: row;
  margin-top: -1%;
  margin-left: 0px;
}

.add_cientkycv2_bank_consent_checkbox_condition {
  font-size: 13px;
  margin-left: 0px;
  margin-top: 10px;
  color: #323232;
  font-family: "Poppins";
}

.add_client_propritor_pan_verify_cta {
  padding: 10px;
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background-color: #3a86ff;
  margin-top: 20px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 0;
}

.address_toggle {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
}

#address_toggle_label {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  padding-left: 0;
  margin-left: 0;
}


#add_client_proprietor_detail_subtitle{
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size:18px;
  line-height: 21px;
}



.clientinvestmentcertificate_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 5vh;
}

.clientinvestmentcertificate_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.clientinvestmentcertificate_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clientinvestmentcertificate_header_div_title_container {
  display: flex;
  flex-direction: column;
}

.clientinvestmentcertificate_header_div_subtitle {
  margin: 0;
  padding: 0;
  color: #969494;
  font-size: 14px;
}

.clientinvestmentcertificate_header_div_title {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.clientinvestmentcertificate_header_button_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientinvestmentcertificate_download_all_container {
  margin-top: 20px;
  width: 92%;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.clientinvestmentcertificate_download_all_button {
  width: 170px;
  height: 50px;
  background-color: var(--blue);
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.clientinvestmentcertificate_download_all_button_text {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 16px;
}

.clientinvestmentcertificate_table_container {
  margin-top: 10px;
  width: 92%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 20px;
}

.download-icon {
  cursor: pointer;
}

.pagination_div_client_v2 {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  align-self: center;
  background-color: #f6f6f6;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.clientinvestmentcertificate_search_bar_div {
  width: 100%;
}

.clientinvestmentcertificate_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 12%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.clientinvestmentcertificate_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}



.switch_plan_request_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.switch_plan_request_logo_image {
  margin-top: 40px;
  width: 180px;
  margin-left: 2%;
}

.switch_plan_request_page_expire {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.switch_plan_request_div {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
}

.switch_plan_request_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: rgb(218, 218, 218);
}

.switch_plan_request_header_title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(87, 87, 87);
}

.switch_plan_request_header_page_expire_title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: rgb(87, 87, 87);
}

.switch_plan_request_div_container {
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  line-height: 25px;
}

.switch_plan_request_data_title {
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: #000;
}

.switch_plan_request_div_container_2 {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
}

.switch_plan_request_data_subtitle {
  margin: 0;
  padding: 0;
  font-size: 15.5px;
  font-weight: 700;
  margin-left: 10px;
  color: #2f2f2f;
}

.switch_plan_request_button_div {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.switch_plan_request_button {
  color: #fff;
  padding: 12px;
  border: none;
  width: 87%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.otp_info_text_div {
  width: 85%;
  margin-top: 5%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_info_text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: rgb(63, 63, 63);
}

.switch_plan_request_input {
  width: 70%;
  margin-top: 8%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}

.switch_plan_request_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-bottom: 5px;
  width: 74%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switch_plan_request_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

.switch_plan_request_otp_button {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: larger;
  outline: none;
  cursor: pointer;
}

.switch_plan_request_otp_button:hover {
  background-color: var(--blue-hover);
}

@media only screen and (max-width: 1230px) {
  .switch_plan_request_logo_image {
    width: 20%;
  }

  .switch_plan_request_page_expire {
    width: 40%;
  }

  .switch_plan_request_div {
    width: 50%;
  }
}

@media only screen and (max-width: 1100px) {
  .switch_plan_request_logo_image {
    width: 25%;
  }

  .switch_plan_request_page_expire {
    width: 50%;
  }

  .switch_plan_request_div {
    width: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .switch_plan_request_logo_image {
    width: 30%;
  }

  .switch_plan_request_page_expire {
    width: 60%;
  }

  .switch_plan_request_div {
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .switch_plan_request_logo_image {
    width: 35%;
  }

  .switch_plan_request_page_expire {
    width: 70%;
  }

  .switch_plan_request_div {
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .switch_plan_request_logo_image {
    width: 40%;
  }

  .switch_plan_request_page_expire {
    width: 80%;
  }

  .switch_plan_request_div {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .switch_plan_request_logo_image {
    width: 45%;
  }

  .switch_plan_request_page_expire {
    width: 90%;
  }

  .switch_plan_request_div {
    width: 90%;
  }

  .switch_plan_request_data_div2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .switch_plan_request_data_div3 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .switch_plan_request_data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .switch_plan_request_data_title {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .switch_plan_request_data_subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .switch_plan_request_agreement_title {
    font-size: 14px;
  }

  .switch_plan_request_button {
    padding: 12px;
    width: 87%;
    font-size: 16px;
  }
}

.poolclient_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 20px;
}

.poolclient_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.poolclient_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.note {
  font-size: 16px;
    font-weight: 100;
    color: #42474f;
}
.poolclient_header_div_title_container {
  display: flex;
  flex-direction: column;    
  width: 95%;
}

.poolclient_header_div_subtitle {
  margin: 0;
  padding: 0;
  color: #969494;
  font-size: 14px;
}

.poolclient_header_div_subtitle_copy {
  margin: 0;
  padding: 0;
  color: #2e2e2e;
  font-size: 14px;
}

.poolclient_header_div_title {
  margin: 0;
  padding: 0;
  margin-top: 35px;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.poolclient_header_button_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.poolclient_header_button_div_kyc {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid var(--green);
  color: var(--green);
  border-radius: 3px;
  cursor: pointer;
}

.poolclient_header_button_text_kyc {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  color: var(--green);
}

.poolclient_header_button_div_kyc:hover {
  background-color: var(--green);
}

.poolclient_header_button_div_kyc:hover
  .poolclient_header_button_text_kyc {
  color: #fff;
}

.poolclient_header_button_div_net {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 3px;
}

.poolclient_header_button_text_net {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
}

.poolclient_searchbar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.poolclient_searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: 12%;
  height: 25px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.poolclient_searchbar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.poolclient_filtercontainer {
  width: 92%;
  align-self: center;
  align-items: flex-start;
  margin-top: 20px;
}

.poolclient_tab_container {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 20px;
  grid-column-gap: 30px;
  column-gap: 30px;
}

.poolclient_tab_div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-bottom: 10px;
  cursor: pointer;
}

.poolclient_header_main_div{
  display: inline-block;
  padding: 20px 0;
}

.poolclient_header_div_element{
  display: inline-block;
}

.poolclient_tab_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.poolclient_tab_underline {
  height: 3px;
  border-radius: 5px;
  margin-top: 5px;
  overflow: hidden;
}
.tableContainer {
  margin-left: 47px;
  padding: 0;
}
.amountInputField {
  width: 200px;
  height: 35px;
  border: 1px solid;
  border-radius: 4px;
  padding-left: 10px;
}

.client_list_container_button_div {
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: center;
}

.bottom_container_button {
  width: 360px;
  height: 50px;
  background-color: var(--blue);
  padding: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.bottom_container_button:hover {
  background-color: var(--blue-hover);
}

.note_div{
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.clientlist_search_bar_div{
  margin-top: 10px;
}

.clientlist_table_column_div{
  display:flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.withdrawalHistory_div_container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .withdrawalHistory_div_tablecontainer {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 220px;
  }

  .withdrawalHistory_header_div {
    width: 92%;
    align-self: center;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid blue; */
  }

  .withdrawalHistory_header_button_div_container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .withdrawalHistory_header_div_title_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* border: 1px solid brown; */
  }
  
  .withdrawalHistory_header_div_subtitle {
    margin: 0;
    padding: 0;
    color: #969494;
    font-size: 14px;
  }
  
  .withdrawalHistory_header_div_title {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    color: #42474f;
    font-weight: 600;
    font-size: 28px;
    /* border: 1px solid red; */
  }

  .withdrawalHistory_status_text_0 {
    margin: 0;
    padding: 0;
    color: var(--red);
  }

  .withdrawalHistory_client_name {
    margin: 0;
    padding: 0;
    color: var(--blue);
    /* cursor: pointer; */
  }
  
  .withdrawalHistory_status_text_1 {
    margin: 0;
    padding: 0;
    color: var(--green);
  }
.investment_consent_confirmation_div {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  width: 35%;
  margin-bottom: 20px;
  border: #6c6c6c 1px solid;
  overflow: hidden;
  padding-bottom: 55px;
  padding-top: 25px;
  margin-top: 120px;
}

.investment_consent_confirmation_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
}

.investment_consent_confirmation_header_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.investment_consent_confirmation_input {
  width: 70%;
  margin-top: 8%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}

.investment_consent_confirmation_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  width: 74%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20%;
}

.investment_consent_confirmation_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

.investment_consent_confirmation_otp_button {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  font-family: 'Lato';
  margin-top: 40px;
}

.investment_consent_confirmation_otp_button_verify {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  font-family: 'Lato';
  margin-top: 25px;
}

.investment_consent_confirmation_page_expire {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.investment_consent_confirmation_header_page_expire_title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: rgb(87, 87, 87);
}

.investment_consent_confirmation_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.investment_consent_confirmation_logo_image {
  margin-top: 25px;
  width: 180px;
  margin-left: 2%;
  padding-bottom: 25px;
}

.image_container {
  width: 100%;
  align-items: left;
  border-bottom: #DBDBDB 1px solid;
}

.consent_checkbox {
  display: flex;
  flex-direction: row;
  padding-left: 15%;
  padding-right: 15%;
}

.checkbox_label {
  font-size: 11px;
  margin-left: 0px;
  padding-top: 7px;
  color: #323232;
  font-family: 'Poppins';
}

.otp_input {
  padding-left: 20%;
  width: 80%;
}

.consent_buttons {
  display: flex;
  justify-content: center;
}

.consent_success_message {
  text-align: center;
  padding-left: 8%;
  padding-right: 8%;
}

.success_message_body {
  font-size: 16px;
  font-family: 'Asap';
}

.check_mark_image {
  padding-top: 50px;
  padding-bottom: 30px;
}
.forms {
  padding-top: 120px;
  text-align: center;
}

.forms form {
  width: 70%;
  border: 2px solid grey;
  padding: 30px;
}

.forms form img {
  height: 120px;
  margin-left: 90px;
  margin-bottom: 10px;
  display: block;
  border: 1px solid black;
  border-radius: 50%;
}

.forms .form-header .textFieldDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 3px;
}

.forms .form-header .textFieldDiv input {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.forms .form-header .textFieldDiv input:disabled {
  background-color: #ececec;
  color: rgb(119, 119, 119);
}

.forms .form-header .textFieldDiv label {
  display: flex;
  flex-grow: 1;
}

.forms .form-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.forms .form-header .submit {
  border-radius: 6px;
  cursor: pointer;
  font-size: 22px;
  background-color: var(--blue);
  color: #fff;
  width: 100%;
  height: 36px;
  margin-top: 16px;
}

.forms .form-header .submit:disabled {
  border-radius: 6px;
  cursor: not-allowed;
  font-size: 22px;
  border: 0;
  background-color: gray;
  color: lightgray;
  width: 100%;
  height: 36px;
  margin-top: 16px;
}

.title {
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: 500;
  }
  .subtitle {
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 400;
  } 
  
.title {
  font-size: 28px;
  font-family: 'Poppins';
  font-weight: 500;
}
.subtitle {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 400;
} 

