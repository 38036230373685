.input_box_field_container {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
  }

.input_box_input_container {
    margin-top: 12px;
    padding: 12px;
    font-size: large;
    border: 1px solid #494949;
    border-radius: 3px;
}
  
.input_box_container:disabled {
background-color: rgb(247, 247, 247);
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.input_box_label_container {
    margin-bottom: -6px;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: #272727;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    color: #414141;
  }

  .input_box_invalid_text {
    margin: 0;
    padding: 0;
    font-size: medium;
    color: var(--invalid);
  }

  .input_box_invalid_div {
    margin: 0;
    padding: 0;
    /* margin-top: 3px; */
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .input_box_input_container:disabled {
    background-color: #EDEDED;
    color: #1B1B1B;
  }
  @media only screen and (max-width: 550px) {
    .input_box_input_container {
      width: 90%;
    }
  }
  