.auth_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.auth_logo_image {
  width: 180px;
  cursor: pointer;
  margin-left: 2%;
}

.client_kyc_link_main_container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.client_kyc_link_container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.client_kyc_link_body_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.clientkyclink_main_container_main_title {
  flex: 2.3;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 30px;
}

.clientkyclink_main_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-left: 15vw;
}

.clientkyclink_main_container_div1 {
  margin-top: 10vh;
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.kyc_link_button_container {
  margin-left: 15vw;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 70%;
}

.kyc_link_checkbox_container {
  margin-left: 15vw;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 70%;
}

.kyc_link_submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 41%;
  background-color: var(--green);
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_link_submit_button:hover {
  background-color: var(--green-hover);
}

.clientkyc_user_exist_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clientkyc_user_exist_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #414141;
}

.clientkyc_user_exist_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_user_exist_button:hover {
  background-color: var(--blue-hover);
}

@media only screen and (max-width: 550px) {
  .clientkyclink_main_container_div1 {
    width: 75%;
  }
  .client_kyc_link_container {
    width: 100%;
  }
  .clientkyclink_main_container {
    display: flex;
    width: 98%;
    margin-left: 1vw;
  }

  .auth_logo_image {
    width: 200px;
    height: auto;
    cursor: pointer;
  }

  .clientkyclink_main_container_main_title {
    margin-left: 0vw;
  }
}

.client_tc_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.thank_you_main_container {
  width: 450px;
  border-radius: 5px;
  border: 1px solid #50cd89;
  border-radius: 15px;
  margin-left: 500px;
  margin-top: 150px;
  position: fixed;
  padding: 40px;
}
.thank_you_check_icon {
  margin-left: 150px;
  margin-top: 10px;
}

.thank_you_text {
  margin-left: 90px;
}
.thank_you_subtext {
  margin-left: 10px;
}
