.pagenotfound_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pagenotfound_rest_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
  align-items: center;
}

.pagenotfound_container_title {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 100px;
  letter-spacing: 10px;
  color: var(--blue);
  text-align: center;
}

.pagenotfound_container_subtitle {
  margin-top: 10px;
  font-size: 25px;
  color: var(--gray);
  text-align: center;
}
