.item1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;
    grid-template-rows: repeat(3, 1fr);
    margin-right:20%
  }
  
  .item2 {
    width: 120px;
    min-height: 120px;
    max-height: auto;
    float: left;
    margin: 3px;
    padding: 3px;
}

.container { 
  position: static;
  /* max-width: 33%; */
  border: 6px solid #0000000d; 
  padding:5%;
  /* border:20%; */
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 25px;
  /* edit */
  display: flex;
  justify-content: space-between;
  height: 250px;
  width:330px;
  /* align-items: center; */
  
}
.container .containertext {
  background-color: #f0f0f0; 
  padding:5%;
  border:2%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top:3%;
  border-radius: 15px;
  height: 190px;
  width:280px;
  font-size:medium;
  overflow: auto;
  align-self: center;
}

.container img { 
  display: inline-block;
  /* margin-left: auto; */
  /* display: flex; */
  border-radius: 25px;
  margin-bottom: auto;
  /* margin-bottom: 100px; */
  /* padding:3% */
  /* float:right; */
  /* padding:1% */
  
 }

.container .msg{
  display: inline-block;
  justify-content: space-between;
  float:left;
  /* padding:3%; */
  margin-bottom: auto;
  margin-right: 10%;
  margin-left: 2.2%;
  /* padding:1% */

}
.container .fa-download { 
  background-color: transparent;
  border: none;
  cursor: pointer;
  float:right;
  margin-left: 16%;
}

.label{
  display : flex;
  justify-content: space-between;
  padding:5%;
}