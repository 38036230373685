.pool_clientlist_model_div {
  display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 16.5%;
    margin-top: 170px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
}

.pool_clientlist_model_title_div {
  display: flex;
    width: 93%;
    flex-direction: row;
    align-content: space-around;
    align-items: center;
    padding-top: 21px;
    padding-bottom: 21px;
    padding-left: 40px;
    justify-content: space-between;
}

.pool_client_list_container_button_div {
  width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    display: flex;
    align-self: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: #faf9f9;
    box-shadow: 0px -2px 7px -1px #6b6b6b40;
}

.pool_client_add_more_section {
  color: #4A88F7;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 0px 40px 37px;
}

.pool_client_bottom_container_button{
  width: 280px;
  height: 50px;
  background-color: var(--blue);
  padding: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pool_client_bottom_container_button:hover {
  background-color: var(--blue-hover);
}

.pool_clientlist_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 20px;
}

.pool_clientlist_search_bar_div {
  margin-top: 15px;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.tool_tip {
  display: flex;
  width: 38%;
  justify-content: space-between;
}

.pool_clientlist_search_bar {
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  width: 20%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
}

.pool_clientlist_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.pool_clientlist_table_div {
  flex: 1;
  width: 100%;
  /* padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px; */
}

.pool_clientlist_action_button_invest_disabled {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid #a9a9a9;
  background-color: #fff;
  color: #a9a9a9;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.pool_clientlist_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.pool_clientlist_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.pool_clientlist_kyc_status_text {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  color: var(--orange);
  font-size: 11px;
}
