.faq_div_container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.faq_main_div{
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;
}

.faq_title{
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.faq_title_text{
  font-weight: 500;
}
.faq_style_wrapper{
  width : 70%;
  margin-top: 30px;
}

.faq_style_wrapper .faq-body .faq-row .row-content .row-content-text{
    font-size: medium !important;
    line-height: 1.5em !important;
}

.faq_style_wrapper .faq-body .faq-row .row-content{
  width: 95%;
  text-align: justify;
}