.clientkyc_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientkyc_main_div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-left: 220px;
  padding-bottom: 20px;
  overflow: hidden;
}

.clientkyc_top_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.clientkyc_top_row_container {
  width: 56%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientkyc_top_row_back {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
}

.clientkyc_main_container_main_title {
  flex: 2.3;
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 30px;
}

.clientkyc_main_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
}

.clientkyc_main_container_div1 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_div2 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_title {
  margin: 0;
  padding: 0;
  color: var(--blue);
  font-weight: 600;
  font-size: 22px;
}

.clientkyc_main_input_form {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.clientkyc_main_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 12px;
}

.clientkyc_main_input_field {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.clientkyc_main_input_field:disabled {
  background-color: #ececec;
  color: rgb(119, 119, 119);
}

.clientkyc_main_image_div_container {
  display: flex;
  height: 45px;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.clientkyc_main_image_div {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  border: none;
}

.clientkyc_main_image_text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.clientkyc_main_image_button {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  background-color: var(--blue);
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
}

.clientkyc_main_image_button:hover {
  background-color: var(--blue-hover);
}

.kyc_button_container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 70%;
}

.kyc_submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 50%;
  background-color: var(--green);
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_submit_button:hover {
  background-color: var(--green-hover);
}

@media only screen and (max-width: 550px) {
  .clientkyc_main_image_button {
    width: 28%;
  }
}

.clientkyc_ckyc_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_user_exist_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clientkyc_user_exist_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #414141;
}

.clientkyc_user_exist_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_user_exist_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_enable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button_pan_enable:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_disable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: #b9b9b9;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: not-allowed;
  border-radius: 3px;
}

.clientkyc_gender_input {
  padding-left: 15px;
}

.clientkyc_gender_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 3px;
}

/* Page not Found */
.clientpagenotfound_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientpagenotfound_rest_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.clientpagenotfound_container_title {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 100px;
  letter-spacing: 10px;
  color: var(--blue);
  text-align: center;
}

.clientpagenotfound_container_subtitle {
  margin-top: 10px;
  font-size: 25px;
  color: var(--gray);
  text-align: center;
}

.client_tc_header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.client_tc_logo_image {
  width: 180px;
  cursor: pointer;
  margin-left: 2%;
}

.client_thank_page_container {
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 728px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #50cd89;
  border-radius: 25px;
}

.client_thank_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.client_thank_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 100px;
  padding-top: 35px;
}

.client_thank_main_title h3 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height */
  color: #2f2f2f;
}
