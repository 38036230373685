.client_freedom_plan_div_container {
  display: flex;
  flex-direction: column;
  margin :20px 0;
}

.client_freedom_card{
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1.5px solid #E4E4E4;
  background: #F4F4F4;
  justify-content: space-between;
  padding:20px 10px;
}

.client_freedom_card_content_container{
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.client_freedom_card_button_container{
   display: flex;
   margin: 20px 20px;
   align-items: center;
}

.client_freedom_card_content{
  padding: 20px;
  margin:20px 10px;
  background: rgba(255, 255, 255, 0.80);
}

.client_freedom_card_content_title{
  color: #757575;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.client_freedom_card_content_value{
  color: #2E2E2E;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top:10px;
}

.client_freedom_details_investment_main_header_button {
  width: 220px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #4A88F7;
  box-shadow: 0px 4px 4px 0px rgba(242, 242, 242, 0.25);
  color:#4A88F7;
  margin:0 10px;
}

.client_freedom_investment_button{
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #4A88F7;
  color: #FFF;
  border: none;
  margin: 0 10px;
  cursor: pointer;
}

.client_freedom_header {
  padding: 2% 2% 2% 2%;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.client_freedom_header_left_grid {
  display: grid;
  justify-content: left;
  align-self: left;
  grid-template-columns: 4% 80%;
}

.client_freedom_header_left_grid_text {
  text-align: left;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.client_freedom_details_summary {
  display: grid;
  padding: 1% ;
  padding-left: 5%;
  padding-right: 5%;
  grid-template-columns: 50% 50%;
  text-align: left;
}

.client_freedom_details_summary_header_text{
  font-size:14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom:0;
  padding-bottom:0;
}

.client_freedom_details_summary_value_text{
  font-size:22px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0E0E0E;
  margin-top:0;
  padding-top:0;
}

.client_freedom_details_investment{
  padding: 2%;
  padding-left: 5%;
  padding-right: 5%;
}

.client_freedom_details_investment_main_header{
  margin-bottom: 8vh;
  justify-content: center;
  align-self: center;
}

.client_freedom_details_investment_main_header_text{
  font-size:16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #232323;
}

.client_freedom_details_investment_grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%; 
  text-align: left;
}

.client_freedom_details_investment_header_text{
  font-size:14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom:0;
  padding-bottom:0;
}

.client_freedom_details_investment_value_text{
  font-size:14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0E0E0E;
  margin-top:0;
  padding-top:0;
}

.client_freedom_plan_earning_button{
  background-color: transparent;
  width: 160px;
  height: 36px;
  border: 1.3px solid #3A86FF;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
  text-align: center; 
}

.client_freedom_plan_earning_button_text{
  color: #3A86FF;
  font-size: 20px;
  font-weight: 500;
  align-self: center;
}

@media screen and (max-width: 1500px) {
  .client_freedom_details_summary_header_text{
    font-size:11px;
  }
  .client_freedom_details_summary_value_text{
    font-size:16px;
  }

  .client_freedom_header_left_grid_text {
    font-size: 17px;
  }
  }
