.support_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.support_main_div {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.support_header {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.support_image {
  width: 35%;
}

.support_form_div {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.support_form_container {
  min-width: 35%;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 30px;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.support_form_container_div {
  width: 90%;
  display: flex;
  align-self: center;
}

.support_form_container_title {
  margin: 0;
  padding: 0;
  color: #494949;
  font-size: 20px;
  font-weight: 600;
}

.support_input_box_subject {
  width: 85%;
  resize: none;
  margin-top: 15px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #494949;
  border-radius: 3px;
}

.support_input_box_message {
  width: 85%;
  height: 150px;
  resize: none;
  margin-top: 15px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
}

.support_input_box_subject::placeholder {
  color: #838383;
}

.support_input_box_message::placeholder {
  color: #838383;
}

.support_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 15px;
  padding: 12px;
  border: none;
  width: 90%;
  font-size: 16px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.contact_box {
  min-width: 35%;
  padding: 15px;
  padding-top: 15px;
  padding-bottom: 20px;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 15px;
}

.support_submit_container:hover {
  background-color: var(--blue-hover);
}

.support_subject {
  /* border: 1px solid #494949;
  border-radius: 3px;
  border-top-width: 1px ; */
  margin-top: 1%;
  width: 90%;
  padding: 12px;
}

.support_subject:hover {
  cursor: pointer;
  color: black;
  border-width: 2px;
}

.support_contact {
  color: #494949;
  margin-left: 50px;
  font-weight: 400;
}

.support_icon {
  margin-right: 10px;
}

.details {
  margin-top: 20px;
  display: flex;
  align-self: center;
  flex-direction: row;
  width: 90%;
  align-items: center;
}
.phone_icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  text-align: center;
  font-size: 15px;
  color: #494949;
}

.email_icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  color: #494949;
}

@media  (max-width: 1400px) {
  .details {
    flex-direction: column;
    align-items: baseline;
  }
  
}

@media (max-width: 1000px) {
  .support_subject {
    width: 95%;
  }  
}

@media (max-width: 760px) {
  .support_subject {
    width: 100%;
  }  
  .contact_box {
    padding-left: 0px;
    padding-right: 10px;
  }
}