.step_circle {
  width: 26px;
  height: 27px;
  line-height: 25px;
  background-color: #D9D9D9;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
  margin: auto;
  /* margin-bottom: 16%; */
}

.stepBlock img{
  width: 27px;
  height: 27px;
  line-height: 25px;
  background-color: #D9D9D9;
  color: white;
  border-radius: 50%;
}
.stepBlock {
  /* padding: 0px 50px; */
  text-align: center;
}

.circleWrapper {
  margin-bottom: 25px;
  text-align: center;
}


.stepper{
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 30px;
}

.selected .step_circle {
    color: black;
    width: 12px;
    height: 13px;
    border-width: 9px;
    background-color: white;
    border-color: #3A86FF;
}

.disableText{
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  color: #ADADAD
}
  

span {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  color: #151515;
}


  .stepWrapper {
    display: flex;
    margin-bottom: 20px;
    margin-top: -25px;
    justify-content: space-around;
    z-index: -1;
  }
  
  .stepBlock .circleWrapper {
    padding: 0px 20px;
    position: relative;
    width: 80px;
  }

/* 
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 26%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 135%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 26%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}

@media only screen and (min-width: 1500px) and (max-width: 2000px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 170%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 26%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}
@media only screen and (min-width: 6000px) and (max-width: 8000px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 755%;
    height: 2px;
    position: absolute;
    top: 10px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 0%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}
@media only screen and (min-width: 5001px) and (max-width: 6000px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 550%;
    height: 2px;
    position: absolute;
    top: 10px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 20%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}
@media only screen and (min-width: 3500px) and (max-width: 5000px) {
  .stepBlock:not(:last-child) > .circleWrapper::before {
    content: '';
    width: 760%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }
  .stepBlock:not(:first-child) > .circleWrapper::before {
    content: '';
    width: 0%;
    height: 2px;
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    margin-right: 100px;
    z-index: -1;
  }
}
@media only screen and (min-width: 3000px) and (max-width: 3500px) {
  .stepBlock:not(:last-child) > .circleWrapper::before {
    content: '';
    width: 640%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }
  .stepBlock:not(:first-child) > .circleWrapper::before {
    content: '';
    width: 0%;
    height: 2px;
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    margin-right: 100px;
    z-index: -1;
  }
}

@media only screen and (min-width: 2645px) and (max-width: 3000px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 280%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 0%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}
@media only screen and (min-width: 2250px) and (max-width: 2644px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 250%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 0%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}

@media only screen and (min-width: 2000px) and (max-width: 2250px) {
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 200%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
  }

 .stepBlock:not(:first-child) > .circleWrapper::before {
  content: '';
  width: 26%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #D9D9D9;
  margin: auto;
  margin-right: 100px;
  z-index: -1;
}
}

@media only screen and (min-width: 6001px) and (max-width: 8000px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 570%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}
@media only screen and (min-width: 5001px) and (max-width: 6000px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 410%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}
@media only screen and (min-width: 3500px) and (max-width: 5000px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 300%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}
@media only screen and (min-width: 2645px) and (max-width: 3000px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 220%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }
}
@media only screen and (min-width: 2250px) and (max-width: 2644px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 185%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}
@media only screen and (min-width: 2000px) and (max-width: 2250px){
  .stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
      content: '';
      width: 150%;
      height: 2px;
      position: absolute;
      top: 0px;
      margin-left: 0.5rem;
      bottom: 0px;
      background-color: #D9D9D9;
      margin: auto;
      z-index: -1;
  }}

@media only screen and (min-width: 1500px) and (max-width: 2000px){
.stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
    content: '';
    width: 120%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
}}


@media only screen and (min-width: 1190px) and (max-width: 1500px){
.stepWrapper:has(#step3) > .stepBlock:not(#step3) > .circleWrapper::after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0px;
    margin-left: 0.5rem;
    bottom: 0px;
    background-color: #D9D9D9;
    margin: auto;
    z-index: -1;
}
} */