.account_creation_form_container {
  width: 100%;
  margin-top: 20px;
}

.account_creation_gst_toggle_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.account_creation_field_container {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
}

.account_creation_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.account_creation_input_box_flex_container {
  display: flex;
  align-items: center;
}

.account_creation_footer {
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.account_creation_footer_text {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #6b6b6b;
  font-weight: 500;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

.account_creation_text_link {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: var(--blue);
  font-weight: 500;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  margin-left: 5px;
}
