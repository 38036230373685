.consent_request_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    padding-bottom: 50px;
    width: 40%
  }

  .consent_request_model_header_title{
    padding: 2.5vh;
    margin: 0;
    color: #343434;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 20px;
  }
  .consent_request_model_header{
    background-color: #F2F2F2;
    padding:0;
    align-items: left;
    text-align: left;
  }
  .consent_request_model_body{
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    line-height: 24.36px;
    padding: 30px 50px 10px 50px;
  }
  .consent_request_model_list{
    font-family: "Inter";
    margin-top: 10px;
  }
  .consent_request_model_button {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 250px;
    height: 45px;
    margin-top: 40px;
    background-color: var(--blue);
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }