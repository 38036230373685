.add_client_pan_not_verified_container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    border: 1px solid rgb(219, 219, 219);
    align-items: center;
    width: 65%;
    padding-top: 90px;
    height: 300px;
  }
  
  .add_client_pan_not_verified_text{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    margin-top: 20px;
  }
  
  .add_client_pan_not_verified_support_text{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 0;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    margin: 0;
  }

  .add_client_pan_not_verified_support_email{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 10px;
    padding: 0;
    color: #064586;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
    text-decoration: none;
  }

  .add_client_pan_not_verified_support{
    margin-top: 25px;
  }