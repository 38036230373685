.client_freedom_plan_error_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  padding-bottom: 50px;
  margin-left: 2vw;
  margin-right: 2vw;
  width: 50vw;
}

