.resend_otp_container {
    margin-bottom: -6px;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: #272727;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    color: #414141;
  }

  .resend_otp_label_container {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: #272727;
  }