.auth_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.auth_logo_image {
  width: 180px;
  cursor: pointer;
  margin-left: 2%;
}

.client_kyc_link_main_container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.client_kyc_link_container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.client_kyc_link_body_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.clientkyclink_main_container_main_title {
  flex: 2.3;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  width: 500px;
  text-align: center;
  line-height: 27px;
}
.clientkyclink_main_container_title {
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 30px;
}
.clientkyclink_main_container_1 {
  display: flex;
  width: 60%;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.clientkyclink_main_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-left: 15vw;
}

.clientkyclink_main_container_div2 {
  /* margin-top: 10vh; */
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}
.clientkyc_main_container_title_1 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  color: #3f3f3f;
}

.kyc_link_button_container {
  margin-left: 15vw;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 70%;
}

.kyc_link_checkbox_container {
  margin-top: 30px;
  margin-left: 15vw;
  margin-bottom: 20px;
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}

.kyc_link_submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 41%;
  background-color: var(--green);
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_link_submit_button:hover {
  background-color: var(--green-hover);
}

.clientkyc_user_exist_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clientkyc_user_exist_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #414141;
}

.clientkyc_user_exist_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}
.clientkyclink_main_container_div1 {
  margin-top: 1vh;
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}
.clientkyc_user_exist_button:hover {
  background-color: var(--blue-hover);
}
.thank_you_main_container_new {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #50cd89;
  border-radius: 15px;
  position: fixed;
  padding: 40px;
  margin: 20%;
}

@media only screen and (max-width: 550px) {
  .clientkyclink_main_container_div1 {
    width: 75%;
  }
  .kyc_link_checkbox_container{
    width: 75%;
  }
  .client_kyc_link_container {
    width: 100%;
  }
  .clientkyclink_main_container {
    display: flex;
    width: 98%;
    margin-left: 1vw;
  }

  .auth_logo_image {
    width: 200px;
    height: auto;
    cursor: pointer;
  }

  .clientkyclink_main_container_main_title {
    margin-left: 0vw;
    width: 300px;
  }
  .clientkyclink_main_container_1 {
    width: 100%;
  }
  .clientkyclink_main_container_div2 {
    padding: 30px;
    width: 75%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid rgb(219, 219, 219);
  }
  .thank_you_main_container_new {
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #50cd89;
    border-radius: 15px;
    margin-left: 10%;
    margin-top: 200px;
    position: fixed;
    padding: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .clientkyclink_main_container_main_title {
    margin-left: 0vw;
    width: 400px;
  }
  .clientkyclink_main_container_1 {
    width: 80%;
  }
}
.client_tc_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
}


.thank_you_check_icon_new {
  /* margin-left: 150px; */
  margin-top: 10px;
}

.thank_you_text_new {
  margin-top: 10px;
}
.thank_you_subtext_new {
  /* margin-left: 10px; */
  width: 90%;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
}

.nominee_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
}
.skip_button {
  cursor: pointer;
  color: #3a86ff;
  text-decoration: underline;
}
.client_kyc_link_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.add_client_link_entity_subtext {
  margin-top: 5px;
  color: #4d4d4d;
  font-family: Asap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.add_client_kyc_link_main_submit_cta {
  padding: 10px;
  width: 53%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background-color: #3a86ff;
  margin-top: 20px;
  cursor: pointer;
}
.add_client_kyc_link_basic_details_cta_text {
  font-size: 18px;
  padding: 0;
  line-height: 21px;
  font-weight: 500;
  color: #ffffff;
}
.kyc_link_checkbox_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5px;
}
.kyc_link_consent_text {
  font-family: Poppins;
    font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  width: inherit;
}
.kyc_link_consent_checked {
  height: 16px;
  width: 16px;
  background-color: #064586;
  border-radius: 1px;
  margin-right: 2%;
  cursor: pointer;
}
.kyc_link_consent_unchecked {
  height: 12px;
  width: 12px;
  border: 2px solid #686868;
  cursor: pointer;
  border-radius: 1px;
  margin-right: 2%;
}
#address_toggle_label2 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  overflow: hidden;
  white-space: initial;
  padding-left: 0;
  margin-left: 0;
}
