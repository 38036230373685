.poolclient_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 20px;
}

.poolclient_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.poolclient_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.note {
  font-size: 16px;
    font-weight: 100;
    color: #42474f;
}
.poolclient_header_div_title_container {
  display: flex;
  flex-direction: column;    
  width: 95%;
}

.poolclient_header_div_subtitle {
  margin: 0;
  padding: 0;
  color: #969494;
  font-size: 14px;
}

.poolclient_header_div_subtitle_copy {
  margin: 0;
  padding: 0;
  color: #2e2e2e;
  font-size: 14px;
}

.poolclient_header_div_title {
  margin: 0;
  padding: 0;
  margin-top: 35px;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.poolclient_header_button_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.poolclient_header_button_div_kyc {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid var(--green);
  color: var(--green);
  border-radius: 3px;
  cursor: pointer;
}

.poolclient_header_button_text_kyc {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  color: var(--green);
}

.poolclient_header_button_div_kyc:hover {
  background-color: var(--green);
}

.poolclient_header_button_div_kyc:hover
  .poolclient_header_button_text_kyc {
  color: #fff;
}

.poolclient_header_button_div_net {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 3px;
}

.poolclient_header_button_text_net {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
}

.poolclient_searchbar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.poolclient_searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: 12%;
  height: 25px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.poolclient_searchbar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.poolclient_filtercontainer {
  width: 92%;
  align-self: center;
  align-items: flex-start;
  margin-top: 20px;
}

.poolclient_tab_container {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 20px;
  column-gap: 30px;
}

.poolclient_tab_div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-bottom: 10px;
  cursor: pointer;
}

.poolclient_header_main_div{
  display: inline-block;
  padding: 20px 0;
}

.poolclient_header_div_element{
  display: inline-block;
}

.poolclient_tab_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.poolclient_tab_underline {
  height: 3px;
  border-radius: 5px;
  margin-top: 5px;
  overflow: hidden;
}
.tableContainer {
  margin-left: 47px;
  padding: 0;
}
.amountInputField {
  width: 200px;
  height: 35px;
  border: 1px solid;
  border-radius: 4px;
  padding-left: 10px;
}

.client_list_container_button_div {
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: center;
}

.bottom_container_button {
  width: 360px;
  height: 50px;
  background-color: var(--blue);
  padding: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.bottom_container_button:hover {
  background-color: var(--blue-hover);
}

.note_div{
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.clientlist_search_bar_div{
  margin-top: 10px;
}

.clientlist_table_column_div{
  display:flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
