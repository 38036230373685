.add_client_kyc_entity_main_container_title {
  display: flex;
  flex-direction: column;
}

.add_client_entity_subtext {
  margin-top: 5px;
  color: #4d4d4d;
  font-family: Asap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.beneficial_owner_tile {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size:18px;
  line-height: 21px;
}

#beneficial_owner_subtext{
  margin: 0;
  margin-top: 12px;
  margin-bottom: 40px;
  padding: 0;
  color: rgb(105, 105, 105);
}

#beneficial_owner_subtitle{
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
.add_benefifcial_button{
  float: right;
  padding: 10px;
  width: 30%;
  border-radius: 3px;
  margin-right: 0;
  border: none;
  margin-left: 70%;
  background-color: #3a86ff;
  color: #FFFFFF;
 cursor: pointer;
}