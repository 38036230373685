.pool_list_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 100px;
  margin-left: 220px;
}

.pool_list_heading_container {
  display: flex;
  flex-direction: column;
  width: 92%;
  align-self: center;
  margin-top: 30px;
}

.pool_list_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.pool_list_content{
  margin-top: 30px;
  width: 92%;
  align-self: center;
}


