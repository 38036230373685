.marketitem {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: dense;
    grid-template-rows: repeat(5, 1fr);
    margin-right:8%;
    margin-left:5%;
  }


.marketcontainer { 
  position: static;
  border: 1.5px solid hsla(0, 0%, 39%, 0.835); 
  padding:5%;
  border-radius: 25px;
  height:75%;
  width:80%;
  display: flex;
  justify-content: center;
 
}

.marketcontainer img { 
  display: inline-block; 
  margin-left: auto;
  border-radius: 25px;
  height: 10%;
  width:10%;
 }
 .marketcontainer object { 
  display: static;
  border-radius: 25px;
  height: 80%;
  width:100%;
  /* margin-bottom: 5px; */
  align-self: center;
  border:5%;
  object-fit: cover;
 }
.marketcontainer .fa-download { 
  background-color: transparent;
  border: none;
  cursor: pointer;
  float: right;
  margin-top:5%
  }
.marketcontainer .marketleft {
  justify-content: space-between;
  float:left;
  margin-left: auto;
  color: hsla(0, 0%, 39%, 0.835); 
  margin-top:5%;
  font-weight: 700;
} 
  
.marketlabel{
  display : flex;
  justify-content: space-between;
}
.index-label {
  margin-right: 10px;
  font-weight: bold;
}
