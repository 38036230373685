.signup_submit_container {
    background-color: var(--blue);
    color: #fff;
    margin-top: 12px;
    padding: 12px;
    border: none;
    width: 100%;
    font-size: 18px;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
  }

  .signup_field_container {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
  }