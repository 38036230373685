.withdrawal_confirmation_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.withdrawal_confirmation_logo_image {
  margin-top: 40px;
  width: 180px;
  margin-left: 2%;
}

.withdrawal_confirmation_page_expire {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.withdrawal_confirmation_div {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
}

.withdrawal_confirmation_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: rgb(218, 218, 218);
}

.withdrawal_confirmation_header_title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(87, 87, 87);
}

.withdrawal_confirmation_header_page_expire_title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: rgb(87, 87, 87);
}

.withdrawal_confirmation_div_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgb(233, 232, 232);
}

.withdrawal_confirmation_data_div1 {
  width: 93%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
}

.withdrawal_confirmation_data_div2 {
  width: 93%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.withdrawal_confirmation_data_div3 {
  width: 93%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.withdrawal_confirmation_data {
  display: flex;
  flex-direction: column;
}

.withdrawal_confirmation_data_title {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 100;
  color: #000;
}

.withdrawal_confirmation_data_subtitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: #2f2f2f;
}

.withdrawal_confirmation_data_div4 {
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.withdrawal_confirmation_data_div5 {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 33% 33%;
  text-align: left;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 20px;
  
}

.withdrawal_confirmation_button {
  color: #fff;
  padding: 12px;
  border: none;
  width: 87%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.withdrawal_confirmation_agreement {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.withdrawal_confirmation_agreement_title {
  font-size: 16px;
  font-weight: 300;
  margin-left: 4px;
  padding: 0;
  color: rgb(63, 63, 63);
}

.agreement_link {
  color: var(--blue);
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: none;
}

.otp_info_text_div {
  width: 85%;
  margin-top: 5%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_info_text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: rgb(63, 63, 63);
}

.withdrawal_confirmation_input {
  width: 70%;
  margin-top: 8%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}

.withdrawal_confirmation_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-bottom: 5px;
  width: 74%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.withdrawal_confirmation_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

.withdrawal_confirmation_otp_button {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: larger;
  outline: none;
  cursor: pointer;
}

.withdrawal_confirmation_otp_button:hover {
  background-color: var(--blue-hover);
}

@media only screen and (max-width: 1230px) {
  .withdrawal_confirmation_logo_image {
    width: 20%;
  }

  .withdrawal_confirmation_page_expire {
    width: 40%;
  }

  .withdrawal_confirmation_div {
    width: 50%;
  }
}

@media only screen and (max-width: 1100px) {
  .withdrawal_confirmation_logo_image {
    width: 25%;
  }

  .withdrawal_confirmation_page_expire {
    width: 50%;
  }

  .withdrawal_confirmation_div {
    width: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .withdrawal_confirmation_logo_image {
    width: 30%;
  }

  .withdrawal_confirmation_page_expire {
    width: 60%;
  }

  .withdrawal_confirmation_div {
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .withdrawal_confirmation_logo_image {
    width: 35%;
  }

  .withdrawal_confirmation_page_expire {
    width: 70%;
  }

  .withdrawal_confirmation_div {
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .withdrawal_confirmation_logo_image {
    width: 40%;
  }

  .withdrawal_confirmation_page_expire {
    width: 80%;
  }

  .withdrawal_confirmation_div {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .withdrawal_confirmation_logo_image {
    width: 45%;
  }

  .withdrawal_confirmation_page_expire {
    width: 90%;
  }

  .withdrawal_confirmation_div {
    width: 90%;
  }

  .withdrawal_confirmation_data_div2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .withdrawal_confirmation_data_div3 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .withdrawal_confirmation_data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .withdrawal_confirmation_data_title {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .withdrawal_confirmation_data_subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .withdrawal_confirmation_agreement_title {
    font-size: 14px;
  }

  .withdrawal_confirmation_button {
    padding: 12px;
    width: 87%;
    font-size: 16px;
  }
}
