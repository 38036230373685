.client_earnings_model_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  padding-bottom: 50px;
  margin-left: 2vw;
  margin-right: 2vw;
  width: 50vw;
}

.client_earnings_model_header_title {
  padding: 2.5vh;
  padding-left: 2.5vw;
  margin: 0;
  color: #343434;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.client_earnings_model_main_container_div {
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vh;
  max-height: 40vh;
  overflow-y: scroll;
}

.client_earnings_model_main_div {
  display: grid;
  grid-template-columns: 27% 27% 27%;
  grid-gap: 10.5%;
  text-align: left;
  padding: 1vh 1vw 1vh 1vw;
  margin-bottom: 1vh;
  background-color: #F9F9F9;
}

.client_earnings_model_main_value_div {
  display: grid;
  grid-template-columns: 27% 27% 27%;
  grid-gap: 10.5%;
  text-align: left;
  padding: 1vh 1vw 1vh 1vw;
}


.client_earnings_model_line_div {
  padding-bottom: 1.5vh;
}

.client_earnings_model_header {
  background-color: #f2f2f2;
  margin-bottom: 30px;
  padding: 0;
  align-items: left;
  text-align: left;
}


.client_earnings_model_title {
  margin: 0px 0px 0px 0px;
  padding: 0;
  color: #3E3E3E;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 14px;
}

.client_earnings_model_title_value {
  margin: 0;
  padding: 0;
  color: #3F3F3F;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.client_earnings_model_input {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  font-size: large;
  border-radius: 5px;
  border: 1px solid #424242;
}

.client_earnings_model_button {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 230px;
  height: 50px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Lato", sans-serif;
}

.client_earnings_model_button:hover {
  background-color: var(--blue-hover);
}

.arrow_downward_icon_outer_circle_div {
  width: 25px;
  height: 25px;
  background-color: #064586;
  border-radius: 50%;
  position: relative;
}

.arrow_downward_icon_div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.client_earnings_model_line_div {
  padding-bottom: 1.5vh;
}

@media screen and (max-width: 850px) {
  .client_earnings_model_div {
    width: 90vw;
  }

  .client_earnings_model_info {
    text-align: center;
  }
}
