.poollist_column_container {
  width: 100%; /* Set the container to full width */
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create three equal columns */
  gap: 30px; /* Add a gap between columns */
  align-self: center;
  padding-bottom: 5px;
}

.pool_container {
  width: 100%-1px;
  overflow: hidden;
  border: 1px solid #E4E4E4;
  border-radius: 8px 8px 8px 8px;
}

.pool_logo_and_title_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 4px;
  border-radius: 3px;
}

.pool_logo_image_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.pool_logo_image {
  width: 26px;
}

.pool_title {
  color: #323232;
  font-weight: bold;
  font-size: 15px;
  margin-left: 10px;
  text-align: center;
}

.pool_middle_container {
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
}

.pool_top_container {
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
  padding:20px;
  border-bottom: 1px solid #E4E4E4;
}
.pool_top_element{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F6F6F6;
  align-items: baseline;
}
.pool_top_subtitle{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  font-size: 16px;
  font-weight: 400;

}
.pool_top_title{
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: #757575;
}
.pool_bottom_container_button_div {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 10px 0;
}

.pool_riskrate_container {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pool_riskrate_container_title {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.pool_riskrate_container_text {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  margin-left: 10px;
}

.pool_bottom_container {
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
  padding:20px;
}
.pool_bottom_element{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F6F6F6;
}
.pool_bottom_subtitle{
  margin: 0;
  padding: 10px 0;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
}
.pool_bottom_title{
  margin: 0;
  padding: 10px 0;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.pool_bottom_container_button_div {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 20px 0;
}

.pool_bottom_container_button {
  width: 100%;
  background-color: var(--blue);
  padding: 12px;
  color: #fff;
  font-size: large;
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pool_bottom_container_button:hover {
  background-color: var(--blue-hover);
}

.pool_bottom_container_view_more_div {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.pool_view_more_text {
  margin: 0;
  padding: 0;
  color: var(--blue);
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.pool_more_details {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  margin-top: 20px;
  border-top: 1px solid #cfcfcf;
}

.pool_more_details_title {
  margin: 0;
  padding: 0;
  color: #323232;
  font-weight: 700;
  font-size: 14px;
}

.pool_more_details_subtitle {
  margin: 0;
  margin-top: 10px;
  padding: 0;
  color: #323232;
  font-weight: 300;
  font-size: 13px;
}

.pool_more_partner_title {
  margin: 0;
  padding: 0;
  color: #323232;
  font-weight: 700;
  font-size: 13px;
}

.pool_container_warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(237, 129, 52, 0.09);
  padding: 10px;
  border-radius: 3px;
}

.pool_container_warning_text {
  margin: 0;
  padding: 0;
  color: #ed8134;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.pool_template_card{
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  border: 1.5px solid #E4E4E4;
  background: #F4F4F4;
  padding: 40px 30px;
}

.pool_template_card_content_container{
  display: flex;
  flex:70%;
  flex-direction: row;
}

.pool_template_card_button_container{
   display: flex;
   flex:30%;
   align-items: center;
   justify-content: flex-end;
}

.pool_template_card_content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  margin:0 10px;
  background: rgba(255, 255, 255, 0.80);
}

.pool_template_card_content_title{
  color: #757575;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 0;
}

.pool_template_card_content_value{
  display: flex;
  flex-direction: row;
  color: #2E2E2E;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding:5px 0;
}

.pool_template_details_investment_main_header_button {
  width: 170px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #4A88F7;
  box-shadow: 0px 4px 4px 0px rgba(242, 242, 242, 0.25);
  color:#4A88F7;
  margin:0 10px;
}

.pool_template_investment_button{
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #4A88F7;
  color: #FFF;
  border: none;
  margin: 0 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}
