.input_container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.file_input_container {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}

.file_input_title {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
  margin-bottom: 15px;
}

.file_input_uploaded {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.file_input_upload {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  justify-content: center;
  float: left;
  color: grey;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.file_input_button {
  margin-top: 1%;
  width: 25%;
  margin-left: 5%;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  border-color: white;
  line-height: 18px;
  background: var(--blue);
  color: #ffffff;
  border-radius: 4px;
}

.file_placeholder{
  color: #494949;
}

.file_placeholder_uploaded{
  color: black;
}

.file_input_info_text_container{
  margin-top:10px;
}