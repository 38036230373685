.amount_locked_model_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  padding-bottom: 50px;
  width: 610px;
  height: 250px;
  flex-shrink: 0;
}

.amount_locked_model_header_title {
  display: flex;
  padding: 2.5vh;
  margin: 0;
  color: #2e2e2e;
  font-family: "Asap", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.amount_locked_model_main_div {
  padding: 0 30px;
}

.amount_locked_model_header {
  display: flex;
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 0;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.model-amount-locked-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-amount-locked-content-text {
  padding: 10px 0;
}
