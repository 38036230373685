.dropdown{
  width: 100%;
  user-select: none;
  position: relative;
  height: fit-content;
}

.dropdown_button {
    width: inherit;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    padding-right: 10px;
    background: #ffffff;
    border: 1px solid #060606;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #626262;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 48px;
    display: flex;
    align-items: center;
    /* cursor:not-allowed; */
  }

  .dropdow_disable{
    width: inherit;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    padding-right: 10px;
    background: #ffffff;
    border: 1px solid #060606;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #060606;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 48px;
    display: flex;
    align-items: center;
  }

  .dropdown_select_dropdown {
    width: 100%;
    margin-top: -8px;
    margin-left: 12px;
  }
  
  .dropdown_select_firm_text {
    margin: 0;
    padding-bottom: 6px;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #414141;
  }
  
  .dropdown_content {
      background: #ffffff;
      border: 1px solid grey;
      overflow-y: scroll;
      box-sizing: border-box;
      border-radius: 2px;
      border: 1px solid grey;
      margin-bottom: -147px;
      width: 100%;
      height: auto;
      z-index: 100;
      overflow-y: scroll;
      position:absolute
    }

  
 @media only screen and (max-width: 1200px){

} 