.loader_screen_container{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    margin-top: 10%;
}

.loader_screen_text{
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    width: 450px;
    color: #151515;
    margin-top: 40px;
}

.loader_screen_subtext{
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    width: 450px;
    color: #151515;
    margin-top: 10px;
}