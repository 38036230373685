.kyclink_model_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 350px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
  outline: none;
}

.kyclink_model_title_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.kyclink_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 18px;
}

.kyclink_model_input_div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.kyclink_model_input {
  padding: 10px;
  margin-top: 12px;
  font-size: large;
  border-radius: 3px;
  border: 1px solid rgb(73, 73, 73);
}

.kyclink_model_button {
  padding: 10px;
  width: 150px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
}

.kyclink_model_button:hover {
  background-color: var(--blue-hover);
}

.kyclink_input_err {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-size: 14px;
  height: 13px;
}

.kyclink_copy_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f6f6f6;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 450px;
}

.ifa_referral_code {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  background-color: white;
  border: 1px solid #429ef5;
  border-radius: 5px;
  margin-right: 20px;
  width: 25%;
  padding-left: 3px;
  padding-right: 3px;
}

.circle {
  font-size: 15px;
  color: #000 !important;
  text-align: center;
  /* background: #000; */
  width: 34px;
  height: 34px;
  padding: 8px;
  text-align: center;
}
