.walletlinks_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.walletlinks_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width : 95%;
}

.walletlinks_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.walletlinks_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.walletlinks_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.walletlinks_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 13%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.walletlinks_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.walletlinks_status_text_0 {
  margin: 0;
  padding: 0;
  color: var(--red);
}

.walletlinks_status_text_1 {
  margin: 0;
  padding: 0;
  color: var(--green);
}

.walletlinks_status_text_2 {
  margin: 0;
  padding: 0;
  color: var(--orange);
}

.walletlinks_status_text_5 {
  margin: 0;
  padding: 0;
  color: grey;
}