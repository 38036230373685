.clientkyc_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientkyc_main_div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-left: 220px;
  padding-bottom: 20px;
  overflow: hidden;
}

.clientkyc_top_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.clientkyc_top_row_container {
  width: 56%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientkyc_top_row_back {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
}

.clientkyc_main_container_main_title {
  flex: 2.3;
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 30px;
}

.clientkyc_main_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.clientkyc_main_container_div1 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_div2 {
  padding: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
}

.clientkyc_main_container_title_net_worth {
  margin: 0;
  padding: 0;
  color: var(--blue);
  font-weight: 600;
  font-size: 22px;
}

.clientkyc_main_input_form {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.clientkyc_main_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 12px;
}

.clientkyc_main_input_field {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.clientkyc_main_input_field:disabled {
  background-color: #ececec;
  color: rgb(119, 119, 119);
}

.clientkyc_main_image_div_container {
  display: flex;
  height: 45px;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.clientkyc_main_image_div {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  border: none;
}

.clientkyc_main_image_text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.clientkyc_main_image_button {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  background-color: var(--blue);
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
}

.clientkyc_main_image_button:hover {
  background-color: var(--blue-hover);
}

.kyc_button_container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 70%;
}

.kyc_submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 50%;
  background-color: var(--green);
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.kyc_submit_button:hover {
  background-color: var(--green-hover);
}

@media only screen and (max-width: 550px) {
  .clientkyc_main_image_button {
    width: 28%;
  }
}

.clientkyc_ckyc_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_user_exist_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clientkyc_user_exist_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #414141;
}

.clientkyc_user_exist_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_user_exist_button:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_enable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.clientkyc_ckyc_button_pan_enable:hover {
  background-color: var(--blue-hover);
}

.clientkyc_ckyc_button_pan_disable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: #b9b9b9;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: not-allowed;
  border-radius: 3px;
}

.clientkyc_gender_input {
  padding-left: 15px;
}

.clientkyc_gender_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 3px;
}

/* Page not Found */
.clientpagenotfound_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientpagenotfound_rest_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.clientpagenotfound_container_title {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 100px;
  letter-spacing: 10px;
  color: var(--blue);
  text-align: center;
}

.clientpagenotfound_container_subtitle {
  margin-top: 10px;
  font-size: 25px;
  color: var(--gray);
  text-align: center;
}

.client_tc_header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.client_tc_logo_image {
  width: 180px;
  cursor: pointer;
  margin-left: 2%;
}

.client_thank_page_container {
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 728px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #50cd89;
  border-radius: 25px;
}

.client_thank_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.client_thank_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 90px;
  width: 80%;
  padding-top: 35px;
}

.client_thank_main_title h3 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32.09px;
  /* identical to box height */
  color: #2f2f2f;
}
.client_thank_main_title p {
  font-family: "Asap";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25.63px;
  color: #000000;
}
.clientkyc_net_worth_header_container {
  display: grid;
  grid-template-columns: 70% 30%;
}

.kyc_download_net_worth_button {
  background-color: #3a86ff;
  width: 100px;
  height: 38px;
  border: 1px solid #3a86ff;
  border-radius: 2px;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.kyc_download_net_worth_button_text {
  color: #ffffff;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  align-self: center;
}

.tc_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  border: 1.5px solid #6c6c6c;
  border-radius: 25px;
  margin: auto;
  margin-top: 50px;
  padding: 40px;
  margin-bottom: 50px;
}

.tc_main_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.tc_title {
  color: #2f2f2f;
  font-size: 24px;
  font-weight: 600;
  line-height: 32.09px;
  text-align: center;
}
.tc_nominee_details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}
.tc_nominee_header {
  align-self: center;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}
.tc_nominee_content {
  margin-top: 20px;
  align-self: flex-start;
  width: 100%;
}
.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin-right: 2%;
}
.tc_nominee_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
}
.nominee_key {
  color: #686868;
  font-size: 16px;
  font-weight: 400;
  margin-right: 2%;
}
.nominee_value {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.checkbox_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}
.consent_checked {
  height: 16px;
  width: 16px;
  background-color: #064586;
  border-radius: 1px;
  margin-right: 2%;
  cursor: pointer;
}
.consent_unchecked {
  height: 12px;
  width: 12px;
  border: 2px solid #686868;
  cursor: pointer;
  border-radius: 1px;
  margin-right: 2%;
}
.consent_text {
  font-family: "poppins";
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  width: 100%;
}
.tc_agree_cta {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 40%;
  border-radius: 3px;
  background-color: #3a86ff;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}
.tc_cta_text {
  font-family: "Lato";
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}
.tc_loader {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .clientkyc_main_container_title_net_worth {
    font-size: 16px;
  }

  .kyc_download_net_worth_button {
    width: 80px;
    height: 30px;
    display: flex;
  }

  .kyc_download_net_worth_button_text {
    font-size: 10px;
    text-align: center;
  }
}

@media screen and (max-width: 900px) {
  .kyc_download_net_worth_button {
    width: 60px;
    height: 30px;
    display: flex;
  }

  .kyc_download_net_worth_button_text {
    font-size: 7px;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  .clientkyc_net_worth_header_container {
    display: initial;
    grid-template-columns: none;
  }
}
