.clientinvestment_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: x-scroll;
  margin-bottom: 5vh;
}

.clientinvestment_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.clientinvestment_header_div {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}
.clientinvestment_header_subject_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clientinvestment_header_div_title_container {
  display: flex;
  flex-direction: row;
}
.clientinvestment_header_div_buttons_container {
  display: flex;
  flex-direction: row;
}

.clientinvestment_header_div_subtitle {
  margin: 0;
  padding: 0;
  color: #969494;
  font-size: 14px;
}

.clientinvestment_header_div_title {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 32px;
}

.clientinvestment_header_button_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientinvestment_header_button_div_kyc {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid var(--green);
  color: var(--green);
  border-radius: 3px;
  cursor: pointer;
}

.clientinvestment_header_button_text_kyc {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  color: var(--green);
}

.clientinvestment_header_button_div_kyc:hover {
  background-color: var(--green);
}

.clientinvestment_header_button_div_kyc:hover
  .clientinvestment_header_button_text_kyc {
  color: #fff;
}

.clientinvestment_header_button_div_net {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 3px;
}

.clientinvestment_header_button_text_net {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
}

.clientinvestment_searchbar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.clientinvestment_searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: 12%;
  height: 25px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.clientinvestment_searchbar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.clientinvestment_filtercontainer {
  width: 92%;
  align-self: center;
  align-items: flex-start;
  margin-top: 20px;
}

.clientinvestment_tab_container {
  width: 96%;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 10px;
  column-gap: 30px;
  padding: 0px 10px 0px 10px;
}
.date_selection_column_div {
  height: 30px;
  width: 30%;
}
.Income_Statement_for_earlier_FYs{
  width: 60%;
  height: 150%;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid black;
  text-align: center;
  align-items: center;
  font-family: Asap;  
}
.provisional_income_statement_button{
  color: white;
  background-color: #3A86FF;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-top: 5px;
  width: 148px;
  height: 48px;
  border: 1px solid #A6A6A6;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.annual_income_statement_button {
  color: white;
  background-color: #A6A6A6;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 19px;
  width: 90%;
  height: 150%;
  border: 1px solid #A6A6A6;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.active_ais_button {
  background-color: #3A86FF !important;
}
.clientinvestment_tab_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 45px;
  margin-bottom: 10px;
  width: 100%;
}

.clientinvestment_tab_text {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #4b4b4b;
}

.clientinvestment_tab_underline {
  height: 3px;
  border-radius: 5px;
  margin-top: 5px;
  overflow: hidden;
}

.virtual-model-div {
  display: flex;
  flex-direction: column;
  height: 26%;
  width: 38%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
}
.virtual-model-div-2 {
  display: flex;
  flex-direction: column;
  height: 17%;
  width: 38%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
}

.virtual-model-div-3 {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px; ;
  /* height: 37%; */
  width: 37%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
  margin-top: 20%;
}

.virtual-model-div-8 {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px; ;
  /* height: 37%; */
  width: 75%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
  margin-top: 20%;
}

.cellBox-parent-div-2 {
  width: 40%;
  margin-left: 28px;
}

.virtual-model-body-div-margin2{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 30px;
  row-gap: 24%;
  height: 50%;
  justify-content: center;
  margin-left:28px;
  margin-right: 28px;
}

.annual-model-header-text{
  margin-top: 20px;
  margin-left: 30px;
  font-size: 18px;
  font-weight: 500;
}
.provisional-model-header-text{
    margin-top: 5px;
    color: #3A86FF;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;}

.annual-model-body-div-margin2{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 30px;
  row-gap: 24%;
  justify-content: center;
  margin-left:38px;
  margin-right: 38px;
  border: 1px solid grey;
  border-radius: 4px;
 /* Height:40% */
}

.provisional-model-body-div-margin{
  display: flex;
  margin-top: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #F2F2F2;
  justify-content: center;
  gap: 95px;
  margin-left:38px;
  margin-right: 38px;
  
  border-radius: 4px;
}

.annual-model-inner-body-div{
  display: flex;
  padding-left: 20px;
  padding-top: 8px;
  gap: 30px;
}

.selectDropDownannual{
  width: 60%;
}

@media screen and (max-width: 992px) {
  .virtual-model-div {
    display: flex;
    flex-direction: column;
    height: 26%;
    width: 50%;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  .virtual-model-div-2 {
  display: flex;
  flex-direction: column;
  height: 17%;
  width: 50%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
}
}

@media screen and (max-height: 850px) {
  .virtual-model-div {
    display: flex;
    flex-direction: column;
    height: 36%;
    width: 50%;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  .virtual-model-div-2 {
  display: flex;
  flex-direction: column;
  height: 27%;
  width: 50%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  margin-bottom: 20%;
}
}

.virtual-model-header-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 3px;
  outline: none;
  background-color: #f2f2f2;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
}

.virtual-model-header-div-2{
  display: flex;
  flex-direction: row;
  gap:50%;
  border-radius: 3px;
  outline: none;
  background-color: #f2f2f2;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
}

.virtual-model-header-text {
  margin-top: 20px;
  margin-left: 28px;
  margin-bottom: 23px;
}

.virtual-model-cross-icon {
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 23px;
}

.virtual-model-cross-icon:hover {
  cursor: pointer;
}

.virtual-model-body-div {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 30px;
  row-gap: 24%;
  height: 50%;
  justify-content: center;
}

.virtual-model-inner-body-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
}

.virtual-cell-box {
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
  font-style: normal;
}

.virtual-cell-box-header {
  display: flex;
  flex-direction: column;
  color: #686868;
  font-weight: 400;
  font-size: 12px;
}
.virtual-cell-box-header-2 {
  display: flex;
  flex-direction: column;
  color: #686868;
  font-weight: 400;
  font-size: 14px;
}

.virtual-cell-box-value {
  display: flex;
  flex-direction: column;
  color: #1b1b1b;
  font-weight: 500;
  font-size: 14px;
}
.virtual-cell-box-value-2 {
  display: flex;
  flex-direction: column;
  color: #2e2e2e;
  font-weight: 500;
  font-size: 16px;
  margin-top: 7px;
}

.cellBox-parent-div {
  width: 40%;
}

.virtual-model-copy-icon:hover {
  cursor: pointer;
}

.client_investment_summary {
  display: flex;
  flex-direction: column;
  margin-left: 220px;
  max-height: 900px;
  padding: 0 30px;
}

/* .client_investment_summary_templates{
   padding : 20px 0;
} */

.client_investment_consent_button {
  background-color: #edf4ff;
  color: #3a86ff;
  border: none;
  border-radius: 4px;
  text-underline-offset: 3px;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  width: "auto";
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
}
.consent_resend_button {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  color: #3a86ff;
}

.client_summary_toggle_container {
  display: flex;
  align-self: center;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-top: 30px;
  border-bottom: 1px solid #d4d4d4;
}
.client_summary_toggle_element {
  font-size: 20px;
  color: #757575;
  margin-right: 7%;
  cursor: pointer;
}
.client_summary_toggle_element_active {
  font-size: 20px;
  font-weight: 600;
  color: #3a86ff;
  margin-right: 7%;
  cursor: pointer;
  border-bottom: 3px solid #3a86ff;
  padding-bottom: 12px;
}

/* @media screen and (max-width: 1400px) {
  .client_investment_summary {
    margin-left: 260px;
    margin-right: 10px;
  }
} */
