.bank_verify_form_container {
    width: 100%;
    margin-top: 20px;
  }

  .bank_verify_checkbox {
    display: flex;
    flex-direction: row;
    margin-top: -1%;
    margin-left: 0px;
  }

  .bank_verify_checkbox_condition {
    font-size: 11px;
    margin-left: 0px;
    margin-top: 9px;
    color: rgb(105, 105, 105);
  }

  .bank_verify_checkbox_container {
    width: 100%;
  }