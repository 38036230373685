.withdrawalHistory_div_container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .withdrawalHistory_div_tablecontainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 220px;
  }

  .withdrawalHistory_header_div {
    width: 92%;
    align-self: center;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid blue; */
  }

  .withdrawalHistory_header_button_div_container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .withdrawalHistory_header_div_title_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* border: 1px solid brown; */
  }
  
  .withdrawalHistory_header_div_subtitle {
    margin: 0;
    padding: 0;
    color: #969494;
    font-size: 14px;
  }
  
  .withdrawalHistory_header_div_title {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    color: #42474f;
    font-weight: 600;
    font-size: 28px;
    /* border: 1px solid red; */
  }

  .withdrawalHistory_status_text_0 {
    margin: 0;
    padding: 0;
    color: var(--red);
  }

  .withdrawalHistory_client_name {
    margin: 0;
    padding: 0;
    color: var(--blue);
    /* cursor: pointer; */
  }
  
  .withdrawalHistory_status_text_1 {
    margin: 0;
    padding: 0;
    color: var(--green);
  }