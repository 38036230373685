.add_client_kyc_pre_pan_container{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 55%;
}

.add_client_kyc_pre_pan_main_container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid rgb(219, 219, 219);
}

.add_client_kyc_pre_pan_main_container_title{
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size:18px;
  line-height: 21px;
}

.add_client_kyc_pre_pan_submit_cta{
    padding: 10px;
    width: 97%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    background-color: #3A86FF;
    margin-top: 20px;
    cursor: pointer;
}

.add_client_kyc_pre_pan_cta_text{
    font-size: 18px;
    padding: 0;
    line-height: 21px;
    font-weight: 500;
    color: #FFFFFF;
}

.add_cientkycv2_pan_consent_checkbox {
    display: flex;
    flex-direction: row;
    margin-top: -1%;
    margin-left: 0px;
  }
  
  .add_cientkycv2_pan_consent_checkbox_condition {
    font-size: 13px;
    margin-left: 0px;
    margin-top: 10px;
    color: #323232;
    font-family: 'Poppins';
  }

  .add_client_pan_not_verified_container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    border: 1px solid rgb(219, 219, 219);
    align-items: center;
    width: 65%;
    padding-top: 90px;
    height: 300px;
  }
  
  .add_client_pan_not_verified_text{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    margin-top: 20px;
  }
  
  .add_client_pan_not_verified_support_text{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 0;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    margin: 0;
  }

  .add_client_pan_not_verified_support_email{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 10px;
    padding: 0;
    color: #064586;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    text-decoration: none;
  }

  .add_client_pan_not_verified_support{
    margin-top: 25px;
  }