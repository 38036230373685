.investment_consent_confirmation_div {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  width: 35%;
  margin-bottom: 20px;
  border: #6c6c6c 1px solid;
  overflow: hidden;
  padding-bottom: 55px;
  padding-top: 25px;
  margin-top: 120px;
}

.investment_consent_confirmation_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
}

.investment_consent_confirmation_header_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.investment_consent_confirmation_input {
  width: 70%;
  margin-top: 8%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}

.investment_consent_confirmation_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  width: 74%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20%;
}

.investment_consent_confirmation_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

.investment_consent_confirmation_otp_button {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  font-family: 'Lato';
  margin-top: 40px;
}

.investment_consent_confirmation_otp_button_verify {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  font-family: 'Lato';
  margin-top: 25px;
}

.investment_consent_confirmation_page_expire {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.investment_consent_confirmation_header_page_expire_title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: rgb(87, 87, 87);
}

.investment_consent_confirmation_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.investment_consent_confirmation_logo_image {
  margin-top: 25px;
  width: 180px;
  margin-left: 2%;
  padding-bottom: 25px;
}

.image_container {
  width: 100%;
  align-items: left;
  border-bottom: #DBDBDB 1px solid;
}

.consent_checkbox {
  display: flex;
  flex-direction: row;
  padding-left: 15%;
  padding-right: 15%;
}

.checkbox_label {
  font-size: 11px;
  margin-left: 0px;
  padding-top: 7px;
  color: #323232;
  font-family: 'Poppins';
}

.otp_input {
  padding-left: 20%;
  width: 80%;
}

.consent_buttons {
  display: flex;
  justify-content: center;
}

.consent_success_message {
  text-align: center;
  padding-left: 8%;
  padding-right: 8%;
}

.success_message_body {
  font-size: 16px;
  font-family: 'Asap';
}

.check_mark_image {
  padding-top: 50px;
  padding-bottom: 30px;
}