.title {
  font-size: 28px;
  font-family: 'Poppins';
  font-weight: 500;
}
.subtitle {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 400;
} 
