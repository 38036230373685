.add_clientkycv2_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.add_clientkycv2_main_div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-left: 220px;
  padding-bottom: 20px;
  overflow: hidden;
}

.add_clientkycv2_top_container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.add_clientkycv2_top_row_container {
  width: 56%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add_clientkycv2_top_row_back {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
}

.add_clientkycv2_main_container_main_title {
  flex: 2.3;
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 30px;
}

.add_clientkycv2_main_conatiner {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.add_client_kyc_main_submit_cta {
  padding: 10px;
  width: 53%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background-color: #3a86ff;
  margin-top: 20px;
  cursor: pointer;
}

.add_client_v2_success_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 220px;
  margin-left: 221px;
  padding-bottom: 20px;
  overflow: hidden;
}

.add_client_success_screen_subtext_text {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  padding: 0;
  align-items: center;
  text-align: center;
  /* font-family: "Poppins"; */
  margin: 0;
}

.add_client_success_screen_v2 {
  margin-top: 25px;
}

.add_client_success_screen_text {
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  align-items: center;
  text-align: center;
  /* font-family: "Poppins"; */
  margin-top: 20px;
}

.add_client_success_screen_container {
  padding: 60px;
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  border: 1px solid rgb(219, 219, 219);
  align-items: center;
  width: 72%;
  padding-top: 90px;
  height: 300px;
}
.add_client_v2_success_screen_main {
  display: flex;
  align-items: center;
  justify-content: center;
}
