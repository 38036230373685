.transaction_history_model_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  padding-bottom: 50px;
  margin-left: 2vw;
  margin-right: 2vw;
  width: 50vw;
}

.transaction_history_model_header_title {
  padding: 2.5vh;
  margin: 0;
  color: #343434;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.transaction_history_model_main_container_div {
  display: flex;
  flex-direction: column;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 2vh;
  max-height: 50vh;
  overflow-y: scroll;
}

.transaction_history_model_main_container_div ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.transaction_history_model_main_container_div ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.transaction_history_model_main_div {
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 1vh;
}

.disclaimer_model {
  margin-left: 3vw;
  margin-right: 3vw;
  margin-bottom: 2vh;
}

.transaction_history_model_line_div {
  padding-bottom: 1.5vh;
}

.transaction_history_model_header {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 0;
  align-items: left;
  text-align: left;
}

.transaction_history_model_left_div {
  display: grid;
  grid-template-columns: 10% 50%;
}

.transaction_history_model_title {
  margin: 0;
  padding: 0;
  color: #1b1b1b;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.transaction_history_model_title_description {
  margin: 2px 0px 10px 0px;
  padding: 0;
  color: #737373;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 12px;
}

.transaction_history_model_input {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  font-size: large;
  border-radius: 5px;
  border: 1px solid #424242;
}

.transaction_history_model_button {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 230px;
  height: 50px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Lato", sans-serif;
}

.transaction_history_model_button:hover {
  background-color: var(--blue-hover);
}

.transaction_history_model_loader_div {
  display: flex;
  width: 92%;
  height: 400px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.arrow_downward_icon_outer_circle_div {
  width: 25px;
  height: 25px;
  background-color: #064586;
  border-radius: 50%;
  position: relative;
}

.arrow_downward_icon_div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.download_statement_container_div {
  display: flex;
  flex-direction: column;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 2vh;
  max-height: 50vh;
}

.download_statement_title_div {
  padding-left: 0;
  color: #212121;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 25.36px;
}

.download_statement_sub_container_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #adadad;
  border-radius: 4px;
  height: 121px;
  margin-bottom: 20px;
}
.download_statement_sub_title_div {
  padding-left: 0;
  color: #3a3a3a;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  margin-left: 4%;
  margin-top: 10px;
}
.download_statement_date_selection_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  margin-top: 0;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 4%;
}
.date_selection_column_div {
  height: 30px;
  width: 30%;
}

.date_picker_input {
  border-color: #adadad;
  height: 40px;
  width: 100%;
  padding-left: 6%;
  padding-right: 1%;
  font-size: 17px;
  border-radius: 5px;
}
@media screen and (max-width: 850px) {
  .transaction_history_model_div {
    width: 90vw;
  }

  .transaction_history_model_left_div {
    grid-template-columns: 8% 70%;
  }

  .transaction_history_model_info {
    text-align: left;
  }
}

.transaction_history_model_loader_loader {
  /* display: flex; */
  align-self: center;
  align-items: center;
  top: 90%;
  position: absolute;
  justify-content: center;
}

.ifa_transaction_statement_button {
  color: white;
  background-color: #3a86ff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 19px;
  width: 100%;
  height: 100%;
  border: 1px solid #3a86ff;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}