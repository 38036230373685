.clientinvestmentcertificate_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 5vh;
}

.clientinvestmentcertificate_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.clientinvestmentcertificate_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clientinvestmentcertificate_header_div_title_container {
  display: flex;
  flex-direction: column;
}

.clientinvestmentcertificate_header_div_subtitle {
  margin: 0;
  padding: 0;
  color: #969494;
  font-size: 14px;
}

.clientinvestmentcertificate_header_div_title {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.clientinvestmentcertificate_header_button_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientinvestmentcertificate_download_all_container {
  margin-top: 20px;
  width: 92%;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.clientinvestmentcertificate_download_all_button {
  width: 170px;
  height: 50px;
  background-color: var(--blue);
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.clientinvestmentcertificate_download_all_button_text {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 16px;
}

.clientinvestmentcertificate_table_container {
  margin-top: 10px;
  width: 92%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 20px;
}

.download-icon {
  cursor: pointer;
}

.pagination_div_client_v2 {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  align-self: center;
  background-color: #f6f6f6;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.clientinvestmentcertificate_search_bar_div {
  width: 100%;
}

.clientinvestmentcertificate_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 12%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.clientinvestmentcertificate_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}
