
.wallet_loading_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    padding-bottom: 50px;
    margin-left:2vw;
    margin-right: 2vw;
  }

  .wallet_loading_model_header_title{
    padding: 2.5vh;
    margin: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
  }

  .wallet_loading_model_main_div{
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .wallet_loading_model_header{
    background-color: #F2F2F2;
    margin-bottom:30px;
    padding:0;
    align-items: left;
    text-align: left;
  }

  .wallet_loading_model_title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .wallet_loading_model_title {
    margin: 0;
    padding: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-size: 20px;
  }
  
  .wallet_loading_model_input {
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    font-size: large;
    border-radius: 5px;
    border: 1px solid #424242;
  }
  
  .wallet_loading_model_button {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 230px;
    height: 50px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
  
  .wallet_loading_model_button:hover {
    background-color: var(--blue-hover);
  }
  