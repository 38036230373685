.profile_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile_rest_div {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.profile_rest_div_heading {
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  align-self: center;
  color: #c0c0c0;
  font-weight: 800;
  font-size: 28px;
}

.profile_border {
  background: linear-gradient(to bottom, #f0f0f0 180px, white 0%);
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 30px 20px;
  padding: 0;
  width: 92%;
  margin-top: 60px;
  align-self: center;
  /* color: #848884; */
  font-weight: 800;
  font-size: 30px;
}

.profile_section {
  margin-top: 34px;
  margin-bottom: 40px;
  padding-left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile_circle {
  align-items: center;
  height: 100px;
  width: 100px;
  background-color: #6b6d6e;
  color: #f0f0f0;
  border-radius: 50%;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 20px;
}

.profile_section_heading {
  /* margin: 1%; */
  padding: 0 px;
  /* margin-left: -1px; */
  width: 100%;
  margin-bottom: 0px;
  margin-top: 10px;
  align-self: center;
  color: #36454f;
  font-weight: 500;
  font-size: 28px;
}
.profile_client_heading {
  margin: 0;
  padding-left: 10px;
  width: 92%;
  margin-top: 10px;
  align-self: center;
  color: #36454f;
  font-weight: 500;
  font-size: 28px;
}

.profile_tag {
  margin-left: -10px;
  padding: 0;
  font-size: 15px;
  color: #36454f;
  align-self: center;
  font-weight: 100;
  width: 100%;
}

.profile_row {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.profile_row_container {
  display: flex;
  height: auto;
  /* margin-top: 40px; */
  padding-top: 0;
}

.profile_row_container_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  padding-left: 15px;
  border-radius: 3px;
  border: 1px #d0d0d0;
  /* height : 800px; */
}

.profile_row_container_title {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  color: #b5b4b3;
  font-weight: 500;
  font-size: 13px;
  height: 15px;
}

.profile_row_container_subtitle {
  margin: 0;
  /* padding-left:50px; */
  padding: 0;
  margin-top: 0px;
  color: #262626;
  font-weight: 300;
  font-size: 17px;
  /* height : 50px; */
}

.ifa_profile_referral_code {
  cursor: pointer;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  font-size: 18px;
  width: 125px;
}

.profile_row_container_ref {
  /* margin-right: 5px; */
  /* padding:10px; */
  margin-bottom: 10px;
  color: #4d4b4a;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  /* height: 20px; */
}

.profile_initials {
  display: flex;
  flex-direction: row;
}

.profile_referral_wrapper {
  width: 20%;
}

.profile_referral_span {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ifa_profile_email_status {
  font-weight: 300;
  font-size: 14px;
  color: #069952;
}
.email_verified_status {
  height: 16px;
  width: 16px;
  margin-bottom: -3px;
}

.ifa_profile_email_text {
  margin-right: 10px;
  font-weight: 300;
  font-size: 14px;
  color: #262626;
}
.ifa_email_pending_container {
  width: 70%;
  border-radius: 4px;
  border: 0.5px solid #db8400;
  background: #fffaf2;
  margin-top: 5px;
  display: flex;
}

.ifa_email_pending_text {
  margin: 0;
  color: #db8400;
  font-size: 12px;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: -5px;
}

.ifa_email_pending_text_without_subtext {
  margin: 0;
  color: #db8400;
  font-size: 12px;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: fit-content;
  border-radius: 4px;
  border: 0.5px solid #db8400;
  background: #fffaf2;
  margin-top: 5px;
  display: flex;
}

.ifa_email_pending_subtext {
  margin: 0;
  color: #db8400;
  font-size: 10px;
  font-family: Asap;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ifa_email_send_button {
  border: 0.5px solid #3a86ff;
  background: #3a86ff;
  color: #fff;
  font-size: 9px;
  font-family: Poppins;
  font-style: normal;
  width: 70px;
  padding: 7px;
  font-weight: 400;
  margin: 6px;
  line-height: normal;
  margin-top: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.ifa_email_container{
  margin-top: -10px;
}