.add_client_kyc_basic_details_container{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin-top: 15px;
}

.add_client_kyc_basic_details_main_container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 70%;
    border-radius: 5px;
    border: 1px solid rgb(219, 219, 219);
}


.add_client_kyc_basic_details_submit_cta{
    padding: 10px;
    width: 75%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    background-color: #3A86FF;
    margin-top: 20px;
    cursor: pointer;
}

.add_client_kyc_basic_details_cta_text{
    font-size: 18px;
    padding: 0;
    line-height: 21px;
    font-weight: 500;
    color: #FFFFFF;
}

.add_client_investor_type{
    margin-bottom: 25px;
}

/* .add_client_gender_type_container{
    margin-bottom: 25px;
} */

.add_client_investor_select_type{
    display: flex;
}

.add_client_gender_type{
    display: flex;
}