.overview_filter_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: 12%;
  height: 25px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.overview_filter_icon_1 {
  font-size: 22px;
  color: #a9a9a9;
}

.overview_filter_text {
  margin: 0;
  padding: 0;
  flex: 1;
  font-size: 13px;
  margin-left: 18px;
  color: #8a8888;
}

.overview_filter_dropdown_button {
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  padding-left: 10px;
  background-color: transparent;
}

.overview_filter_icon_2 {
  font-size: 22px;
  color: #a9a9a9;
}
