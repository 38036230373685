.paymentlinks_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.paymentlinks_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width : 95%;
}

.paymentlinks_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.paymentlinks_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.paymentlinks_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.paymentlinks_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 13%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.paymentlinks_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.paymentlinks_status_text_0 {
  margin: 0;
  padding: 0;
  color: var(--red);
}

.paymentlinks_status_text_1 {
  margin: 0;
  padding: 0;
  color: var(--blue);
}

.paymentlinks_status_text_5 {
  margin: 0;
  padding: 0;
  color: var(--orange);
}

.paymentlinks_status_success {
  margin: 0;
  padding: 0;
  color: var(--green);
}

.resend_button {
  border: none;
  background: "#ebf0fa";
  border-radius: 1px;
  width:40px;
  height:30px;
}

.resend_payment_link_replay_button {
  color: #3a86ff;
}

.client_investment_status_modal_div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  width: 610px; 
  height: 300px;
  padding-bottom: 50px;
  margin-left:2vw;
  margin-right: 2vw;
}

.investment_status_modal_header {
  background-color: #f2f2f2;
  margin-bottom: 30px;
  padding: 0;
  align-items: left;
  text-align: left;
  border-radius: 4px 4px 0 0;
}

.investment_status_modal_header_title {
  padding: 2.5vh;
  padding-left: 2.5vw;
  margin: 0;
  color: #343434;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.investment-status-body {
  text-align: center;
  margin-top: 5px;
  font-weight: 500;
  font-size: 18;
}

.investment-amount-body {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 32px;
}

.status-link {
  text-decoration: underline;
  cursor: pointer;
}

.investment-status {
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}

.investment-details-model-slider{
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.investment-details-model-line{
  width: 190px;
  height: 0px;
  align-items: center;
  gap: 0px;
  border: 1px solid #BDBDBD;
}