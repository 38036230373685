
.client_portfolio_request_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    padding-bottom: 50px;
    width: 610px;
    height: 350px;
    flex-shrink: 0;
  }

  .client_portfolio_request_model_header_title{
    display: flex;
    padding: 2.5vh;
    margin: 0;
    color: #2E2E2E;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }

  .client_portfolio_request_model_main_div{
    padding:0 30px;
  }

  .client_portfolio_request_model_header{
    display: flex;
    background-color: #F2F2F2;
    margin-bottom:10px;
    padding:0;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .client_portfolio_toggle_container {
    display: flex;
    align-self: center;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    border-bottom: 1px solid #D4D4D4;
  }
  .client_portfolio_toggle_element {
    font-size: 15px;
    color: #757575;
    margin-right: 10%;
    cursor: pointer;
  }
  .client_portfolio_toggle_element_active {
    font-size: 15px;
    font-weight: 600;
    color: #3a86ff;
    margin-right: 10%;
    cursor: pointer;
    border-bottom: 3px solid #3a86ff;
    padding-bottom: 12px;
  }
  .client_portfolio_investments_container{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  .client_portfolio_investments_content{
    display: flex;
    flex-direction: row;
    padding: 10px 0;
  }
  .client_portfolio_investments_text{
    display: flex;
    flex: 50%;
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .client_portfolio_investments_value{
    display: flex;
    flex:50%;
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  