.add_client_kyc_bank_details {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.add_client_kyc_bank_details {
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 70%;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.add_client_kyc_bank_verify_cta {
  display: flex;
  height: 45px;
  width: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  outline: none;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 10px;
}

.add_cientkycv2_bank_consent_checkbox {
  display: flex;
  flex-direction: row;
  margin-top: -1%;
  margin-left: 0px;
}

.add_cientkycv2_bank_consent_checkbox_condition {
  font-size: 13px;
  margin-left: 0px;
  margin-top: 10px;
  color: #323232;
  font-family: "Poppins";
}

.add_client_propritor_pan_verify_cta {
  padding: 10px;
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background-color: #3a86ff;
  margin-top: 20px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 0;
}

.address_toggle {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
}

#address_toggle_label {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  padding-left: 0;
  margin-left: 0;
}


#add_client_proprietor_detail_subtitle{
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size:18px;
  line-height: 21px;
}


