.transaction_requests_div_container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .transaction_requests_div_table_container{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 220px;
  }

  .transaction_requests_header_div {
    width: 90%;
    align-self: center;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .transaction_requests_header_div_title {
    margin: 0;
    padding: 0;
    color: #42474f;
    font-weight: 600;
    font-size: 24px;
  }
  .toggle_container{
    display: flex;
    align-self: center;
    flex-direction: row ;
    width: 90%;
    justify-content: flex-start;
    margin-top: 30px;
    border-bottom: 1px solid #8c8c8c;
  }
  .toggle_element{
    font-size: 20px;
    color: #616162;
    margin-right: 10%;
    cursor: pointer;
  }
  .toggle_element_active{
    font-size: 20px;
    font-weight: 600;
    color:#3A86FF;
    margin-right: 10%;
    cursor: pointer;
    border-bottom: 3px solid #3A86FF;
    padding-bottom: 12px;
  }

