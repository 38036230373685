.logo_main_input_form {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    width:300px;
  }

.logo_main_input_form .img{
  width:100%;
  height:100%
}
.logo_main_input_label {
font-size: 16px;
margin: 0;
padding: 0;
font-weight: normal;
margin-bottom: 12px;
}

.logo_main_image_div_container {
display: flex;
height: 100%;
width: 100%;
margin: 0;
padding: 0;
margin-bottom: 12px;
border: 1px solid #cacaca;
border-radius: 3px;
flex-direction: row;
align-items: center;
overflow: hidden;
}
  
.logo_main_image_div {
flex: 1;
width:100%;
display: flex;
align-items: center;
margin: 0;
padding: 0;
padding-left: 12px;
border: none;
}
  
.logo_main_image_text {
margin: 0;
padding: 0;
font-size: 16px;
height:100%;
width:100%;
}

.logo_main_image_button {
height: 100%;
width: 28%;
display: flex;
align-items: center;
justify-content: center;
font-size: medium;
background-color: var(--blue); 
color: #fff;
border: none;
cursor: pointer;
outline: none;
}
.logo_main_image_button:hover {
    background-color: var(--blue-hover);
    }

/* @media only screen and (max-width: 550px) {
    .logo_main_image_button {
      width: 28%;
    }
} */

.logo_field_container {
    display: flex;
    flex-direction: column;
  }
.logo_submit_container {
  background-color: var(--blue);
  color: #fff;
  align-items: "center";
  display: "flex";
  align-self: "center";
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 50%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  margin-left:25%
}
/* .download_image {
    display: block;
    margin-bottom: 10px;
    width:300px;
    padding:5%;
  } */
  .support_header {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    grid-row: 1 / 2;
  }