.additional-info-container {
    background-color: #ffebeb;
    color: red;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    overflow-x: none;
    margin-right: 10px;
    padding: 10px 5px;
    margin-top: 10px;
}
.additional-info-lock-icon-information {
    display: flex;
    align-items: center;
}
.Information-text {
    font-size: 14px;
}
.information-text-conatainer {
    display: flex;
    flex-direction: column;
}
.information-text1 {
    font-size: 14px;
}
.information-text2 {
    font-size: 12px;
    margin-top: 5px;
}
