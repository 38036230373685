.pool_list_div_main_container {
    display: flex;
    flex-direction: column;
  }
  
  .pool_list_main_div {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
  }
  
  .poollist_clientname_conatiner {
    display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
  }
  
  .poollist_header_button_text_title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
    color: #525252;
  }
  
  .poollist_header_button_text_subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
    color: var(--blue);
  }
  
  .pool_pagination_div_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    /* padding-bottom: 60px; */
  }
  
  .pagination_div_container {
    display: flex;
    align-self: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    width: 80%;
    height: 80px;
  }
  
  .previous_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: #3a86ff;
  }
  
  .next_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: #3a86ff;
    margin-left: 30px;
  }
  
  .pool_list_toggle_container {
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    border-bottom: 1px solid #D4D4D4;
  }
  .pool_list_toggle_element {
    font-size: 20px;
    color: #757575;
    margin-right: 7%;
    cursor: pointer;
  }
  .pool_list_toggle_element_active {
    font-size: 20px;
    font-weight: 600;
    color: #3a86ff;
    margin-right: 7%;
    cursor: pointer;
    border-bottom: 3px solid #3a86ff;
    padding-bottom: 12px;
  }
  .pool_list_sub_heading{
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 40px;
    font-weight: 400;
    color: #757575;
    width: 100%;
  }
  .pool_list_sub_heading_content{
    margin-left:0px;
    padding-left:0px;
    font-size: 16px;
  }
  