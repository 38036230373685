.client_overview_chart_container {
  border: 1px solid #d1d1d1;
  border-radius: 16px;
  display: grid;
  height: 'auto';
  grid-template-rows: 74% 13% 13%;
  margin: 0px 10px 0px 0px;
  text-align: center;
}

.client_overview_chart {
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.client_overview_limit {
  background-color: #e6f2ff;
  padding-top: 3.3%;
  padding-bottom: 3.3%;
  align-self: center;
  text-align: center;
  justify-content: center;
}

.client_overview_limit_text {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.client_overview_footer {
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-top: 3.3%;
  padding-bottom: 2%;
  align-self: center;
  text-align: center;
  justify-content: center;
}

.client_overview_footer_text {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.client_overview_withdraw {
  border: 1px solid #d1d1d1;
  height: auto;
  border-radius: 16px;
  display: grid;
  grid-template-rows: 85% 15%;
  margin: 0px 10px 0px 0px;
  margin-top: 20px;
  padding-top: 4.8%;
  padding-bottom: 10%;
  padding-left: 5%;
  padding-right: 5%;
}

.client_overview_withdraw_detail {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: left;
}

.client_overview_withdraw_header_text {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom: 2;
  padding-bottom: 0;
}

.client_overview_withdraw_value_text {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #212121;
  margin-top: 0;
  padding-top: 0;
}

.client_overview_button {
  background-color: transparent;
  border: 1.3px solid #3a86ff;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  padding: 10px;
}
.client_overview_button_disabled{
  background-color: transparent;
  border: 1.3px solid #949598;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  padding: 10px;
}
.client_overview_button_disabled{
  background-color: transparent;
  height: 36px;
  border: 1.3px solid #949598;
  border-radius: 3px;
  justify-content: center;
  text-align: center;
  margin-left: 20px;
}

.client_overview_button_text {
  color: #3a86ff;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  align-self: center;
}
.client_overview_button_text_disabled {
  color: #949598;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  align-self: center;
}

.client_overview_button_text_disabled {
  color: #949598;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  align-self: center;
}

.client_overview_actions{
  padding:15px 0;
  border-radius: 8px;
  opacity: 0.7;
  background: #F6F6F6;
  margin: 30px 0;
}

.client_overview_action_buttons{
  padding: 10px 0;
}

.client_overview_actions_header {
  color:#757575;
  font-weight: 600;
  margin-left:20px;
}

.client_overview_investment_details{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  /* margin-bottom: 30px; */
}

.investment_detail_card{
  display:flex;
  flex-direction: column;
  background-color: #EBF2FE;
  border-radius: 10px;
  width: 50%;
  margin: 20px 0;
  align-items: center;
  padding: 25px;
}

.investment_detail_info{
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 20px;
}

.investment_detail_content{
  text-align: center;
  font-family: Poppins;
  font-size: 38px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.investment_detail_logo{
  display:flex;
  padding:10px;
}

.investment_detail_text{
  display: flex;
  flex-direction: column;
  padding:0 10px;
}

.investment_detail_button{
  display: flex;
  flex:20%;
  align-items: center;
  justify-content: center;
  border-top:0.5px solid #BDD0F2;
  padding:6px;
}

.investment_detail_value{
  font-weight: 600;
  font-size: 20px;
  color: #2E2E2E;
}

.investment_detail_header{
  font-size: 14px;
  color:#2E2E2E;
  padding:5px;
}

.view_details_button{
  color:#3A86FF;
  font-size: 14px;
}

.view_details_button:hover{
  text-decoration: underline;
  cursor:pointer;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
    transform: translate(-50%, -50%);
  width: 40%;
  background: rgba(0, 0, 0, 1);
  border: 1px solid #424242;
  border-radius: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999; 
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-button{
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 230px;
  height: 50px;
  margin-top: 30px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
}

.disclaimer-container {
  padding: 15px 10px;
  border: 1px solid #fbe795;
  background: #fffbeb;
  font-size: 16px;
  color: #404040;
  border-radius: 4px;
  display: flex;
}

.info-icon {
  height: 20px;
  margin-right: 8px;
}

.disclaimer-text {
  font-size: 16px;
  color: #404040;
}

.bold-text{
  font-weight: "600";
  opacity: "0.9";
  font-size: "16px";
  color: "#404040"
}


@media screen and (max-width: 1300px) {
  .client_overview_footer_text {
    font-size:10px
  }

  .client_overview_button {
    width: 150px;
  }

  .client_overview_button_disabled{
    width: 150px;
  }

  .client_overview_button_text {
    font-size: 9px;
  }
  .client_overview_button_text_disabled {
    font-size: 9px;
  }
}

@media screen and (max-width: 1050px) {
  .client_overview_button {
    width: 120px;
  }
  .client_overview_button_disabled{
    width: 120px;
  }
  .client_overview_button_text {
    font-size: 9px;
  }
  .client_overview_button_text_disabled {
    font-size: 9px;
  }
  .client_overview_chart {
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-left: 0%;
  }

  .client_overview_footer_text {
    font-size:12px
  }
}