.auth_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 70px;
}

.auth_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
  
}

.auth_header_text {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: black;
  margin-right: 2%;
}

.auth_logo_img {
  height: auto;
  width: 140px;
  cursor: pointer;
  margin-left: 2%;
}

.auth_main_logo_image {
  width: 13%;
  cursor: pointer;
}

.auth_rest_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 100px;
}

.auth_image_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: 50%;
  height: 100%;
  background-image: url("https://arthmate-p2p-data.s3.ap-south-1.amazonaws.com/dev/public/12345.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(120%);
}

.auth_main_title {
  color: #414141;
  font-weight: bolder;
  font-size: 40px;
  margin-top: 100px;
  margin-bottom: 60px;
  text-align: center;
}

/* .auth_image {
  width: 100%;
  overflow: hidden;
  /* margin-right: 100px; */
/* } */

.auth_form_container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  align-items: center;
  width: 50%;
  padding-bottom: 20px;
  /* text-align: center; */
}

.thank_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.thank_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 100px;
  padding-top: 35px;
}

.thank_main_title h3 {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height */
  color: #2f2f2f;
}

@media screen and (max-width: 990px) {
  .auth_header_text {
    font-size: 18px;
  }

  .auth_image_container {
    width: 50%;
  }
  .auth_form_container {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {

  .auth_header {
    flex-direction: column;
  }

  .auth_logo_img {
    width: 180px;
    height: auto;
  }

  .auth_header_text {
    font-size: 20px;
  }

  .auth_main_logo_image {
    width: 30%;
  }

  .auth_rest_container {
    flex-direction: column;
    margin-top: 100px;
  }

  .auth_image_container {
    position: absolute;
    height: 50%;
    width: 100%;
    flex: 1;
    background-image: url("https://arthmate-p2p-data.s3.ap-south-1.amazonaws.com/dev/public/12345.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .auth_main_title {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  /* .auth_image {
    width: 60%;
    margin-bottom: 40px;
    margin-right: 70px;
  } */

  .auth_form_container {
    left: 0%;
    position: absolute;
    top: 60%;
    width: 100%;
  }
}
