.client-item {
    margin-bottom: 10px;
  }
  .client-item label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .client-item input[type="checkbox"] {
    margin-right: 10px;
  }

  .parent_div{
    background-color: #fff;
    border:2px solid rgb(124, 122, 122);
    border-radius: 5px;

  }

  .list_client_model_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    padding-left: 50px;
    padding-right: 50px;
    height:"50%";
    width:"50%";
    background-color: #fff;
    border-radius: 5px;
    outline: none;
    height:600px;
    width:500px;
    overflow-y: "auto";
  }

  .client_list{
    overflow-y: "auto";
    height: 300px;
    width:300px;
    border: 2px solid rgb(124, 122, 122);
    padding:4%;
    border-radius: 2%;
  } 

  .text_input_box_message {
    width:300px;
    height: 150px;
    margin-top: 15px;
    padding: 4%;
    font-size: 14px;
    border: 1px solid #c3c3c3;
    border-radius: 2%;
    /* align-self: center; */
    overflow-y: "auto";
  }

  
  .text_input_box_message::placeholder {
    color: #838383;
    margin-top: 0px;
  }


  .send_submit_container {
    background-color: var(--blue);
    color: #fff;
    margin-top: 15px;
    padding: 4%;
    border: none;
    width:350px;
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    
  }

  .send_submit_container:hover {
    background-color: var(--blue-hover);
  }
  