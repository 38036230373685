.upload_input_v2_container {
  display: flex;
}

.upload_input_v2_main_container {
  width: 100%;
  font-size: 12px;
  padding: 15px;
  color: #3a86ff;
  border-color: #3a86ff;
  background-color: #ffffff;
  cursor: pointer;
  border-style: solid;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.upload_input_v2_text {
  font-size: 12px;
  color: #3a86ff;
  border-color: #3a86ff;
  margin-left: 5px;
}

.upload_input_v2_info_text_container {
  margin-top: 15px;
  font-size: 50px;
}

.upload_input_v2_title {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

.upload_input_v2_file_container {
  width: 100%;
  border: 1px solid black;
  padding: 10px;
  border-radius: 2px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.upload_input_v2_remove_file {
  padding: 0;
  color: #3a86ff;
  font-size: 13px;
  font-weight: 600;
  font-family: lato;
  cursor: pointer;
}

.upload_input_v2_file_text {
  padding-top: 5px;
  font-size: 13px;
  color: #616060;
}
.download_template{
  font-weight: 400;
  color: #3A86FF;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
