.forms {
  padding-top: 120px;
  text-align: center;
}

.forms form {
  width: 70%;
  border: 2px solid grey;
  padding: 30px;
}

.forms form img {
  height: 120px;
  margin-left: 90px;
  margin-bottom: 10px;
  display: block;
  border: 1px solid black;
  border-radius: 50%;
}

.forms .form-header .textFieldDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 3px;
}

.forms .form-header .textFieldDiv input {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.forms .form-header .textFieldDiv input:disabled {
  background-color: #ececec;
  color: rgb(119, 119, 119);
}

.forms .form-header .textFieldDiv label {
  display: flex;
  flex-grow: 1;
}

.forms .form-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.forms .form-header .submit {
  border-radius: 6px;
  cursor: pointer;
  font-size: 22px;
  background-color: var(--blue);
  color: #fff;
  width: 100%;
  height: 36px;
  margin-top: 16px;
}

.forms .form-header .submit:disabled {
  border-radius: 6px;
  cursor: not-allowed;
  font-size: 22px;
  border: 0;
  background-color: gray;
  color: lightgray;
  width: 100%;
  height: 36px;
  margin-top: 16px;
}
