.commissions_summary_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.commissions_summary_div_table_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.commissions_summary_header_div {
  width: 90%;
  align-self: center;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.commissions_summary_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 24px;
}
.toggle_container {
  display: flex;
  align-self: center;
  flex-direction: row;
  width: 90%;
  justify-content: flex-start;
  margin-top: 30px;
  border-bottom: 1px solid #8c8c8c;
}
.toggle_element {
  font-size: 20px;
  color: #616162;
  margin-right: 10%;
  cursor: pointer;
}
.toggle_element_active {
  font-size: 20px;
  font-weight: 600;
  color: #3a86ff;
  margin-right: 10%;
  cursor: pointer;
  border-bottom: 3px solid #3a86ff;
  padding-bottom: 12px;
}
.commissions_summary_info_container {
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 2%;
}
.commissions_summary_info {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.commissions_summary_info_heading {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3%;
  color: #4e4e4e;
  display: grid;
  grid-template-columns: 90% 6%;
}
.commissions_summary_info_value {
  color: #262626;
  font-weight: 600;
  font-size: 24px;
}
.commissions_summary_info_value_1{
  color: #393939;
  font-weight: 500;
  font-size: 24px;
}
.paid_value{
  color: #2DAE18;
  font-weight: 600;
  font-size: 20px;
}
.unpaid_value{
  color: #E25656;
  font-weight: 600;
  font-size: 20px;
}
.table_container {
  width: 90%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no_selection_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12%;
}
.no_selection_text {
  font-size: 14px;
  color: #4e4e4e;
  margin-top: 4%;
  font-weight: 400;
}
.MenuItem{
  font-family: "Asap";
}
.table_data_container{
  width: 100%;
  align-items: center
}
.table_footer{
  position: absolute;
  white-space: nowrap;
  top: 92%;
  right: 4%;
}
.total_commission{
  font-weight: 400;
  font-size: 18px;
  margin-top: 2%;
  margin-bottom: 2%;
}
.contact_us{
  font-weight: 500;
  font-size: 18px;
  margin-left: 1%;
  color: #000000
}

.invoice_section{
  display: flex;
  float: left;
  height: 50px;
}
.ifa_commissions_generate_invoice{
  background-color: var(--blue);
  border-radius: 3px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  width: 160px;
  justify-content: center;
  text-align: center;
  border: none;
  padding-top: 10px;
  color: white;
  padding-bottom: 10px;
  cursor: pointer;
}

.ifa_commissions_generate_invoice_button_text{
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
  text-align: center;
}

.invoice_link{
  text-decoration: none;
  color: blue;
  margin: 0;
  padding: 0;
}

.total_commissions_earnings_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1%;
}

.earnings_toggle_container {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 14px;
  font-family: "Asap";
  margin-top: 1%;
  margin-bottom: 1.6%;
  border-radius: 6px;
  margin-right: 3.1%;
}

.earnings_toggle_element {
  color: #3a86ff;
  cursor: pointer;
  padding: 5%;
  white-space: nowrap;
  border: 1px solid #3a86ff;
  width:110%;
}

.earnings_toggle_element_active {
  color: white;
  cursor: pointer;
  background-color: #3a86ff;
  padding: 5%;
  white-space: nowrap;
  border: 1px solid #3a86ff;
  width:110%;
}

.monthly {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.maturity {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
