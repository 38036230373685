.main_signup {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.signup_container {
  display: flex;
  flex-direction: column;
  /* margin-top: 50px; */
  padding-top: 25px;
  padding-bottom: 200px;
  padding-left: 40px;
  padding-right: 40px;
  width: 75%;
  /* border: 1px solid #c3c3c3; */
  border-radius: 3px;
}

.thank_page_container {
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 728px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #50cd89;
  border-radius: 25px;
}

.kyc_main_image_div {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  border: none;
}

.kyc_main_image_div_container {
  display: flex;
  height: 45px;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.kyc_main_image_text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.signup_header {
  display: flex;
  width: 100%;
  align-items: center;
}

.signup_header_title {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.signup_form_container {
  width: 100%;
  margin-top: 20px;
}

.signup_field_container {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
}

.signup_label_container {
  margin-bottom: -6px;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #272727;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  color: #414141;
}

.pan_conatainer_main {
  margin-top: 15px;
}

.upload_pan_title {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}
.pan_upload {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: grey;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}
.pan_upload_container {
  display: flex;
}

.pan_uploaded {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.pan_upload_button {
  margin-top: 1%;
  width: 25%;
  margin-left: 5%;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: #ffffff;
  color: #3a86ff;
  border-style: solid;
  border-color: #3a86ff;
  border-radius: 4px;
  /* border: none; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.signup_input_container {
  margin-top: 12px;
  padding: 12px;
  font-size: large;
  border: 1px solid #494949;
  border-radius: 3px;
}

.signup_input_container:disabled {
  background-color: rgb(247, 247, 247);
}

.signup_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.signup_footer {
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.signup_footer_text {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #6b6b6b;
  font-weight: 500;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

.signup_footer_text_link {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: var(--blue);
  font-weight: 500;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  margin-left: 5px;
}

.signup_notice_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  width: 60%;
  border-radius: 3px;
}

.signup_notice_text {
  font-size: 16px;
}

.signup_invalid_div {
  margin: 0;
  padding: 0;
  /* margin-top: 3px; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signup_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

/*Drop down div */
.org_check {
  display: flex;
  align-items: center;
}

.selectDropDown {
  width: 100%;
  margin-top: -8px;
  margin-left: 12px;
}

.selectFirmText {
  margin: 0;
  padding-bottom: 6px;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

#upload_doc_firm {
  margin-top: 8px;
}

.dropDownItem {
  border-bottom: 0.5px solid #aeaeae;
  width: auto;
  padding: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Asap";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
  color: #000000;
}

.dropdownContent {
  background: #ffffff;
  border: 1px solid grey;
  width: auto;
  overflow-y: scroll;
  /* -ms-overflow-style: none;  IE and Edge */
  /* scrollbar-width: none;   Firefox */
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid grey;
  width: auto;
  margin-bottom: -147px;
  z-index: 4;
  overflow-y: scroll;
}

.dropdownContent::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
}

.gstToggleBox {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.gstToggle {
  margin-top: 7px;
  margin-left: 15px;
}

.kyc-loader{
  display: grid;
  grid-template-columns: 10% 5%;
  margin-top: 100px;
  text-align: center;
  justify-content: center;
}

@media only screen and (max-width: 1190px) {
  #mobileNumber {
    width: 80%;
  }
}
.step_navigator {
  width: 70%;
  z-index: -1;
}
.end_navigator{
  width: 70%;
  z-index: -1;
}
@media only screen and (min-width: 6001px) and (max-width: 8000px) {
  .step_navigator {
   width: 67%
  }
}
@media only screen and (min-width: 5001px) and (max-width: 6000px){
  .step_navigator {
    width: 67%
   }
}
@media only screen and (min-width: 3500px) and (max-width: 5000px){
  .step_navigator {
    width: 68%
   }
}
@media only screen and (min-width: 2645px) and (max-width: 3000px){
  .step_navigator {
    width: 66%
   }
}
@media only screen and (min-width: 2250px) and (max-width: 2644px){
  .step_navigator {
    width: 66%
   }
}

@media only screen and (min-width: 6001px) and (max-width: 8000px) {
  .end_navigator {
   width: 75%
  }
}
@media only screen and (min-width: 5001px) and (max-width: 6000px){
  .end_navigator {
    width: 76%
   }
}
@media only screen and (min-width: 3500px) and (max-width: 5000px){
  .end_navigator {
    width: 76%
   }
}
@media only screen and (min-width: 2645px) and (max-width: 3000px){
  .end_navigator {
    width: 77%
   }
}
@media only screen and (min-width: 2250px) and (max-width: 2644px){
  .end_navigator {
    width: 77%
   }
}
@media only screen and (min-width: 2000px) and (max-width: 2250px){
  .end_navigator {
    width: 78%
   }
}
@media only screen and (min-width: 1500px) and (max-width: 2000px){
  .end_navigator {
    width: 78%
   }
}
@media only screen and (min-width: 1000px) and (max-width: 1500px){
  .end_navigator {
    width: 80%
   }
}


