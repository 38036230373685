
.client_investment_request_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    padding-bottom: 50px;
  }

  .client_investment_request_model_header_title{
    display: flex;
    padding: 2.5vh;
    margin: 0;
    color: #2E2E2E;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }

  .client_investment_request_model_main_div{
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .client_investment_request_model_header{
    display: flex;
    background-color: #F2F2F2;
    margin-bottom:30px;
    padding:0;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .client_investment_request_model_title_div {
    align-items: center;
    text-align: center;
  }
  
  .client_investment_request_model_title {
    color: #757575;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 0 3px 0 5px;
  }
  
  .client_investment_request_model_input {
    width: 350px;
    padding: 10px;
    margin-top: 30px;
    font-size: large;
    border-radius: 5px;
    border: 1px solid #424242;
  }
  
  .client_investment_request_model_button {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 250px;
    height: 45px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
  
  .client_investment_request_model_button:hover {
    background-color: var(--blue-hover);
  }

  .client_investment_request_content{
    display: flex;
    flex: column;
    padding-top: 10px;
  }

  .client_investment_request_model_heading{
    color: #2E2E2E;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media screen and (max-width: 550px) {
    .client_investment_request_model_input {
      width: 260px;
    }

    .client_investment_request_model_button {
      width: 150px;
    }
  }
  