.input_box_field_container {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
  }

  
.input_box_input_container :disabled {
background-color: rgb(247, 247, 247);
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.input_box_v2_label_container {
    margin-bottom: -6px;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: #272727;
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    color: #414141;
  }

  .input_box_v2_invalid_text {
    margin: 0;
    padding: 0;
    font-size: medium;
    color: var(--invalid);
  }

  .input_box_invalid_div {
    margin: 0;
    padding: 0;
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .input_box_field_container {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
  }
