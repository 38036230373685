.template_ifa_agreement_page_break{
    page-break-before : always;
}

ol,ul,li {
    margin:0;
    padding:0px 0px 0px 10px;
}

p {
    padding: 5px;
    margin: 5px;
    font-size: 13px;
}

.template_ifa_agreement_container{
    margin:0;
    text-align: center;
    justify-content: center;
    width:100vw;
    letter-spacing: 0.01px;
}

.template_ifa_agreement_heading{
    padding-top:30px;
    padding-bottom:50px;
}

.template_ifa_agreement_heading_text{
    color:black;
    font-size:18px;
    font-weight: 800;
}

.template_ifa_agreement_sub_heading_text{
    color:black;
    font-size:16px;
}

.template_ifa_agreement_underline{
    padding-bottom: 2px;
    border-bottom: black 1px solid;
}

.template_ifa_agreement_p_justify{
    text-align: justify;
}

.template_ifa_agreement_main_point_heading{
    font-size:17px;
    color:black;
    font-weight: 800;
}

.template_ifa_agreement_bullet_container{
    display: grid;
    grid-template-columns: 6% 94%;
    padding:0;
    margin:0;
}

.template_ifa_agreement_authority{
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}

.template_ifa_agreement_bottom_heading{
    padding-bottom:50px;
}

@media only screen and (max-width: 550px){
    .template_ifa_agreement_container{
      width: 900px;
    }  
}
