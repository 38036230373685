.wallet_request_success_header {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.wallet_request_success_page_container {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  margin: auto;
  background: #ffffff;
  border: 1px solid #11a75c;
  border-radius: 25px;
  margin-top: 20vh;
}

.wallet_request_success_image_container {
  margin-top: 100px;
  /* margin-left: 298px; */
}

.wallet_request_success_check_mark {
  height: 80px;
  width: auto;
}

.wallet_request_success_main_title {
  text-align: center;
  /* margin: auto; */
  padding: 100px;
  padding-top: 35px;
}

.wallet_request_success_main_title_text {
  font-family: "Asap";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  /* identical to box height */
  color: #11a75c;
}
.wallet_request_success_head_grid_container {
  margin-top: 130px;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
  border-radius: 20px;
  border: 1px solid transparent;
  width: 550px;
  padding: 20px;
}

.wallet_request_success_item_left {
  text-align: left;
}

.wallet_request_success_item_right {
  text-align: right;
}

.wallet_request_success_grid_container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
}

.wallet_request_success_item_text {
  color: black;
  font-size: 13.5px;
}
