.wallet_request_confirmation_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.wallet_request_confirmation_logo_image {
  margin-top: 40px;
  width: 180px;
  margin-left: 2%;
}

.wallet_request_confirmation_page_expire {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.wallet_request_confirmation_div {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 35%;
  margin-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
}

.wallet_request_confirmation_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-color: rgb(218, 218, 218);
}

.wallet_request_confirmation_header_title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(87, 87, 87);
}

.wallet_request_confirmation_header_page_expire_title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: rgb(87, 87, 87);
}

.wallet_request_confirmation_div_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgb(233, 232, 232);
}

.wallet_request_confirmation_data_div1 {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wallet_request_confirmation_data_div2 {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wallet_request_confirmation_data_div3 {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wallet_request_confirmation_data {
  display: flex;
  flex-direction: column;
}

.wallet_request_confirmation_data_title {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 100;
  color: #000;
}

.wallet_request_confirmation_data_subtitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: #2f2f2f;
}

.wallet_request_confirmation_data_div4 {
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wallet_request_confirmation_button {
  color: #fff;
  padding: 12px;
  border: none;
  width: 87%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.wallet_request_confirmation_agreement {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wallet_request_confirmation_agreement_title {
  font-size: 16px;
  font-weight: 300;
  margin-left: 4px;
  padding: 0;
  color: rgb(63, 63, 63);
}

.agreement_link {
  color: var(--blue);
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: none;
}

.otp_info_text_div {
  width: 85%;
  margin-top: 5%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_info_text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: rgb(63, 63, 63);
}

.wallet_request_confirmation_input {
  width: 70%;
  margin-top: 8%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}

.wallet_request_confirmation_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-bottom: 5px;
  width: 74%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wallet_request_confirmation_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

.wallet_request_confirmation_otp_button {
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 3px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  font-size: larger;
  outline: none;
  cursor: pointer;
}

.wallet_request_confirmation_otp_button:hover {
  background-color: var(--blue-hover);
}


.virtual_account{
  margin: 10px;
  padding: 13px;
  font-size: 13px;
  /* background-color: #f4f4f4; */
  
}

.vpa_title{
  margin-top: 5px;
  font-size: 15px;
  justify-content: space-between;
  display: flex;
}

.vpa_subtitle{
  font-weight: 700;

  font-size: 15px;
  font-family: "";
}

.element {
  position: relative;
}

.tooltip {
  display: none;
  border: 1px solid #9e9e9e;
  font-family: "Asap";
  font-style: normal;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
  padding: 2px;
  position: absolute;
  font-size: 10px;
  width: 130px;
  color: #9e9e9e;
  margin-left: -22px;
  line-height: 16px;
}

.element:hover .tooltip {
  display: block;  
}


.vpaUnorderList {
  /* background: #F3F3F3; */
  border-radius: 4px 4px 0px 0px;
  padding-left: 0px;
  padding-top: 0;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 18px; */
  color: #333333;
  list-style: none;
  height: fit-content;
  /* display: flex; */
  align-items: center;
  padding-right: 15px;
  padding-left: 10px;

}

.vpa_request_confirmation_input {
  width: 70%;
  padding: 12px;
  font-size: large;
  border-radius: 3px;
  align-self: center;
  border: 1px solid #9e9e9e;
  outline: none;
}



.paytmEnterVpa {
  /* background: #F3F3F3; */
  border-radius: 4px 4px 0px 0px;
  padding-left: 0px;
  padding-top: 0;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 18px; */
  color: #333333;
  list-style: none;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 15px;
  padding-left: 10px;

}


.radioClass{
  height: 15px;
    margin-right: 10px;
    width: 15px;
    cursor: pointer;
}
.copy_button{
  border: 1px solid rgb(0, 145, 255);
  padding: 2px;
  color: rgb(0, 145, 255);
  cursor: pointer;
  font-size: 10px;
  margin-top: -5px;
}

@media only screen and (max-width: 1230px) {
  .wallet_request_confirmation_logo_image {
    width: 20%;
  }

  .wallet_request_confirmation_page_expire {
    width: 40%;
  }

  .wallet_request_confirmation_div {
    width: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .wallet_request_confirmation_logo_image {
    width: 25%;
  }

  .wallet_request_confirmation_page_expire {
    width: 50%;
  }

  .wallet_request_confirmation_div {
    width: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .wallet_request_confirmation_logo_image {
    width: 30%;
  }

  .wallet_request_confirmation_page_expire {
    width: 60%;
  }

  .wallet_request_confirmation_div {
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .wallet_request_confirmation_logo_image {
    width: 35%;
  }

  .wallet_request_confirmation_page_expire {
    width: 70%;
  }

  .wallet_request_confirmation_div {
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .wallet_request_confirmation_logo_image {
    width: 40%;
  }

  .wallet_request_confirmation_page_expire {
    width: 80%;
  }

  .wallet_request_confirmation_div {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .wallet_request_confirmation_logo_image {
    width: 45%;
  }

  .wallet_request_confirmation_page_expire {
    width: 90%;
  }

  .wallet_request_confirmation_div {
    width: 90%;
  }

  .wallet_request_confirmation_data_div2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .wallet_request_confirmation_data_div3 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .wallet_request_confirmation_data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .wallet_request_confirmation_data_title {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .wallet_request_confirmation_data_subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .wallet_request_confirmation_agreement_title {
    font-size: 14px;
  }

  .wallet_request_confirmation_button {
    padding: 12px;
    width: 87%;
    font-size: 16px;
  }

  .wallet_request_confirmation_data_div1 {
    flex-direction: column;
  }
}
