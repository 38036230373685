.consent_text_container {
    width: 100%;
  }

  .consent_text_checkbox {
    display: flex;
    flex-direction: row;
    margin-top: -1%;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .consent_text_condition {
    font-size: 11px;
    margin-left: 0px;
    margin-top: 9px;
    color: rgb(105, 105, 105);
  }

  .consent_text_policy_text{
    cursor: pointer;
    font-size: 11px;
    color: #3a86ff;
  }
  