.client_inprocess_investments_loader_div {
  display: flex;
  width: 92%;
  height: 400px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.client_inprocess_investments_container {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
  align-self: center;
}

/* .client_inprocess_investments_title {
    margin-top: 20px;
    width: 92%;
    font-size: 22px;
    align-self: center;
    color: #42474f;
  } */

.client_inprocess_investments_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.client_inprocess_investments_button:hover {
  background-color: var(--orange);
  color: #fff;
}
