.pdf_viewer_model_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  
  .pdf_viewer_model_title_div {
    display: flex;
    flex-direction: row;
    text-align: justify;
  }
  
  .pdf_viewer_model_title {
    margin: 0;
    padding: 0;
    color: #414141;
    font-weight: bold;
    font-size: 18px;
    font-family:"Times New Roman", Times, serif;
  }

  .pdf_viewer_model_body {
    margin: 0;
    padding: 0;
    color: #414141;
    font-weight: bold;
    font-size: 18px;
    font-family:"Times New Roman", Times, serif;
  }
  
  .pdf_viewer_model_input {
    width: 60%;
    padding: 10px;
    margin-top: 20px;
    font-size: large;
    border-radius: 3px;
    border: 1px solid rgb(73, 73, 73);
  }
  
  .pdf_viewer_model_button {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 130px;
    /* margin-top: 15px; */
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .pdf_viewer_model_button:hover {
    background-color: var(--blue-hover);
  }
  
  .pdf_viewer_model_info_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }
  
  .pdf_viewer_model_info {
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #414141;
    font-size: 14px;
  }

  .container_tnc {
    background: linear-gradient(to bottom, #f6f6f6 80px, white 0%); 
    box-sizing: border-box;
    border: 1px solid #b0b5b1;
    border-radius: 10px;  
    padding: 30px 20px;
    padding: 0;
    width: 92%;
    margin-top: 20px;
    align-self: center;
    color: #42474f;
    font-weight: 800;
    font-size: 28px;
  }

  .pdf_viewer_header_container{
    display: grid;
    grid-template-columns: 96% 4%;
  }

  ::-webkit-scrollbar {
    width: 0px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
    background: #faf7f8;
    border-radius: 5px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #70696c;
    border-radius: 5px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }

    @media only screen and (max-width: 550px) {
      .pdf_viewer_header_container{
        display: grid;
        grid-template-columns: 90% 10%;
      }
      
    }
    


  