.upload_input_v3_main_contianer{
    margin-top:5px;
}

.upload_input_v3_title {
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #414141;
}

.upload_input_v3_container {
  display: flex;
}

.upload_input_v3_upload {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: grey;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.upload_input_v3_button {
  margin-top: 1%;
  width: 25%;
  margin-left: 5%;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: #ffffff;
  /* color: #3a86ff; */
  border-style: solid;
  /* border-color:#3a86ff; */
  border-radius: 4px;
  /* border: none; */
}
.upload_input_v3_info_text_container {
    margin-top: 15px;
    font-size: 50px;
  }
.upload_input_v3_file_container {
  border: 1px solid black;
  padding: 10px;
  border-radius: 2px;
  margin-top: 10px;
  display: flex;
  text-align: right;
  justify-content: space-between;
}

.upload_input_v3_main_container {
  width: 100%;
  font-size: 12px;
  padding: 15px;
  color: #3a86ff;
  border-color: #3a86ff;
  background-color: #ffffff;
  cursor: pointer;
  border-style: solid;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.upload_input_v3_file_text {
  padding-top: 5px;
  font-size: 13px;
  color: #616060;
}

.upload_input_v3_remove_file {
  padding: 0;
  color: #3a86ff;
  font-size: 13px;
  font-weight: 600;
  font-family: lato;
  cursor: pointer;
}

.upload_input_uploaded {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.upload_input_upload {
  margin-top: 1%;
  width: 70%;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px;
  float: left;
  color: grey;
  display: flex;
  flex-direction: column;
  padding: 11px 5px 12px 21px;
}

.upload_input_button {
  margin-top: 1%;
  width: 25%;
  margin-left: 5%;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: #ffffff;
  /* color: #3a86ff; */
  border-style: solid;
  /* border-color:#3a86ff; */
  border-radius: 4px;
  /* border: none; */
}

.upload_input_info_text_container {
  margin-top: 15px;
  font-size: 50px;
}
