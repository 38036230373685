.otpenter_model_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.otpenter_model_title_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.otpenter_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 18px;
}

.otpenter_model_input {
  width: 60%;
  padding: 10px;
  margin-top: 20px;
  font-size: large;
  border-radius: 3px;
  border: 1px solid rgb(73, 73, 73);
}

.otpenter_model_button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 130px;
  margin-top: 15px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.otpenter_model_button:hover {
  background-color: var(--blue-hover);
}

.otpenter_model_info_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.otpenter_model_info {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #414141;
  font-size: 14px;
}
.otpenter_model_info_attempt_div {
  display: flex;
  flex-direction: row-reverse;
  align-items: left;
  margin-top: 15px;
  justify-content: left;
  float:left;
  inset-block-start:left;
}

.otpenter_model_info_atmpt {
  margin: 0;
  padding: 0;
  display: flex;
  justify-self: left;
  text-align: left;
  font-weight: 400;
  color: #414141;
  font-size: 14px;
  /* color: #951b1b; */
}
