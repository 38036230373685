.client_switch_plan_main_div {
  display: flex;
  flex-direction: column;
  background-color: white;
  outline: none;
  border-radius: 12px;
  overflow: hidden;
  width: 55%;
  padding-bottom: 15px;
}

.client_switch_plan_header {
  width: 100%;
  height: 65px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.client_switch_plan_title {
  margin: 0;
  padding: 0;
  margin-left: 18px;
  font-size: 20px;
  color: black;
}

.client_switch_plan_close_icon {
  margin: 0;
  padding: 0;
  margin-right: 30px;
  cursor: pointer;
}

.client_switch_plan_loader_div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.client_switch_plan_top_div {
  margin-top: 15px;
}

.client_switch_plan_row_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.client_switch_plan_h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: black;
}

.client_switch_plan_h2 {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  font-size: 18px;
  color: black;
  font-weight: 700;
}

.client_switch_plan_h4 {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-size: 14px;
  color: #757575;
}

.client_switch_plan_investment {
  margin-top: 15px;
  align-self: center;
  background-color: #fbfbfb;
  border-radius: 4px;
  padding: 12px;
}

.client_switch_plan_investment_toggle {
  display: flex;
  width: 100%;
  align-self: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  border-bottom: 1px solid #d4d4d4;
}

.client_switch_plan_investment_active {
  font-size: 14px;
  font-weight: 600;
  color: #3a86ff;
  margin-right: 7%;
  cursor: pointer;
  padding-bottom: 12px;
  border-bottom: 3px solid #3a86ff;
}

.client_switch_plan_investment_inactive {
  font-size: 14px;
  color: #757575;
  margin-right: 7%;
  cursor: pointer;
}

.client_switch_plan_investment_sub_heading {
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  font-weight: 400;
  color: #757575;
}

.client_switch_plan_investment_content {
  margin-left: 0px;
  padding-left: 0px;
  font-size: 12px;
}

.client_switch_plan_select_tenure_text {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-size: 14px;
  color: #282828;
}

.client_switch_plan_tenure_container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.client_switch_plan_tenure_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
  border: 1px solid #aeaeae;
}

.client_switch_plan_tenure_text {
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-left: 5px;
}

.client_switch_plan_input_div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #424242;
  border-radius: 4px;
  width: 280px;
}

.client_switch_plan_input {
  width: 100%;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.client_switch_error_text {
  margin: 0;
  padding: 0;
  font-size: 11px;
  margin-top: 10px;
  color: #e73535;
}

.client_switch_info_text {
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-top: 18px;
  color: #757575;
}

.client_switch_plan_invest_button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 200px;
  margin-top: 15px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
}

.client_switch_plan_invest_button:hover {
  background-color: var(--blue-hover);
}

.client_switch_plan_list_div {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.client_switch_plan_list_row_div {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.client_switch_plan_list_text_heading {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  font-size: 14.5px;
  color: #363636;
}

.client_switch_plan_list_text_content {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  font-size: 14px;
  color: #171717;
}
