/* .upload_input_v2_main_contianer{
    margin-top:10px;
} */

.upload_input_title {
    font-family: "Asap";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #414141;
  }

  .upload_input_container {
    display: flex;
  }

  .upload_input_uploaded {
    margin-top: 1%;
    width: 70%;
    background: #ffffff;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    border-radius: 4px;
    height: 46px;
    float: left;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 11px 5px 12px 21px;
  }

  .upload_input_upload {
    margin-top: 1%;
    width: 70%;
    background: #ffffff;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    border-radius: 4px;
    height: 46px;
    float: left;
    color: grey;
    display: flex;
    flex-direction: column;
    padding: 11px 5px 12px 21px;
  }

  .upload_input_button {
    margin-top: 1%;
    width: 25%;
    margin-left: 5%;
    height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    background: #ffffff;
    /* color: #3a86ff; */
    border-style: solid;
    /* border-color:#3a86ff; */
    border-radius: 4px;
    /* border: none; */
  }

  .upload_input_info_text_container {
    margin-top: 15px;
    font-size: 50px;
  }