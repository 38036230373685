.state_dropdown_main_contianer {
  margin-top: 5px;
}

.state-dropdown-container {
  display: flex;
  border: 1px solid #494949;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 5px;
}

.state-dropdown-container-disabled {
  display: flex;
  border: 1px solid #494949;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 5px;
  background: #eeeeee;
}

.state_drop_down_label {
  margin-bottom: -6px;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #272727;
  font-family: "Asap";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  color: #414141;
}

.state_dropdown_input_container {
  font-size: 14px;
  border: 1px solid #eeeeee;
  color: #605e5e;
  border-radius: 3px;
}

.state_dropdown_input_field {
  background: #eeeeee;
  padding: 5px;
  font-size: 14px;
  border-radius: 0px 4px 4px 0px;
  width: 100%;
  border: 1px solid #494949;
  color: #605e5e;
  border: none;
  outline: none;
}

.state_dropdown_input_field:disabled {
  background: #eeeeee;
  font-size: 16px;
  border-radius: 0px 4px 4px 0px;
  width: 100%;
  border: 1px solid #494949;
  color: #0a0a0a;
  border: none;
  margin-left: -8px;
  outline: none;
}

.state_dropdown_input_container:disabled {
  background-color: #ededed;
  color: #1b1b1b;
}

.state-dropdown-list {
  border: 1px solid #eeeeee;
  border-top: none;
}

.dropdown-select {
  display: flex;
  border: 1px solid #494949;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 5px;
}
.dropdown-select-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-top: 3px;
  color: #656363;
}
