.banner_container {
  position: absolute;
  /* inset: 0; */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_header {
  display: flex;
  z-index: 2;
  top:0;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.banner_logo_img {
  height: auto;
  width: 180px;
  cursor: pointer;
  margin-left: 20px; 
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #2c2e2b0d;
  padding: 6%;
  margin-top: 150px;
  margin-bottom: 50px;
  margin-left: 15%;
  margin-right: 15%;
  border-radius: 25px;
}

.banner_image {
  width: 72%;
}

.banner_text {
  text-align: center;
  font-size: 18px;
}

@media screen and (max-width: 900px) {
  .banner_logo_img {
    width: 150px;
  }
}

@media screen and (max-width: 600px) {
  .banner_header {
    flex-direction: column;
    justify-content: center;
  }
  .banner_logo_img {
    width: 120px;
    margin-left: 0px;
  }
}