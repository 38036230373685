.downloads_div_container {
    display: flex;
    flex-direction: column;
    height: 80%;
    /* padding:4%; */
  }

  .downloads_header_div_title {
    
    color: #42474f;
    font-weight: 600;
    font-size: 28px;
  }
  

  .downloads_main_div {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 220px;
  }

  .support_header {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .download_image {
    display: table;
    grid-row: 1 /2;
    margin-bottom: 10px;
    width:200px;
    padding:5%;
    align-self: center;
    align-items: center;
  }
  .row-with-download:hover button {
    display: block;
    }

  .tabdownloads {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    border-bottom: 2px solid transparent;
  }
  
  .tabdownloads.selected {
    color:rgb(58, 134, 255);
    border-bottom: 2px solid rgb(58, 134, 255);
  }