.clientrepayment_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientrepayment_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.clientrepayment_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clientrepayment_header_div_title_container {
  display: flex;
  flex-direction: column;
}

.clientrepayment_header_div_subtitle {
  margin: 0;
  padding: 0;
  color: #969494;
  font-size: 14px;
}

.clientrepayment_header_div_title {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.clientrepayment_header_button_div_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientrepayment_status_button_r {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientrepayment_status_button_u {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--blue);
  background-color: #fff;
  color: var(--blue);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientrepayment_status_button_s {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientrepayment_status_button_d {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--gray);
  background-color: #fff;
  color: var(--gray);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}
