.client_growth_plan_div_container {
  display: flex;
  flex-direction: column;
}

.client_growth_header {
  margin: 20px 0;
}
.client_growth_header_text{
  color: #2E2E2E;
  font-size: 16px;
  font-weight: 500;
}

.client_growth_header_content{
  display:flex;
  flex-direction: row;
  margin: 10px 0;
}

.client_growth_tenure_button{
  padding:10px 30px;
  border-radius: 19px;
  border: 1px solid #E1E0E0;
  margin-right:20px;
  color: #064586;
  cursor: pointer;
  font-size: 13px;
}
.client_growth_tenure_button_active{
  padding:10px 30px;
  border-radius: 19px;
  margin-right:20px;
  background-color: #3A86FF;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 13px;
}

.client_growth_card{
  padding: 40px 30px;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  border: 1.5px solid #E4E4E4;
  background: #F4F4F4;
}

.client_growth_card_content_container{
  display: flex;
  flex:70%;
  flex-direction: row;
}

.client_growth_card_button_container{
   display: flex;
   flex:30%;
   align-items: center;
   justify-content: flex-end;
}

.client_growth_card_content{
  padding: 15px;
  margin:0 10px;
  background: rgba(255, 255, 255, 0.80);
}

.client_growth_card_content_title{
  color: #757575;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 0;
}

.client_growth_card_content_value{
  color: #2E2E2E;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding:5px 0;
}

.client_growth_details_investment_main_header_button {
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #4A88F7;
  box-shadow: 0px 4px 4px 0px rgba(242, 242, 242, 0.25);
  color:#4A88F7;
  margin:0 10px;
}

.client_growth_investment_button{
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #4A88F7;
  color: #FFF;
  border: none;
  margin: 0 10px;
  cursor: pointer;
}

.client_growth_header_left_grid {
  display: grid;
  justify-content: center;
  align-self: center;
  grid-template-columns: 5% 95%;
  padding-left: 2%;
}


.client_growth_header_left_grid_text {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.client_growth_details {
  padding: 1% 2% 1% 2%;
}

.client_growth_details_summary {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: left;
  padding: 1%;
  padding-left: 2%;
  padding-right: 2%;
}

.client_growth_details_summary_header_text {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom: 0;
  padding-bottom: 0;
}

.client_growth_details_summary_value_text {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0e0e0e;
  margin-top: 0;
  padding-top: 0;
}

.client_growth_details_investment {
  padding: 2%;
  padding-left: 5%;
  padding-right: 5%;
}

.client_growth_details_investment_main_header {
  margin-bottom: 8vh;
  justify-content: center;
  align-self: center;
}

.client_growth_details_investment_main_header_text {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #232323;
}

.client_growth_details_investment_grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  text-align: left;
}

.client_growth_details_investment_header_text {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #686868;
  margin-bottom: 0;
  padding-bottom: 0;
}

.client_growth_details_investment_value_text {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0e0e0e;
  margin-top: 0;
  padding-top: 0;
}

.client_growth_tenure_label {
  margin: 0px 0px 0px 0px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

#client_growth_tenure {
  accent-color: #064586;
  border-color: #064586;
  width: 30px;
  margin: 2px 0px 0px 0px;
  cursor: pointer;
}

.client_growth_radio_button_container {
  text-align: left;
  margin-bottom: 2%;
}

.client_growth_radio_button_inner_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.client_growth_radio_button {
  border: 1px solid #064586;
  padding: 1.5%;
  display: inline;
  margin-left: 2%;
  margin-top: 2%;
  border-radius: 4px;
  cursor: pointer;
  width: 30%;
  box-sizing: border-box;
}

.payout_radio_button_container {
  text-align: left;
  margin-top: 30px;
  align-items: space-between;
}

.payout_radio_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5%;
  margin-right: 15px;
  cursor: pointer;
}

.payout_radio_button_sub_container {
  display: grid;
  grid-template-columns: 35% 35%;
  grid-gap: 0%;
  text-align: left;
  margin-bottom: 1vh;
  margin-left: 5px;
}

.payout_radio_label {
  font-size: 13px;
  font-family: "Poppins";
  cursor: pointer;
  font-weight: 400;
  color: #282828;
  margin-left: 3px;
}

@media screen and (max-width: 1500px) {
  .client_growth_details_summary_header_text {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    color: #282828;
  }
  .client_growth_details_summary_value_text {
    font-size: 16px;
  }

  .client_growth_header_left_grid_text {
    font-size: 17px;
  }
}
