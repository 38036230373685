.withdrawal-details_model_div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    width: 610px; 
    height: 300px;
    padding-bottom: 50px;
    margin-left:2vw;
    margin-right: 2vw;
  }

.withdrawal-details_model_header{
    background-color: #F2F2F2;
    margin-bottom:30px;
    padding:0;
    align-items: left;
    text-align: left;
    border-radius: 4px 4px 0 0;
}

.withdrawal-details_model_header_title{
    padding: 2.5vh;
    margin: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.withdrawal-details_model_body{
    margin-top: 10px;
    margin-bottom:30px;
    align-items: center;
    text-align: center; 
    color:  #2E2E2E;
}

.withdrwal-details-model-body-text{
    font-family: Asap;
    font-size: 20px;
    font-weight: 500;
    line-height: 22.92px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.withdrwal-details-model-body-subtext{
    font-family: Asap;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.75px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.withdrwal-details-model-slider{
    margin-top: 25px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.withdrwal-details-model-line{
    width: 190px;
    height: 0px;
    align-items: center;
    gap: 0px;
    border: 1px solid #BDBDBD
}
.withdrwal-details-model-steps{
    display: flex;
    justify-content: center;
    text-align: center;
    /* gap: 45px ; */
    margin-top: 10px;
}
.withdrwal-details-model-date{
    display: flex;
    font-size: small;

}
.withdrawal-details-sent-time{
    margin-left: 40px;
}
.withdrawal-details-approved-time{
    margin-left: 100px;
    margin-top: 10px;
}
.withdrawal-details-processed-time{
    margin-left: 100px;
}
.circle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    /* margin-left: 120px; */
}