.clientlist_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clientlist_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.clientlist_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clientlist_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.clientlist_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.clientlist_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 13%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.clientlist_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.clientlist_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
}

.clientlist_consent_status {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  color: var(--blue);
}

.clientlist_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientlist_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientlist_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.clientlist_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.clientlist_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.clientlist_table_flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientlist_kyc_status_text {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  color: var(--orange);
  font-size: 11px;
}

.tableContainer {
  margin-top: 20px !important;
  width: 100% !important;
  border-top-left-radius: "5px" !important;
  border-top-right-radius: "5px" !important;
  border-top: "1px solid #c7c7c7" !important;
  border-left: "1px solid #c7c7c7" !important;
  border-right: "1px solid #c7c7c7" !important;
  align-self: "center" !important;
  /* margin-left: 30px ; */
}

.clientListActionDiv {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  margin-right: 20px;
}

.css-y8ay40-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  /* padding: 16px; */
  color: rgba(0, 0, 0, 0.87);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
}

.remarks_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.remarks_dot {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

.remarks_text {
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-size: 12px;
  color: #424242;
}

.consent_modal {
  position: fixed;
  top: 25%;
  justify-content: center;
  background-color: white;
  width: 600px;
}

.consent_modal_button {
  padding-bottom: 40px;
}

.consent_button {
  color: white;
  background-color: #3a86ff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 60%;
  border: 1px solid #3a86ff;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.table_button_text {
  font-size: 14px;
  color: #3a86ff;
  cursor: pointer;
  white-space: pre;
}
