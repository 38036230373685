.pdf_viewer_model_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.pdf_viewer_model_title_div {
  display: flex;
  flex-direction: row;
  text-align: justify;
}

.pdf_viewer_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
}

.pdf_viewer_model_body {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
}

.pdf_viewer_model_input {
  width: 60%;
  padding: 10px;
  margin-top: 20px;
  font-size: large;
  border-radius: 3px;
  border: 1px solid rgb(73, 73, 73);
}

.pdf_viewer_model_button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  margin-left: 15px;
  background-color: var(--blue);
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pdf_viewer_download_button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  margin-right: 15px;
  color: "#3a86ff";
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pdf_viewer_model_button:hover {
  background-color: var(--blue-hover);
}

.pdf_viewer_model_info_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.pdf_viewer_model_info {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #414141;
  font-size: 14px;
}

.container_tnc {
  background: linear-gradient(to bottom, #f6f6f6 80px, white 0%);
  box-sizing: border-box;
  border: 1px solid #b0b5b1;
  border-radius: 10px;
  padding: 30px 20px;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.pdf_viewer_header_container {
  display: grid;
  grid-template-columns: 96% 4%;
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #faf7f8;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #70696c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 550px) {
  .pdf_viewer_header_container {
    display: grid;
    grid-template-columns: 90% 10%;
  }
}

.terms-conditions-container {
  background-color: #fbf8fd;
  margin: 20px;
  border-radius: 25px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.terms-conditions-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 70vh;
}

.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px;
}

.header-title {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.embed-frame {
  width: 100%;
  height: calc(100% - 70px); 
  border: none;
  outline: none;
  
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  box-sizing: border-box; 
  background: #FBF8FD; 
}

.download-button-container {
  display: flex;
  align-items: center;
}

.download-button-icon {
  margin-right: 2px;
  height: 32px;
  width: 32px;
}

.download-button {
  color: #3a86ff;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
}

.download-button.hidden {
  display: none;
}

.custom-button {
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  font-family: 'Poppins', sans-serif;
  background-color: #3a86ff;
  cursor: pointer;
}

.custom-button.disabled {
  background-color: #ada8a8;
  cursor: not-allowed;
}
