.investortype_main_container {
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.investortype_main_header {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #414141;
}

.investortype_container {
  margin-top: 10px;
  padding: 30px;
  /* width: 85%; */
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.investortype_input_conatiner {
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
}
.radioClass {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.investortype_label {
  display: flex;
  align-items: center;
  align-content: center;
  color: #141519;
  letter-spacing: -0.25px;
  padding: 8px;
}

.investor_entity_container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.investor_entity_label_conatiner {
  margin-top: 15px;
}

.investor_type_proceed_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5%;
  cursor: pointer;
}

.investor_type_proceed_button_text {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.upload_input_container {
  display: flex;
}
