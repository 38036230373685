.entity_type_container {
  margin-top: 10px;
  padding: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
}

.entity_kyc_main_input_form {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.entity_clientkyc_main_input_label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 12px;
}

.entity_clientkyc_main_input_field {
  height: 45px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 3px;
}

.board_resolution_template {
  color: var(--blue);
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: none;
}
