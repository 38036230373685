.client_otpenter_model_v2_header {
    background-color: #f2f2f2;
    width: 94%;
    padding-top: 15px;
    text-align: left;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    margin-left: 0px;
    padding-bottom: 15px;
    border-radius: 5px
}

.client_otpenter_model_v2_header_title {
  color: #343434;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.client_otpenter_model_v2_body {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client_otpenter_model_v2_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  outline: none;
}

.client_otpenter_model_v2_title_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.client_otpenter_model_v2_title {
  margin: 0;
  padding: 0;
  color: #000;
  text-align: center;
  font-family: "Asap";
  /* letter-spacing: 5px; */
  font-size: 16px;
  word-spacing: 2px;
  width: 520px;
  font-style: normal;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 22px;
}

.client_otpenter_model_v2_input {
  width: 75%;
  padding: 10px;
  margin-top: 20px;
  font-size: large;
  border-radius: 3px;
  border: 1px solid rgb(73, 73, 73);
}

.client_otpenter_model_v2_button {
    padding: 13px;
    padding-left: 20px;
    padding-right: 20px;
    width: 130px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
}

.client_otpenter_model_v2_button:hover {
  background-color: var(--blue-hover);
}
