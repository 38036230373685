
.add_client_kyc_nominee_main_container_title{
    display: flex;
    justify-content: space-between;
}

.add_client_kyc_nominee_text{
    color: var(--blue);
    border-bottom: 2px solid var(--blue);
    cursor: pointer;
}