.ifa_switch_plan_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ifa_switch_plan_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 95%;
}

.ifa_switch_plan_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ifa_switch_plan_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.ifa_switch_plan_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.ifa_switch_plan_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 13%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.ifa_switch_plan_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.ifa_switch_plan_status_text {
  margin: 0;
  padding: 0;
}

.ifa_switch_plan_resend_button_div {
  border: none;
  background: "#ebf0fa";
  border-radius: 1px;
  width: 40px;
  height: 30px;
}

.ifa_switch_plan_resend_button {
  color: #3a86ff;
}

.ifa_switch_plan_resend_button_disable {
  color: #a8a8a8;
}
