
.withdrawal_model_div {
    display: flex;
    flex-direction: column;
    /* padding: 30px; */
    background-color: #fff;
    /* border-radius: 3px; */
    border-radius: 0.5%;
    outline: none;
    padding-bottom: 50px;
    margin-left:2vw;
    margin-right: 2vw;
  
  }

  .withdrawal_model_header_title{
    padding: 2.5vh;
    margin: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
  }

  .withdrawal_model_main_div{
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .withdrawal_model_header{
    background-color: #F2F2F2;
    margin-bottom:30px;
    padding:0;
    align-items: left;
    text-align: left;
  }
  
  .withdrawal_model_title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .withdrawal_model_title {
    margin: 0;
    padding: 0;
    color: #343434;
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-size: 20px;
  }
  
  .withdrawal_model_input {
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    font-size: large;
    border-radius: 5px;
    border: 1px solid #424242;
  }
  
  .withdrawal_model_button {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 230px;
    height: 50px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
  
  .withdrawal_model_button:hover {
    background-color: var(--blue-hover);
  }

  .withdrawal_transaction_container{
    /* margin-top: 30px; */
    margin-top: 3%;
    height: auto;
    border: 1px solid #c6ccc8;
    /* border-radius: 5px; */
    border-radius: 1%;
    align-items: center;
    text-align: center;
  }

  .withdrawal_transaction_item_left {
    text-align: left;
    color: rgb(73, 73, 73);
  }
  
  .withdrawal_transaction_item_right {
    text-align: right;
    font-weight: bold;
  }
  
  .withdrawal_transaction_grid_container {
    display: grid;
    grid-template-columns: 70% 30%;
    justify-content: space-between;
    /* padding-left: 5px;
    padding-right: 5px; */
    padding-left: 5%;
    padding-right: 5%;
  }

  .withdrawal_transaction_freedom_plan_breakdown{

    margin-left: 20%;
    justify-content: start;
  }

  .withdrawal_transaction_freedom_plan_withdrables_container{
    display: grid;
    grid-template-columns: 60% 40%;
    justify-content: space-between;
  } 

  