.logo_main_input_form {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }
.logo_main_input_label {
font-size: 16px;
margin: 0;
padding: 0;
font-weight: normal;
margin-bottom: 12px;
}

.logo_main_image_div_container {
display: flex;
height: 45px;
width: 100%;
margin: 0;
padding: 0;
margin-bottom: 12px;
border: 1px solid #cacaca;
border-radius: 3px;
flex-direction: row;
align-items: center;
overflow: hidden;
}
  
.logo_main_image_div {
flex: 1;
height: 50px;
width:200px;
display: flex;
align-items: center;
margin: 0;
padding: 0;
padding-left: 12px;
border: none;
}
  
.logo_main_image_text {
margin: 0;
padding: 0;
font-size: 16px;
}

.logo_main_image_button {
height: 100%;
width: 28%;
display: flex;
align-items: center;
justify-content: center;
font-size: medium;
background-color: var(--blue); 
color: #fff;
border: none;
cursor: pointer;
outline: none;
}
.logo_main_image_button:hover {
    background-color: var(--blue-hover);
    }

/* @media only screen and (max-width: 550px) {
    .logo_main_image_button {
      width: 28%;
    }
} */

.logo_field_container {
    display: flex;
    flex-direction: column;
  }
.logo_submit_container {
  background-color: var(--blue);
  color: #fff;
  align-items: "center";
  display: "flex";
  align-self: "center";
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 50%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  margin-left:25%
}
.download_image {
    display: block;
    margin-bottom: 10px;
    width:200px;
    padding:5%;
  }
  .support_header {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    grid-row: 1 / 2;
  }
  

  .item2 {
    width: 120px;
    min-height: 120px;
    max-height: auto;
    float: left;
    margin: 3px;
    padding: 3px;
}

.container { 
  position: static;
  border: 3px solid #2c2e2b0d; 
  padding:5%;
  border:20%;
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 25px;
}
.container img { 
  display: flex;
  border-radius: 25px;
 }
.container .fa-download { 
  position: absolute; 
  border-bottom:2px; 
  border-right: 0px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  }

.label{
  display : flex;
  justify-content: space-between;
}

/* for tabs */
a {
  display: inline-block;
  padding: 10px;
  color: black;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

/* Style for the tab when hovered */
a:hover {
  color: "#3A86FF";
  border-bottom: 2px solid rgb(42, 81, 220);
}

/* Style for the tab when active */
a:active {
  color: "#3A86FF";
  border-bottom: 2px solid rgb(43, 82, 225);
}

/* thatsbetter */
.tab {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
  border-bottom: 2px solid transparent;
  cursor:pointer;
}

.tab.selected {
  color:rgb(43, 82, 225);
  border-bottom: 2px solid rgb(43, 82, 225);
  cursor:pointer;
}

.marketing_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* padding:4%; */
}

.marketing_main_div {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 200px;
}